import React from "react";
import callus from "assets/callus.png";
import emailus from "assets/emailus.png";
import whatsapp from "assets/whatsapp.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

const ContactDetails = ({ ...props }) => {
	const { ui } = props.uioptions;
	const { user } = props.authorization;

	return (
		<div className="rounded p-0" style={{ border: "1px solid #3B366F" }}>
			<p className="text-center py-2 text-white bgBlue rounded fw-bold">Get In Touch</p>
			<p className="text-center">
				<img src={callus} alt="+27 114 861 882" width="40" className="mb-2" />
				<br />
				<strong>Call</strong>
				<br />
				<span style={{ color: "#2563EB" }}>
					{user && user.country === "UK" && ui && (
						<a
							href={ui.result.contact_info.uk.phone.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.uk.phone.display}
						</a>
					)}
					{user && user.country === "SA" && ui && (
						<a
							href={ui.result.contact_info.sa.phone.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.sa.phone.display}
						</a>
					)}
				</span>
			</p>
			<p className="text-center">
				<img src={whatsapp} alt="+27 114 861 882" width="40" className="mb-2" />
				<br />
				<strong>WhatsApp</strong>
				<br />
				<span style={{ color: "#2563EB" }}>
					{user && user.country === "UK" && ui && (
						<a
							href={ui.result.contact_info.uk.whatsapp.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.uk.whatsapp.display}
						</a>
					)}
					{user && user.country === "SA" && ui && (
						<a
							href={ui.result.contact_info.sa.whatsapp.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.sa.whatsapp.display}
						</a>
					)}
				</span>
			</p>
			<p className="text-center">
				<img src={emailus} alt="+27 114 861 882" width="40" className="mb-2" />
				<br />
				<strong>Email</strong>
				<br />
				<span style={{ color: "#2563EB" }}>
					{user && user.country === "UK" && ui && (
						<a
							href={ui.result.contact_info.uk.email.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.uk.email.display}
						</a>
					)}
					{user && user.country === "SA" && ui && (
						<a
							href={ui.result.contact_info.sa.email.link}
							target="_blank"
							rel="noreferrer"
							style={{ textDecoration: "none" }}
						>
							{ui.result.contact_info.sa.email.display}
						</a>
					)}
				</span>
			</p>
		</div>
	);
};

// export default ContactDetails;

ContactDetails.propTypes = {
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(ContactDetails);
