/* eslint-disable import/no-anonymous-default-export */
import { SET_CHANGE_PASSWORD_LOADING, CHANGE_PASSWORD, SET_CHANGE_PASSWORD_ERROR, CLEAR_CHANGE_PASSWORD_ERROR, CLEAR_CHANGE_PASSWORD } from '../actions/types';

const initialState = { cp_data: null, cp_loading: false, cp_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_CHANGE_PASSWORD_LOADING:
            return { ...state, cp_loading: true, cp_error: null }
        case SET_CHANGE_PASSWORD_ERROR:
            return { ...state, cp_error: action.payload, cp_loading: false }
        case CHANGE_PASSWORD:
            return { ...state, cp_loading: false, cp_error: null, cp_data: action.payload }
        case CLEAR_CHANGE_PASSWORD_ERROR:
            return { ...state, cp_loading: false, cp_error: null}
        case CLEAR_CHANGE_PASSWORD:
            return { ...state, cp_loading: false, cp_error: null, cp_data: null}
        default: return state
    }
}