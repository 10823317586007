import React, { useEffect, useState } from "react";
import { MobileNumber, HorizontalScroller, RegularButton, Error, TextField } from "components";
import PropTypes from "prop-types";
import {useHistory} from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "store/actions";
import { Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";

const BeneficiaryDetails = (props) => {
	const {
		handleStep,
		myBeneficiary,
		getAllBeneficiaries,
		clearAllBeneficiariesError,
		setMyBeneficiary,
		clearSearchRemitter,
		clearOnboardRemitter,
		disableFields,
		setDisableFields,
		selectedBeneficiary,
		setSelectedBeneficiary,
	} = props;

	// const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
	// const [disableFields, setDisableFields] = useState(false);
	const history = useHistory();
	const onBeneficiaryChange = async (e) => {
		let details = await e.value;
		await handlePickBeneficiary(details?.beneficiary_id);
		setSelectedBeneficiary(details);
	};

	const { user } = props.authorization;
	const { all_beneficiaries, all_beneficiaries_loading, all_beneficiaries_error } =
		props.getBeneficiaries;

	useEffect(() => {
		clearSearchRemitter();
		clearOnboardRemitter();
		user.onboarded && getAllBeneficiaries();
		return () => {
			clearAllBeneficiariesError();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const runDisplayBenefeciaries = () => {
		if (all_beneficiaries_loading) {
			return <span>Loading...</span>;
		}

		if (all_beneficiaries) {
			if (!all_beneficiaries[0]) {
				return <span>No beneficiaries found</span>;
			} else {
				return all_beneficiaries.map((b) => {
					return (
						<span
							key={b.beneficiary_id}
							onClick={() => handlePickBeneficiary(b.beneficiary_id)}
							className="badge borderRadius-20px textBlue fw-normal py-2 mx-1"
							style={{
								backgroundColor: "#F9FAFC",
								border: "1px solid #6B7280",
								cursor: "pointer",
							}}
						>
							{`${b.fname} ${b.mname} ${b.lname}`}
						</span>
					);
				});
			}
		}
	};

	const beneficiaryOptionTemplate = (option) => {
		return <div>{`${option.fname} ${option.lname}: ${option.mobile}`}</div>;
	};

	const selectedBeneficiaryTemplate = (option, props) => {
		if (option) {
			return <div>{`${option.fname} ${option.lname}`}</div>;
		}

		return <span>{props.placeholder}</span>;
	};

	const handlePickBeneficiary = async (id) => {
		if (id === null || id === undefined) {
			setDisableFields(false);
			setMyBeneficiary({
				...myBeneficiary,
				id: null,
				first_name: "",
				last_name: "",
				middle_name: "",
				mobile: "",
				phone: "",
				phone_number: "",
				remitter_id: "",
				adding: true,
			});
			return;
		}
		const obj = await all_beneficiaries.find((el) => Number(el.beneficiary_id) === Number(id));
		const mobile = await obj.mobile.toString();
		// console.log("BENEF: ", obj);
		setMyBeneficiary({
			...myBeneficiary,
			id: obj.beneficiary_id,
			first_name: obj.fname,
			middle_name: obj.mname !== null && obj.mname.length !== 0 ? obj.mname : "",
			last_name: obj.lname,
			mobile: obj.mobile,
			phone_number: obj.mobile,
			phone: mobile.slice(-9),
			remitter_id: "",
			adding: false,
		});
		setDisableFields(true);
	};

	// let content = user.onboarded ? runDisplayBenefeciaries() : "";

	const handleBeneficiaryRedirect = () => {
		history.push(`/beneficiaries/${myBeneficiary.id}`)
	}

	const validateBenef = Yup.object().shape({
		first_name: Yup.string()
			.required("First name is required")
			.matches(
				/^[A-Za-z\s]{1,}$/,
				"First name does not allow numeric values or special characters"
			),
		last_name: Yup.string()
			.required("Last name is required")
			.matches(
				/^[A-Za-z\s]{1,}$/,
				"Last name does not allow numeric values or special characters"
			),
		middle_name: Yup.string()
			.nullable()
			.matches(
				/^[A-Za-z\s]{1,}$/,
				"Middle name does not allow numeric values or special characters"
			),
		phone: Yup.string()
			.required("Mobile is required")
			.matches(
				/^[1-9]{1}[0-9]{1,}$/,
				"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
			)
			.min(9, "Invalid mobile number")
			.max(10, "Invalid mobile number"),
	});

	const handleSubmit = (data) => {
		setMyBeneficiary({
			...myBeneficiary,
			first_name: data.first_name,
			middle_name: data.middle_name,
			last_name: data.last_name,
			mobile: data.mobile.startsWith("+") ? data.mobile : "+" + data.mobile,
			phone: data.phone,
			phone_number: data.phone,
			remitter_id: ""
		});
		handleStep("beneficiaryStep", "forward");
	};

	return (
		<div>
			<Formik
				initialValues={myBeneficiary}
				validationSchema={validateBenef}
				onSubmit={handleSubmit}
				enableReinitialize={true}
			>
				{({ setFieldValue, ...formik }) => {
					return (
						<Form>
							{all_beneficiaries_error &&
								all_beneficiaries_error.map((err) => (
									<Error key={err.error} error={err.error} />
								))}
							<h5 className="fw-bold">Beneficiary Details</h5>
							{user && user.onboarded && (
								<div className="row">
									{/* <div className="col-lg-12">
										<HorizontalScroller>{content}</HorizontalScroller>
									</div> */}
									<div className="col-lg-12">
										<Dropdown
											value={selectedBeneficiary}
											valueTemplate={selectedBeneficiaryTemplate}
											options={all_beneficiaries}
											onChange={onBeneficiaryChange}
											optionLabel="name"
											itemTemplate={beneficiaryOptionTemplate}
											filter
											// showFilterClear
											// resetFilter
											showClear
											dropdownIcon="pi pi-arrow-down"
											filterBy="sname,lname,mobile"
											placeholder="Select Beneficiary"
											scrollHeight="300px"
											style={{ width: "100%", border: "1px solid #3B366F" }}
										/>
									</div>
								</div>
							)}
							<div className="row my-0">
								<div className="col-md-6 my-0">
									<TextField
										label="First Name*"
										name="first_name"
										placeholder="Enter First Name"
										disabled={disableFields}
									/>
								</div>

								<div className="col-md-6 my-0">
									<TextField
										label="Middle Name(s)"
										name="middle_name"
										placeholder="Enter Middle Name(s)"
										disabled={disableFields}
									/>
									<small>Optional</small>
								</div>
							</div>

							<TextField
								label="Last Name*"
								name="last_name"
								placeholder="Enter Last Name"
								disabled={disableFields}
							/>

							<MobileNumber
								label="Mobile Number*"
								name="phone"
								value={
									myBeneficiary && myBeneficiary.mobile
										? myBeneficiary.mobile
										: formik.values.mobile
								}
								// disabled={disableFields}
								inputProps={{
									name: "mobile",
									disabled: disableFields,
									placeholder: "Enter mobile number",
								}}
								country={"zw"}
								onlyCountries={["zw"]}
								onBlur={formik.handleBlur}
								onChange={(value, country, e) => {
									formik.handleChange(e);
									setFieldValue("mobile", value);
									setFieldValue("phone", value.slice(country.dialCode.length));
								}}
							/>
							{formik.errors && formik.errors.phone && (
								<span className="form-text small text-danger mt-0">
									{formik.errors.phone}
								</span>
							)}
							{disableFields && <div className="mt-4">To edit beneficiary <button style={{color: '#3B82F6', backgroundColor: '#fff'}} onClick={handleBeneficiaryRedirect}>CLICK HERE</button></div>}
							<div className="mt-4">
								<RegularButton
									onClick={() => handleStep("beneficiaryStep", "back")}
									value="Back"
									bg="bgYellow"
									type="button"
									floatright={false}
								/>
								<RegularButton value="Continue" type="submit" floatright={true} />
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

BeneficiaryDetails.propTypes = {
	authorization: PropTypes.object.isRequired,
	getBeneficiaries: PropTypes.object.isRequired,
	getAllBeneficiaries: PropTypes.func.isRequired,
	clearAllBeneficiariesError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	getBeneficiaries: state.getBeneficiaries,
});

export default connect(mapStateToProps, actions)(BeneficiaryDetails);
