/* eslint-disable import/no-anonymous-default-export */
import {UPGRADE_PROFILE, UPGRADE_PROFILE_ERROR, SET_UPGRADE_PROFILE_LOADING, CLEAR_UPGRADE_PROFILE_ERROR, CLEAR_UPGRADE_PROFILE} from '../actions/types';

const initialState = {
    upgrade_profile_data: null,
    upgrade_profile_loading: false,
    upgrade_profile_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case UPGRADE_PROFILE:
            return {
                ...state, upgrade_profile_error: null, upgrade_profile_data: action.payload, upgrade_profile_loading: false
            }
        case UPGRADE_PROFILE_ERROR:
            return {
                ...state, upgrade_profile_error: action.payload, upgrade_profile_loading: false
            }
        case SET_UPGRADE_PROFILE_LOADING:
            return {
                ...state, upgrade_profile_loading: true, upgrade_profile_error: null
            }
        case CLEAR_UPGRADE_PROFILE_ERROR:
            return {
                ...state, upgrade_profile_error: null, upgrade_profile_loading: false
            }
        case CLEAR_UPGRADE_PROFILE:
            return {
               ...state, upgrade_profile_data: null, upgrade_profile_loading: false, upgrade_profile_error: null 
            }
        default: return state  
    }
}