import React, { useState, useEffect, Fragment } from "react";
import {
	ContactDetails,
	SignedHeader,
	TextField,
	RegularButton,
	RadioButton,
	File,
	Error,
	Select,
	PageLoader,
	Modal,
} from "components";
import Footer from "components/Footer/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { useHistory } from "react-router-dom";
import Postcoder from "react-address-lookup";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SessionTimeout from "utils/SessionTimeout";

const ToLimited = (props) => {
	const history = useHistory();
	const { upgradeProfile, clearUpgradeProfileError, uioptions, getProfile } = props;
	const { user } = props.authorization;
	const [modalVisible, setModalVisible] = useState(false);

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Upgrade to Limited";
		return () => {
			subscribe = false;
		};
	}, []);

	const { ui } = props.uioptions;

	const [display, setDisplay] = useState({
		proof_of_address: false,
		id1_scan2_required: false,
	});

	React.useEffect(() => {
		getProfile("profile");
	}, [getProfile]);

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};

	const [displaySa, setDisplaySa] = useState({
		foreign_id_number_required: false,
		permit_number_required: false,
	});

	const { upgrade_profile_data, upgrade_profile_loading, upgrade_profile_error } =
		props.upgrdProfile;

	const { profile, profile_loading } = props.myProfile;

	const [country, setCountry] = useState("");

	const newCountry = [];

	useEffect(() => {
		if (ui) {
			ui.result.nationalities.forEach((element) => {
				newCountry.push({ name: element.name, id: element.value });
			});
			setCountry(newCountry);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = {
		post_code: profile && profile.remitone.postcode ? profile.remitone.postcode : "",
		upgrade_type: "limited",
		address_line_1: profile && profile.remitone.address1 ? profile.remitone.address1 : "",
		address_line_2: profile && profile.remitone.address2 ? profile.remitone.address2 : "",
		city: profile && profile.remitone.city ? profile.remitone.city : "",
		state_province: profile && profile.remitone.state ? profile.remitone.state : "",
		nationality: profile && profile.remitone.nationality ? profile.remitone.nationality : "",
		mobile: profile && profile.remitone.mobile ? profile.remitone.mobile : "",
		email: profile && profile.remitone.email ? profile.remitone.email : "",
		dob: profile && profile.remitone.dob ? formatDate(profile.remitone.dob) : "",
		id1_type: profile && profile.remitone.id1_type ? profile.remitone.id1_type : "",
		id1: "",
		id1_details: profile && profile.remitone.id1_details ? profile.remitone.id1_details : "",
		id1_issue_place:
			profile && profile.remitone.id1_issue_place ? profile.remitone.id1_issue_place : "",
		id1_issue_country:
			profile && profile.remitone.id1_issue_country ? profile.remitone.id1_issue_country : "",
		id1_expiry: profile && profile.remitone.id1_expiry ? profile.remitone.id1_expiry : "",
		id3: "",
		id3_type: profile && profile.remitone.id3_type ? profile.remitone.id3_type : "",
		id1_scan2: "",
		id2: "",
		id2_type: profile && profile.remitone.id2_type ? profile.remitone.id2_type : "",
		default_transfer_purpose:
			profile && profile.remitone.default_transfer_purpose
				? profile.remitone.default_transfer_purpose
				: "",
		id4: "",
		id4_type: profile && profile.remitone.id4_type ? profile.remitone.id4_type : "",
		id1_type_label: "",
	};

	const schema = Yup.object().shape(
		user && user.country === "UK"
			? {
					address_line_1: Yup.string().required().label("Address Line 1 "),
					id1_details: Yup.mixed().required("Details required"),
					id1_issue_place: Yup.string().required("ID issue place required"),
					id1_issue_country: Yup.string().required("ID issue country required"),
					nationality: Yup.string().required("Nationality is required "),
					// email: Yup.string()
					// 	.email("Invalid email address")
					// 	.required("Email address is required"),
					post_code: Yup.string().required().label("Post code "),
					id1_expiry: Yup.date()
						.required("ID expiry date required")
						.test(
							"expiry",
							"Expiry date should be greater than today",
							function (id1_expiry) {
								const today = new Date();
								return id1_expiry > today;
							}
						),
					city: Yup.string().required().label("City "),
					state_province: Yup.string().required().label("Province/state "),
					id1_type: Yup.mixed().required("ID required"),
					id1: Yup.mixed()
						.required("Can not proceed without uploading this file")
						.test(
							"type",
							"Only the following formats are accepted: .jpeg, .jpg, .pdf",
							(value) => {
								return (
									value &&
									(value.type === "image/jpeg" ||
										value.type === "image/png" ||
										value.type === "application/pdf")
								);
							}
						),

					id1_scan2: Yup.mixed().when("id1_type", {
						is: (val) => val === "Residence_Permit",
						then: Yup.mixed()
							.required("Can not proceed without uploading this file")
							.test(
								"type",
								"Only the following formats are accepted: .jpeg, .jpg, .pdf",
								(value) => {
									return (
										value &&
										(value.type === "image/jpeg" ||
											value.type === "image/png" ||
											value.type === "application/pdf")
									);
								}
							),
						otherwise: Yup.mixed().notRequired(),
					}),

					id2: Yup.mixed().when("id1_type", {
						is: (val) => val === "Passport" || val === "Other" || val === "Residence_Permit",
						then: Yup.mixed()
							.required("Can not proceed without uploading this file")
							.test(
								"type",
								"Only the following formats are accepted: .jpeg, .jpg, .pdf",
								(value) => {
									return (
										value &&
										(value.type === "image/jpeg" ||
											value.type === "image/png" ||
											value.type === "application/pdf")
									);
								}
							),
						otherwise: Yup.mixed().notRequired(),
					}),
			  }
			: {
					id1_type: Yup.string().required("ID required"),
					id2_type: Yup.string().required("ID2 type required"),
					id1_details: Yup.mixed().required("ID details required"),
					id1_issue_place: Yup.mixed().required("ID issue place required"),
					id1_issue_country: Yup.mixed().required("ID issue country required"),
					nationality: Yup.string().required("Nationality is required "),
					id1_expiry: Yup.date()
						.required("ID expiry date required")
						.test(
							"expiry",
							"Expiry date should be greater than today",
							function (id1_expiry) {
								const today = new Date();
								return id1_expiry > today;
							}
						),
					address_line_1: Yup.mixed().required().label("Address Line 1 "),
					city: Yup.string().required().label("City "),
					state_province: Yup.string().required().label("Province/state "),
					post_code: Yup.string().required().label("Post code "),
					id1: Yup.mixed()
						.required("Can not proceed without uploading this file")
						.test(
							"type",
							"Only the following formats are accepted: .jpeg, .jpg, .pdf",
							(value) => {
								return (
									value &&
									(value.type === "image/jpeg" ||
										value.type === "image/png" ||
										value.type === "application/pdf")
								);
							}
						),

					id3: Yup.string().when("id1_type", {
						is: (val) => val === "Passport" || val === "Foreign_National ID",
						then: Yup.string().required("Foreign ID Number required"),
						otherwise: Yup.string().notRequired(),
					}),

					id4: Yup.string().when("id1_type", {
						is: (val) => val === "Other",
						then: Yup.string().required("Resident permit is required"),
						otherwise: Yup.string().notRequired(),
					}),
			  }
	);

	const handleDocumentsUK = (type) => {
		const obj = ui.result.id_types.uk.id1.find((el) => el.code === type);
		if (obj.proof_of_address && obj.id1_scan2_required) {
			setDisplay({
				...display,
				proof_of_address: true,
				id1_scan2_required: true,
			});
		} else if (obj.proof_of_address) {
			setDisplay({
				...display,
				proof_of_address: true,
				id1_scan2_required: false,
			});
		} else if (obj.id1_scan2_required) {
			setDisplay({
				...display,
				proof_of_address: false,
				id1_scan2_required: true,
			});
		} else {
			setDisplay({
				...display,
				proof_of_address: false,
				id1_scan2_required: false,
			});
		}
	};

	React.useEffect(() => {
		const time_id = setInterval(() => {
			upgrade_profile_error && clearUpgradeProfileError();
		}, 9000);
		return () => {
			clearTimeout(time_id);
		};
	}, [clearUpgradeProfileError, upgrade_profile_error]);

	React.useEffect(() => {
		if (upgrade_profile_data) {
			history.push("/profile");
		}
	}, [upgrade_profile_data, history]);

	const handleDocumentsSA = (type) => {
		const obj = ui.result.id_types.sa.id1.find((el) => el.code === type);
		if (obj.foreign_id_number_required && obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: true,
			});
		} else if (obj.foreign_id_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: false,
			});
		} else if (obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: true,
			});
		} else {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: false,
			});
		}
	};

	const [upgradeProfileState, setUpgradeProfileState] = useState({});

	const handleSubmit = (data) => {
		// return console.log("DATA:", data);
		setUpgradeProfileState(data);
		setModalVisible(true);

		// upgradeProfile(data);
	};

	const handleFinalSubmit = () => {
		// return console.log("data", upgradeProfileState);
		setModalVisible(false);
		upgradeProfile(upgradeProfileState);
	};

	let content =
		profile_loading || upgrade_profile_loading ? (
			<PageLoader />
		) : (
			<div className="vh-100">
				<SessionTimeout />

				<div style={{ minHeight: "16%" }}>
					<SignedHeader />
				</div>

				<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-sm-5 d-none d-sm-block">
								<div className="bgFaintYellow rounded px-4 py-3 mb-4">
									<p>
										Process time varies based on the clarity of the documents submitted.
										The times vary between instant to 24 hours.
									</p>
								</div>

								<ContactDetails />
							</div>

							<div className="offset-xl-1 col-xl-6 offset-lg-1 col-lg-7 col-sm-7">
								<div className="alert alert-warning" role="alert">
									Fill in all mandatory (*) fields as they appear on your government issued
									photo ID. Any changes to your profile will require your account to be
									re-verified by our compliance team.
								</div>
								<h5 className="fw-bold col-12">Upgrade Account to Limited KYC</h5>

								{upgrade_profile_error &&
									upgrade_profile_error.map((err) => (
										<Error key={err.error} error={err.error} />
									))}

								<Formik
									initialValues={initialValues}
									validationSchema={schema}
									onSubmit={handleSubmit}
								>
									{({ setFieldValue, ...rest }) => {
										console.log("ERRORS", rest.errors);
										const logAddress = (addr) => {
											setFieldValue("address_line_1", addr.addressline1);
											setFieldValue("address_line_2", addr.addressline2);
											setFieldValue("city", addr.posttown);
											setFieldValue("state_province", addr.county);
											setFieldValue("post_code", addr.postcode);
										};

										return (
											<Form>
												{user && user.country === "UK" && (
													<Postcoder
														label="Address*"
														apiKey="PCW55-Y54T8-XN6GY-TQBRR"
														name="post_code"
														addressSelectedCallback={logAddress}
														placeholder="Postal Code"
													/>
												)}

												{user && user.country === "UK" && (
													<p className="mt-2 py-1 alert alert-warning">
														The address fields below can be conveniently filled by
														searching your post code above and selecting your address
													</p>
												)}

												<TextField
													label="Post Code*"
													placeholder="Enter postal code"
													name="post_code"
												/>

												<TextField
													label="Enter Address 1*"
													placeholder="Enter address line 1"
													name="address_line_1"
												/>

												<TextField
													label="Enter Address 2"
													placeholder="Enter address line 2"
													name="address_line_2"
												/>

												<TextField label="City*" placeholder="Enter city" name="city" />

												<TextField
													label="State/Province*"
													placeholder="State/Province"
													name="state_province"
												/>

												{ui && (
													<Select
														name="nationality"
														label="Nationality*"
														optionlabel="Choose nationality"
														options={country}
													/>
												)}

												<div className="alert alert-warning mt-2" role="alert">
													Select ID type below that you want it's associated documents
													uploaded. Make sure to upload the appropriate documents in
													the format: jpg, png, jpeg, or pdf
												</div>

												<p className="mt-3 mb-0 fw-bold py-0">Select ID Type</p>

												{user && user.country === "UK" && (
													<>
														{ui &&
															ui.result.id_types.uk.id1.map((value, index) => {
																return (
																	<RadioButton
																		name="id1_type"
																		label={value.type}
																		value={value.code}
																		onChange={(e) => {
																			handleDocumentsUK(e.target.value);
																			setFieldValue("id1_type", e.target.value);
																			setFieldValue(
																				"id1_type_label",
																				value.type
																			);
																		}}
																		id={"id1_type" + index}
																		key={"id1_type" + index}
																	/>
																);
															})}

														<div className="mt-3">
															<p className="mb-0 fw-bold">
																Upload{" "}
																{rest.values && rest.values.id1_type !== ""
																	? rest.values.id1_type_label
																	: "Document"}
																*
															</p>

															<File
																name="id1"
																onChange={(e) => {
																	setFieldValue("id1", e.target.files[0]);
																}}
															/>

															{display.id1_scan2_required && (
																<div className="mt-3">
																	<p className="mb-0 fw-bold">
																		Upload Back of the{" "}
																		{rest.values && rest.values.id1_type !== ""
																			? rest.values.id1_type_label
																			: "Document"}{" "}
																		*
																	</p>
																	<File
																		name="id1_scan2"
																		onChange={(e) => {
																			setFieldValue(
																				"id1_scan2",
																				e.target.files[0]
																			);
																		}}
																	/>
																</div>
															)}

															{display.proof_of_address && (
																<>
																	<div className="mt-3">
																		<p className="mb-0 fw-bold">
																			Upload Proof of Address*
																		</p>
																		{/* <DropzoneArea name="id2" className="border-0" /> */}
																		<File
																			name="id2"
																			onChange={(e) => {
																				setFieldValue("id2", e.target.files[0]);
																				setFieldValue(
																					"id2_type",
																					ui.result.id_types.uk.id2[0].code
																				);
																			}}
																		/>
																	</div>
																	<input name="id2_type" type="hidden" />
																</>
															)}

															<TextField
																label={
																	rest.values && rest.values.id1_type !== ""
																		? rest.values.id1_type_label + " Number*"
																		: "Details*"
																}
																name="id1_details"
																placeholder="Enter ID details"
																hidden={
																	user && user.country === "SA" ? true : false
																}
															/>
															<TextField
																label="Issue Place*"
																name="id1_issue_place"
																placeholder="Enter Issue Place"
															/>
															{ui && (
																<Select
																	name="id1_issue_country"
																	label="Issue Country*"
																	optionlabel="Choose issue country"
																	options={country}
																/>
															)}
															<TextField
																type="date"
																label="Expiry Date*"
																name="id1_expiry"
															/>
														</div>
													</>
												)}

												{user && user.country === "SA" && (
													<>
														{ui &&
															ui.result.id_types.sa.id1.map((value, index) => {
																return (
																	<RadioButton
																		name="id1_type"
																		label={value.type}
																		value={value.code}
																		onChange={(e) => {
																			handleDocumentsSA(e.target.value);
																			setFieldValue("id1_type", e.target.value);
																			setFieldValue(
																				"id1_type_label",
																				value.type
																			);
																			setFieldValue(
																				"default_transfer_purpose",
																				value.default_transfer_purpose
																			);
																		}}
																		id={"id1_type" + index}
																		key={"id1_type" + index}
																	/>
																);
															})}

														<div className="mt-3">
															<p className="mb-0 fw-bold">
																Upload{" "}
																{rest.values && rest.values.id1_type !== ""
																	? rest.values.id1_type_label
																	: "Document"}
																*
															</p>

															<File
																name="id1"
																onChange={(e) => {
																	setFieldValue("id1", e.target.files[0]);
																}}
															/>

															{displaySa.permit_number_required && (
																<TextField
																	label="Permit Number*"
																	placeholder="Enter Permit Number"
																	name="id4"
																	onInput={(e) => {
																		setFieldValue(
																			"id4_type",
																			ui.result.id_types.sa.id4[0].code
																		);
																	}}
																/>
															)}

															{displaySa.foreign_id_number_required && (
																<TextField
																	label="Foreign ID Number*"
																	placeholder="Enter Foreign ID Number"
																	name="id3"
																	onInput={(e) => {
																		setFieldValue(
																			"id3_type",
																			ui.result.id_types.sa.id3[0].code
																		);
																	}}
																/>
															)}
														</div>

														<div className="mt-3">
															<p className="mb-0 fw-bold">Upload Selfie*</p>
															<File
																name="id2"
																onChange={(e) => {
																	setFieldValue("id2", e.target.files[0]);
																	setFieldValue(
																		"id2_type",
																		ui.result.id_types.sa.id2[0].type
																	);
																}}
															/>
														</div>
													</>
												)}

												{user && user.country === "SA" && (
													<>
														<TextField
															label={
																rest.values && rest.values.id1_type !== ""
																	? rest.values.id1_type_label + " Number*"
																	: "Details*"
															}
															name="id1_details"
															placeholder="Enter ID details"
														/>
														<TextField
															label="Issue Place*"
															name="id1_issue_place"
															placeholder="Enter Issue Place"
														/>
														{ui && (
															<Select
																name="id1_issue_country"
																label="Issue Country*"
																optionlabel="Choose issue country"
																options={country}
															/>
														)}
														<TextField
															type="date"
															label="Expiry Date*"
															name="id1_expiry"
														/>
													</>
												)}

												<div
													className="alert alert-warning float-start mt-3"
													role="alert"
												>
													Please make sure all the details of your profile are correct
													and all mandatory (*) fields are filled in before saving the
													changes, as any incorrect information will hinder the
													verification of your account.
												</div>

												<div className="mt-3">
													<RegularButton
														value="Upgrade"
														type="submit"
														floatright={true}
													/>
													<Modal
														modalVisible={modalVisible}
														setModalVisible={setModalVisible}
													>
														<p className="p-4 rounded bgFaintYellow">
															Please make sure all the details of your profile are
															correct and all mandatory (*) fields are filled in
															before saving the changes, as any incorrect information
															will hinder the verification of your account
														</p>
														<p className="fw-bold">Are you sure you want proceed?</p>
														<RegularButton
															value="No"
															type="button"
															bg="bgYellow"
															onClick={() => setModalVisible(false)}
															floatright={false}
														/>
														&nbsp;
														<RegularButton
															value="Proceed"
															onClick={handleFinalSubmit}
															type="button"
															floatright={false}
														/>
													</Modal>
												</div>
											</Form>
										);
									}}
								</Formik>
							</div>
						</div>
					</div>
				</div>
				<div style={{ minHeight: "14%" }}>
					<Footer user={user} uioptions={uioptions} />
				</div>
			</div>
		);

	return <Fragment>{content}</Fragment>;
};

ToLimited.propTypes = {
	upgrdProfile: PropTypes.object.isRequired,
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
	clearUpgradeProfileError: PropTypes.func.isRequired,
	upgradeProfile: PropTypes.func.isRequired,
	clearUpgradeProfile: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
	upgrdProfile: state.upgrdProfile,
	myProfile: state.myProfile,
});

export default connect(mapStateToProps, actions)(ToLimited);
