export const formattedStatus = (status) => {
	// console.log(status)
	switch (status) {
		case "PENDING_CLEARANCE":
			return "Awaiting Payment";
		case "SENT_FOR_PAY":
			return "Awaiting Collection";
		case "HQ_OK":
			return "Awaiting Collection";
		case "AGENT_OK":
			return "Compliance Checking";
		case "PROCESSED":
			return "Collected";
		case "ABORTED":
			return "Cancelled";
		case "DELETED":
			return "Deleted";
		default:
			return status;
	}
};
export const formattedPaymentMethod = (method) => {
	return (method === "AFXeZaga" || method === "ORMEzagaPay" || method === "ORMPay360" || method === "AFXPay360" || method === "ORMAxcess") ? "Credit/Debit Card" : "Bank Transfer";
};

export const displayAPIErrors = (errors) => {
	Object.keys(errors).map((error) => {
		return (
			<div className="alert alert-danger" role="alert">
				{error.error}
			</div>
		);
	});
};
