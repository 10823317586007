import React from "react";
import { ErrorMessage, useField } from "formik";

const RadioButton = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <div
        className="form-check rounded mt-2"
        style={{ border: "1px solid #3B366F" }}
      >
        <div className="className px-2">
          <input
            className={`form-check-input my-2 ${
              meta.touched && meta.error && "is-invalid"
            }`}
            type="radio"
            id={props.id}
            {...field}
            {...props}
          />
          <label
            className="form-check-label py-2 ps-2"
            htmlFor={props.id}
            style={{ borderLeft: "1px solid #3B366F" }}
          >
            {props.label}
          </label>
        </div>
      </div>
      <ErrorMessage
        component="div"
        name={field.name}
        className="ms-2 mt-0 text-danger"
      />
    </>
  );
};

export default RadioButton;
