import React from "react";

const MobileInput = ({ ...props }) => {
  return (
    <>
      <div className="form-group mt-2">
        <label htmlFor={props.name}>{props.label}</label>
        <div
          className="input-group rounded"
          style={{ width: "100%", border: "1px solid #3B366F" }}
        >
          <span
            className="input-group-text bg-white border-0 pe-0"
            style={{ width: "10%" }}
          >
            <img src={props.img} alt="ZIM" width={30} />
          </span>
          <select
            className="form-select border-0 px-0"
            name="phoneCode"
            style={{ width: "13%" }}
          >
            <option defaultValue>{props.code}</option>
          </select>
          <input
            type="number"
            id={props.name}
            className={`form-control border-0 `}
            {...props}
            style={{ width: "77%" }}
          />
        </div>
      </div>
    </>
  );
};

export default MobileInput;
