import React, { useEffect } from "react";
import { TextFieldTwo, MobileInputTwo, RadioButtonTwo } from "components";
import { uk, sa } from "assets/flags";
// import uk from "assets/flags/uk.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

const LightKyc = ({ ...props }) => {
  const { errors, register, getUIoptions } = props;

  const { ui } = props.uioptions;
  const { user } = props.authorization;


  useEffect(() => {
    !ui && getUIoptions();
  }, [getUIoptions, ui]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <TextFieldTwo
            label="First Name*"
            {...register("first_name", { required: true })}
            placeholder="Enter First Name"
          />
          {errors.first_name && errors.first_name.type === "required" && (
            <span>*This field is required</span>
          )}
        </div>

        <div className="col-md-6">
          <TextFieldTwo
            label="Middle Name(s)"
            {...register("middle_name")}
            placeholder="Enter Middle Name(s)"
          />
          <small>Optional</small>
        </div>
      </div>

      <TextFieldTwo
        label="Last Name*"
        {...register("last_name", { required: true })}
        placeholder="Enter Last Name"
      />
      {errors.last_name && errors.last_name.type === "required" && (
        <span>*This field is required</span>
      )}

      <MobileInputTwo
        {...register("mobile", { required: true })}
        label="Mobile Number*"
        img={user.country === "UK" ? uk : sa}
        // code="+44"
        code={user.country === "UK" ? '+27' : '+27'}
      />
      {
        errors.mobile && errors.mobile.type === "required" && (
          <span>*This field is required</span>
        )
      }

      < TextFieldTwo
        label="Date of Birth*"
        {...register("dob", { required: true })}
        type="date"
        placeholder="Enter date of birth"
      />
      {errors.dob && errors.dob.type === "required" && (
        <span>*This field is required</span>
      )}

      <div className="row">
        {ui &&
          ui.result.gender_types.map((type) => (
            <div className="col-md-6">
              <RadioButtonTwo
                label={type.gender}
                {...register("gender", { required: true })}
                defaultValue={type.gender}
                id={type.gender}
              />
            </div>
          ))}
        {errors.gender && errors.gender.type === "required" && (
          <span>*This field is required</span>
        )}
      </div>
    </div>
  );
};

LightKyc.propTypes = {
  uioptions: PropTypes.object.isRequired,
  authorization: PropTypes.object.isRequired,

};

const mapStateToProps = (state) => ({
  uioptions: state.uioptions,
  authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(LightKyc);
