/* eslint-disable import/no-anonymous-default-export */
import {SET_POLL_PAYMENT_LOADING, CLEAR_POLL_PAYMENT_ERROR, CLEAR_POLL_PAYMENT, POLL_PAYMENT_ERROR, POLL_PAYMENT} from '../actions/types';

const initialState = {
    poll_data: null,
    poll_loading: false,
    poll_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case SET_POLL_PAYMENT_LOADING:
            return {
                ...state,
                poll_loading: true,
                poll_error: null
            }
        case CLEAR_POLL_PAYMENT_ERROR:
            return {
                ...state,
                poll_loading: false,
                poll_error: action.payload
            }
        case CLEAR_POLL_PAYMENT:
            return {
                ...state,
                poll_data: null,
                poll_loading: false,
                poll_error: null 
            }
        case POLL_PAYMENT_ERROR:
            return {
                ...state,
                poll_loading: false,
                poll_error: action.payload
            }
        case POLL_PAYMENT:
            return {
                ...state,
                poll_loading: false,
                poll_error: null,
                poll_data: action.payload,
            }
        default: return state
    }
}