import React, { useEffect } from "react";
import SessionTimeout from "utils/SessionTimeout";
import { SignedHeader, Footer, ContactDetails, PageLoader } from "components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { FiCheck } from "react-icons/fi";
import { Link } from "react-router-dom";

const AccountLocked = (props) => {
	const { uioptions, getProfile } = props;
	const { ui } = props.uioptions;
	const { profile, profile_loading } = props.myProfile;
	let history = useHistory();
	const { user, user_loading } = props.authorization;
	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Account Locked";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		if (user.onboarded) {
			const run = async () => {
				await getProfile("auth");
				await getProfile("profile");
			};

			run();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getProfile]);

	React.useEffect(() => {
		if (user && profile) {
			// (user.remitone_status === 'valid' && profile.remitone.status === 'valid') && window.history.back();
			user.remitone_status === "valid" &&
				profile.remitone.status === "valid" &&
				history.goBack();
			console.log("activate user");
		}
	}, [user, profile, history]);

	let content =
		user_loading || profile_loading ? (
			<PageLoader />
		) : (
			<div className="vh-100">
				<SessionTimeout />
				<div style={{ minHeight: "16%" }}>{<SignedHeader />}</div>

				<div
					className="py-0 py-sm-3 d-flex align-items-center justify-content-center"
					style={{ minHeight: "70%" }}
				>
					<div className="container" style={{ width: "100%" }}>
						<div className="row px-2 px-sm-0">
							<div className="col-12 col-xl-3 col-lg-4 col-md-4 order-last order-md-first">
								<ContactDetails />
							</div>
							<div className="col-12 offset-xl-1 col-xl-6 col-lg-1 col-lg-6 col-md-1 col-md-6 px-4 rounded py-5 border bgYellow">
								<h4>Hello {user.first_name},</h4>
								<p className="border border-secondary text-white px-3 py-2 rounded ">
									<FiCheck /> Your account was upgraded successfully
								</p>
								{ui && user && user.remitone_status === "blocked" && (
									<p>{ui.result.disclaimers.awaiting_verification.blocked}</p>
								)}
								{ui && user && user.remitone_status === "expired" && (
									<p>{ui.result.disclaimers.awaiting_verification.expired}</p>
								)}
								{ui && user && user.remitone_status === "valid" && (
									<p>{ui.result.disclaimers.awaiting_verification.blocked}</p>
								)}

								<p>NB: Meanwhile, you can only do the following activities:</p>
								<ul>
									<li>
										<Link to="/profile" style={{ textDecoration: "none" }}>
											View Profile
										</Link>
									</li>
									<li>
										<Link to="/profile/update" style={{ textDecoration: "none" }}>
											Update Profile
										</Link>
									</li>
									<li>
										<Link to="/transactions" style={{ textDecoration: "none" }}>
											View Transactions
										</Link>
									</li>
									<li>
										<Link to="/change-password" style={{ textDecoration: "none" }}>
											Change Password
										</Link>
									</li>
									<li>
										<Link to="/beneficiaries" style={{ textDecoration: "none" }}>
											View Beneficiaries
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div style={{ minHeight: "14%" }}>
					<Footer user={user} uioptions={uioptions} />
				</div>
			</div>
		);

	return <>{content}</>;
};

// export default AccountLocked;

AccountLocked.propTypes = {
	changePassword: PropTypes.func.isRequired,
	clearChangePasswordError: PropTypes.func.isRequired,
	changePasswordReducer: PropTypes.object.isRequired,
	getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	changePasswordReducer: state.changePasswordReducer,
	uioptions: state.uioptions,
	authorization: state.authorization,
	myProfile: state.myProfile,
});

export default connect(mapStateToProps, actions)(AccountLocked);
