import { ContactDetails, Footer, Modal, RegularButton } from "components";
import React, { useState } from "react";
import SignedHeader from "components/SignedHeader/SignedHeader";

import bidvest from "assets/bidvestlogo.png";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import PageLoader from "components/Loaders/PageLoader";
import SessionTimeout from "utils/SessionTimeout";

const CheckOut = (props) => {
	const [modalVisibleInfo, setModalVisibleInfo] = useState(false);

	const { uioptions, getSingleTransaction, clearGetSingleTransactionError, getProfile } = props;

	const { user, user_loading, user_error } = props.authorization;

	const { transaction, transaction_loading, transaction_error } =
		props.getSingleTransactionReducer;

	React.useEffect(() => {
		getSingleTransaction(props.match.params.slug);
		return () => {
			clearGetSingleTransactionError();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSingleTransaction, clearGetSingleTransactionError]);

	React.useEffect(() => {
		getProfile("auth");
	}, [getProfile]);

	let content = (transaction_loading, user_loading) ? (
		<PageLoader />
	) : transaction_error ? (
		<div className="alert alert-danger" role="alert">
			{transaction_error}
		</div>
	) : user_error ? (
		<div className="alert alert-danger" role="alert">
			{user_error}
		</div>
	) : (
		<div className="row align-items-center h-100">
			<div
				className="offset-lg-1 col-lg-12 offset-md-1 col-md-10 border border-2 rounded "
				style={{ boxShadow: "-1px -1px 2px 2px #FFF4E5" }}
			>
				<div className="row px-2 py-4">
					<div className="col-12 ">
						<img src={bidvest} alt="BIDVEST BANK" style={{ width: "100%" }} />
					</div>

					<div className="col-12 border-top border-bottom fw-bold py-3 my-3 ">
						<p>{uioptions.ui.result.bank.instruction}</p>
					</div>

					<div className="col-12 mb-2">
						<button
							className="btn btn-block w-100 bgBlue"
							onClick={() => setModalVisibleInfo(true)}
						>
							View payment procedure
						</button>

						<Modal
							modalVisible={modalVisibleInfo}
							setModalVisible={setModalVisibleInfo}
							header="PAYMENTS PROCEDURE"
						>
							<p>
								This procedure serves as a guideline for all forms of payments made to
								Access Forex by the client as per Section A.4 of the Exchange Control
								Rulings. Please note the following:
							</p>
							<ol>
								<li>
									Transactions are to be concluded within a period of{" "}
									<strong>two working days.</strong>
								</li>
								<li>
									<strong>
										No Cash deposits are to be made directly into Access Forex’s account.
									</strong>
								</li>
							</ol>

							<h5>EFT PAYMENT</h5>
							<ul>
								<li>
									A remitter may transfer by ways of electronic funds transfer (EFT) from
									their <strong>own local bank account</strong> for the sole purpose of
									remittances (not the account of a third party).
								</li>
								<li>
									Suitable documentary evidence must be provided (Proof of Payment) to
									Access Forex verifying that the funds were transferred to our account
									from the remitter’s own bank account.
								</li>
								<li>
									No screens shots or payment notification in a form messages will be
									accepted as a form POP.
								</li>
								<li>The POP must come from the remitter’s bank.</li>
								<li>
									Proof of payment must only be sent to the email address:{" "}
									<a href="mailto:eftsa@accessforex.com">eftsa@accessforex.com</a>
								</li>
								<li>
									The reference on the POP must be the{" "}
									<strong>transaction number (RC Number)</strong> as per the receipt.
								</li>
								<li>
									Subject line on the email must be the transaction number and the
									remitter’s name and surname.
								</li>
								<li>
									The funds transferred by the remitter must equal the amount on the
									receipt captured.
								</li>
								<li>
									Each transaction with a reference number ( RC Number) must have its own
									payment whether it is EFT or cash.
								</li>
							</ul>

							<h5>BULK PAYMENT</h5>
							<ul>
								<li>
									EFT and cash bulk payments for remittances are{" "}
									<strong>not permitted.</strong>
								</li>
							</ul>

							<h5>SPEED POINT</h5>
							<ul>
								<li>
									Payment in respect of a speed point at an Access Forex kiosk, the
									transaction may be settled by the remitters credit or debit card with the
									remitters name displayed on the card.
								</li>
								<li>
									Where the name of the remitter does not appear on the card suitable
									documentary evidence must be provided.
								</li>
								<li>A copy of the receipt from the speed point must be kept.</li>
								<li>
									Once the receipt is printed the transaction number (RC number) must be
									written on the speed point receipt, furthermore the card details must
									also be printed on the speed point receipt.
								</li>
								<li>
									Place the thermal receipt on top of the card, ensure that the card
									details (name and card number) are facing up, place a pen sideways flat
									on the thermal receipt then rub the receipt, you will notice that the
									card information with start to appear on the thermal paper, rub until the
									card information is visible.
								</li>
							</ul>
						</Modal>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Country</span>
							<span className="float-end">{uioptions.ui.result.bank.details.country}</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Bank Name</span>
							<span className="float-end">{uioptions.ui.result.bank.details.bank_name}</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Account Name</span>
							<span className="float-end">
								{uioptions.ui.result.bank.details.account_name}
							</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Account Number</span>
							<span className="float-end">
								{uioptions.ui.result.bank.details.account_number}
							</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Branch Code</span>
							<span className="float-end">
								{uioptions.ui.result.bank.details.branch_code}
							</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Amount</span>
							<span className="float-end fw-bold">
								{transaction && transaction.currency === "GBP" ? "£" : "R"}
								{transaction && transaction.remitt_pay}
							</span>
						</p>
					</div>

					<div className="co-12">
						<p>
							<span className="float-start">Reference</span>
							<span className="float-end fw-bold">
								{transaction && transaction.trans_ref}
							</span>
						</p>
					</div>

					<div className="col-12 border-top mt-3 pt-3">
						<p className="text-danger">{uioptions.ui.result.bank.warning}</p>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "15%" }}>
				<SignedHeader />
			</div>

			<div className="py-2" style={{ minHeight: "75%" }}>
				<div className="container">
					<div className="row">
						<div className="col-sm-3">
							<ContactDetails />
						</div>
						<div className="col-sm-6 offset-sm-1">
							<div>{content}</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ minHeight: "10%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

CheckOut.propTypes = {
	getSingleTransaction: PropTypes.func.isRequired,
	clearGetSingleTransactionError: PropTypes.func.isRequired,
	getSingleTransactionReducer: PropTypes.object.isRequired,

	newBeneficiary: PropTypes.func.isRequired,
	clearNewBeneficiaryError: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
	getSingleTransactionReducer: state.getSingleTransactionReducer,
});

export default connect(mapStateToProps, actions)(CheckOut);
