import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { TextField, ButtonLoader, Error, PasswordLogin, PageLoader, MobileNumber } from "components";
import { AuthLayout } from "layouts";
import * as actions from "store/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import { useClearCache } from 'react-clear-cache';
// import { ClearCacheProvider, useClearCacheCtx, useClearCache } from 'react-clear-cache';

const Login = (props) => {
	const history = useHistory();
	const { user, user_loading, user_error } = props.authorization;
	const { login, clearAuthorizationError, getUIoptions, uioptions, logout, clearUIoptionsError } =
		props;

	
	// const { isLatestVersion, emptyCacheStorage } = useClearCache();
	// const { isLatestVersion, emptyCacheStorage } = useClearCache();

	const [resetType, setResetType] = useState("email");

	const [otpStep,setOTPStep] = useState(false)

	const initialValues = {
		email: "",
		mobile: "",
		password: "",
	};

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Login";
		return () => {
			subscribe = false;
		};
	}, []);

	useEffect(() => {
		logout();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getUIoptions();
	}, [getUIoptions]);

	const schemaObject = {
		password: Yup.string().required().label("Password")
	};

	if (resetType === "mobile") schemaObject.mobile = Yup.string().required().label("Mobile Number");
	if (resetType === "email")
		schemaObject.email = Yup.string()
			.email("Invalid email address")
			.required("Email address is required");

	const loginSchema = Yup.object().shape(schemaObject);

	// const loginSchema = Yup.object().shape({
	// 	email: Yup.string().email("Invalid email address").required("Email address is required"),
	// 	password: Yup.string().required().label("Password"),
	// });

	const [otpCode, setOTPCode] = useState(null);

	function generateOTP() {
          
		// Declare a digits variable 
		// which stores all digits
		var digits ='0123456789';
		let OTP ='';
		for (let i = 0; i <4; i++) {
			OTP += digits[Math.floor(Math.random() * 10)];
		}
		return OTP;
	}

	const [optInput, setOTPInput] = useState("")
	const [optError, setOTPError] = useState("")

	const verifyOTP = () => {
		if(optInput == otpCode){
			history.push("/send-money")
		}else{
			setOTPError("Invalid OTP")
		}
	}

	const sendOTP = (mobile_number) => {

		const otp = generateOTP();

		setOTPCode(otp)

		fetch('https://lzqmpw.api.infobip.com/sms/2/text/advanced', {
		method: 'POST',
		headers: { 
			'Accept': 'application/json',
            'Content-Type': 'application/json',
			'Authorization': 'App 2d608bd8d70fba04beb708cbda9f2d8e-20dbe2a6-883e-4ca3-b5fe-29adafb5e657' 
		},
		body: JSON.stringify({
			messages: [
				{
					from: "Access_Fx",
					destinations: {
						to: mobile_number,
					},
					text: `Your Access Forex OTP is ${otp}. Use this code to verify your account.`,
				}
			]
		}),
		})
		.then((res) => res.json())
		.then((data) => {
			// Do some stuff ...
		})
		.catch((err) => console.log(err));
	}

	useEffect(() => {
		user && history.push("/send-money");
		// if(user && !otpCode) {
		// 	sendOTP("+263784629765");
		// 	// sendOTP(user.mobile);
		// 	setOTPStep(true)
		// } 
		// console.log("user", user);
		return () => {
			clearAuthorizationError();
		};
	}, [user, history, clearAuthorizationError]);

	const handleLogin = (formData) => {
		login(formData);
	};

	useEffect(() => {
		const time_id = setInterval(() => {
			user_error && clearAuthorizationError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [user_error, clearAuthorizationError]);


	// Function to clear complete cache data
	// const clearCacheData = () => {
	// 	caches.keys().then((names) => {
	// 		names.forEach((name) => {
	// 			caches.delete(name);
	// 		});
	// 	});
	// 	console.log('Complete Cache Cleared')
	// };


	useEffect(() => {
		// clearCacheData();
		// if (!isLatestVersion) {
		// 	console.log("Different version detected.");
		// 	clearCacheData();
		// 	emptyCacheStorage();
		// } else {
		// 	console.log("Version is up to date.");
		// 	clearCacheData();
		// }
	}, []);


	let content = uioptions.ui_loading ? (
		<PageLoader />
	) : (
		<AuthLayout uioptions={uioptions} clearUIoptionsError={clearUIoptionsError} pagetitle="LOGIN">
			{
				<>
					{otpStep ? 
					<div className="form-group mobile-input-container">
						{/* <input type="text" label="OTP*" name="otp" /> */}
						<div className="alert alert-success" role="alert">
							An OTP has been sent to your mobile number. Please enter the OTP sent to your mobile number to verify your account.
						</div>
						{optError ? <div className="alert alert-danger" role="alert">
							{optError}
						</div> : null}
						<input onChange={(e) => {
							setOTPInput(e.target.value);
							setOTPError('')
						}} className="p-inputtext p-component p-filled p-d-block outlinenone false" placeholder="Enter OTP HERE"  label="Enter OTP"  style={{width: "100%", border: "1px solid rgb(59, 54, 111)", textAlign:'center'}}></input>
						{otpCode ? <span onClick={() => sendOTP("+263737705149")} style={{cursor:'pointer', display:'flex', width:'100%', justifyContent:'center', margin:'10px 0'}}>Resend OTP</span> : null}
						<button onClick={() => verifyOTP()} className="btn btn-primary bgBlue" style={{marginTop:10, marginBottom: 20, display:'flex',justifyContent:'center', alignItems:'center', width:'100%'}} type="submit">
							Verify
						</button>
					</div> :
				<Formik
					initialValues={initialValues}
					validationSchema={loginSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						values.type = resetType;
						let newFormData = values;
						if (newFormData.mobile) {
							let mob = `+${newFormData.mobile}`
							newFormData.mobile = `+${newFormData.mobile}`;
						}
						handleLogin(newFormData);
						// alert(JSON.stringify(values));
						setSubmitting(false);
					}}
				>
						{({ setFieldValue, ...formik }) => (
						<Form>
							<p className="mt-0 mb-0">
								<Link
									to="/register"
									style={{
										textDecoration: "none",
										color: "#3B366F",
									}}
								>
									Not yet a member? <strong style={{background:'#ccc', borderRadius:5, padding:'0 10px'}}>Sign Up</strong>
								</Link>
							</p>
							{user_error && user_error.map((err) => <Error key={err.error} error={err.error} />)}
							{/* <p className="mt-4">LOG IN</p> */}
							{/* <TextField label="Email*" name="email" /> */}

								<div className="row">
									{resetType === "mobile" ? (
										<div className="form-group mobile-input-container">
											{/* <label className="p-d-block fw-bold">Mobile Number*</label> */}
											{/* <PhoneInput
												name="mobile"
												country={"zw"}
												// value={values.mobile}
												// onChange={(mobile) => setFieldValue("mobile", mobile)}
												// onBlur={handleBlur}
												placeholder=""
												containerclassName={`phone-input`}
												autoFormat={false}
											/> */}

											<MobileNumber
												label="Mobile Number*"
												name="phone"
												value={formik.values.mobile}
												// disabled={disableFields}
												inputProps={{
													name: "mobile",
													placeholder: "Enter mobile number",
												}}
												country={"zw"}
												onlyCountries={["gb","za","zw"]}
												onBlur={formik.handleBlur}
												onChange={(value, country, e) => {
													formik.handleChange(e);
													setFieldValue("mobile", value);
												}}
											/>
											{formik.errors && formik.errors.phone && (
												<span className="form-text small text-danger mt-0">
													{formik.errors.phone}
												</span>
											)}
										
										</div>
									) : (
										<>
											<div className="form-group mobile-input-container">
												<TextField label="Email*" name="email" />
											</div>
										</>
									)}

									<div style={{margin:'10px 0', display:'flex', justifyContent:'flex-end'}}>
										<strong
											align="center"
											onClick={() => {
												if (resetType === "email") {
												}
												setResetType(resetType === "email" ? "mobile" : "email");
											}}
											style={{ cursor: "pointer", color: '#3B366F',background:'#ccc', borderRadius:5, padding:'0 10px' }}
										>
											Login using {resetType === "email" ? "Mobile Number" : "Email"}
										</strong>
									</div>

								</div>

							

							<PasswordLogin label="Password*" name="password" />
							{/* <TextField label="Password*" name="password" type="password" /> */}
							<div className="d-grid gap-2 mt-3">
								{user_loading ? (
									<ButtonLoader />
								) : (
									<button className="btn btn-primary bgBlue" type="submit">
										<i className="pi pi-lock"></i> Sign In
									</button>
								)}
							</div>

							<p className="mt-2 mb-10">
								<Link
									to="/reset-password-link"
									style={{
										textDecoration: "none",
										color: "#3B366F",
									}}
								>
									Forgot your password? <strong style={{background:'#ccc', borderRadius:5, padding:'0 10px'}}>Reset Password</strong>
								</Link>
							</p>
						</Form>
					)}
				</Formik>}
				</>

			}
		</AuthLayout>
	);

	return <>{content}</>;
};

Login.propTypes = {
	authorization: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	clearUIoptionsError: PropTypes.func.isRequired,
	clearAuthorizationError: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(Login);
