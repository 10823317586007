/* eslint-disable no-eval */
import React, { useState, useEffect } from "react";
import {
	UnsignedHeader,
	SignedHeader,
	ContactDetails,
	Step,
	CombinedKYC,
	PageLoader,
	Footer,
	Modal,
	ButtonLoader,
	RegularButton,
} from "components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import AmountAndDelivery from "./AmountAndDelivery";
import BeneficiaryDetails from "./BeneficiaryDetails";
import Confirmation from "./Confirmation";
import TransactionStatus from "./TransactionStatus";
import SessionTimeout from "utils/SessionTimeout";
import { FiExternalLink } from "react-icons/fi";
import Error from "components/Error/Error";

const SendMoney = (props) => {
	const [page, setPage] = useState("");
	const [customError, setCustomError] = useState({ id1: "" });
	const [amountStepIsOpen, setAmountStepIsOpen] = useState(true);
	const [amountPassed, setAmountPassed] = useState(false);
	const [modalVisibleInfo, setModalVisibleInfo] = useState(false);
	const [disableFields, setDisableFields] = useState(false);
	const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);

	const [beneficiaryStepIsOpen, setBeneficiaryStepIsOpen] = useState(false);
	const [beneficiaryPassed, setBeneficiaryPassed] = useState(false);

	const [senderStepIsOpen, setSenderStepIsOpen] = useState(false);
	const [senderPassed, setSenderPassed] = useState(false);

	const [confirmStepIsOpen, setConfirmStepIsOpen] = useState(false);
	const [confirmPassed, setConfirmPassed] = useState(false);

	const [transactionStepIsOpen, setTransactionStepIsOpen] = useState(false);

	const [calculated, setCalculated] = useState(false);
	const [declarationError, setDeclarationError] = useState("");

	const [modalVisibleMigration, setModalVisibleMigration] = useState(false);
	const [modalVisibleOtp, setModalVisibleOtp] = useState(false);
	const [otpValue, setOtpValue] = useState("");

	const [processingError] = useState(false);
	const [limitError, setLimitError] = useState(false);
	const [minAmntError, setMinAmountError] = useState(false);
	const [minimumRecievingAmount, setMinimumRecievingAmount] = useState({
		currency: "",
		amount: "",
	});
	const [, setChecked] = useState(false);

	const [amountsError, setAmountsError] = useState("");
	const [otpCustomError, setOTPcustomError] = useState(false);

	const [amountsState, setAmountsState] = useState({
		amount_to_send: "",
		amount_to_receive: "",
		destination_currency: "",
		trans_type: "",
		payment_method: "",
		purpose: "",
		promo_code: "",
	});

	const { ui, ui_loading } = props.uioptions;
	const { user, user_error, user_loading } = props.authorization;
	const { nb_data, nb_loading, nb_error } = props.newBeneficiaryReducer;
	const { remit_one, remit_one_error, remit_one_loading } = props.remitone;
	const { limits, limits_loading, limits_error } = props.getLimitsReducer;
	const { profile, profile_loading } = props.myProfile;
	const { transaction_charges_data, transaction_charges_loading, transaction_charges_error } =
		props.transactionCharges;
	const { onboarding_store } = props.onboardingPayload;
	const { initiate_payment_data, initiate_payment_loading, initiate_payment_error } =
		props.initPymnt;
	const { confirm_transaction, confirm_transaction_loading, confirm_transaction_error } =
		props.confirmTransactionReducer;
	const { new_transaction, new_transaction_error, new_transaction_loading } =
		props.newTransactionReducer;
	const { search_remitter_data, search_remitter_loading, search_remitter_error } =
		props.searchRemitterReducer;
	const { verify_otp_data, verify_otp_loading, verify_otp_error } = props.verifyOTPReducer;
	const { onboard_remitter_data, onboard_remitter_loading, onboard_remitter_error } =
		props.onboardRemitterReducer;
	const { send_otp_data, send_otp_loading, send_otp_error } = props.sendOTPReducer;

	const {
		getTransactionCharges,
		uioptions,
		storeOnboardingPayload,
		fullRegistration,
		remitOne,
		logout,
		clearAuthorizationError,
		clearRemitOneError,
		newBeneficiary,
		newTransaction,
		confirmTransaction,
		clearNewBeneficiary,
		clearInitiatePayment,
		initiatePayment,
		clearOnboardingPayload,
		clearConfirmTransaction,
		getLimits,
		clearGetLimits,
		clearTransactionPayloadError,
		clearNewBeneficiaryError,
		clearInitiatePaymentError,
		clearConfirmTransactionError,
		clearRemitOne,
		clearNewTransaction,
		getProfile,
		clearGetLimitsError,
		clearTransactionCharges,
		searchRemitter,
		clearSearchRemitterError,
		clearSearchRemitter,
		sendOTP,
		verifyOTP,
		onboardRemitter,
		clearOnboardRemitter,
		clearOnboardRemitterError,
		clearVerifyOTPerror,
		clearSendOTPerror,
	} = props;

	//STATE FOR AMOUNT AND DELIVERY
	const [amntToSend, setAmntToSend] = useState({
		amount_to_send: "",
		amount_to_receive: "",
		source_currency: "",
		trans_type: "",
		destination_currency: "",
		destination_country: "",
		country: "",
		payment_method: "",
		purpose: "",
		promo_code: "",
		ref_code: "",
	});

	//STATE FOR BENEFICIARY
	const [myBeneficiary, setMyBeneficiary] = useState({
		id: null,
		first_name: "",
		last_name: "",
		middle_name: "",
		mobile: "",
		phone: "",
		phone_number: "",
		remitter_id: "",
		adding: true,
	});

	const [myProfile, setMyProfile] = useState({
		first_name: "",
		last_name: "",
		middle_name: "",
		mobile: "",
		phone_number: "",
		phone: "",
		dob: "",
		gender: "",
		nationality: "",
		postal_code: "",
		address_line_1: "",
		address_line_2: "",
		city: "",
		state_province: "",
		id1_type: "",
		id1_details: "",
		id1_issue_place: "",
		id1_issue_country: "",
		id1_expiry: "",
		id1: "",
		default_transfer_purpose: "",
		id3_details: "",
		id3_type: "",
		id4_details: "",
		id4_type: "",
		id2: "",
		id2_type: "",
		id1_type_label: "",
	});

	const getCurrency = () => {
		let result = null;
		ui.result.countries.source.forEach((val) => {
			if (val.code === user.country) {
				result = val.currency;
			}
		});

		return result;
	};

	React.useEffect(() => {
		if (user.onboarded) {
			const run = async () => {
				await getProfile("profile");
				await getProfile("auth");
			};

			run();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getProfile]);

	const handleRequestOTP = () => {
		if (search_remitter_data) {
			const payload = {
				remitter_id: search_remitter_data.result.remitter.remitter_id,
				mobile: search_remitter_data.result.remitter.mobile,
			};
			sendOTP(payload);
		}
	};

	useEffect(() => {
		if (send_otp_data) {
			setModalVisibleMigration(false);
			setModalVisibleOtp(true);
		}
	}, [send_otp_data, setModalVisibleMigration, setModalVisibleOtp]);

	const handleVerifyOTP = () => {
		if (otpValue.length < 6) {
			setOTPcustomError(true);
		} else {
			setOTPcustomError(false);
			const payload = {
				remitter_id: search_remitter_data.result.remitter.remitter_id,
				otp: otpValue,
			};
			verifyOTP(payload);
		}
	};

	useEffect(() => {
		if (verify_otp_data && search_remitter_data) {
			onboardRemitter({ remitter_id: search_remitter_data.result.remitter.remitter_id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verify_otp_data, onboardRemitter]);

	useEffect(() => {
		if (onboard_remitter_data) {
			setModalVisibleOtp(false);
			// handleStep("senderStep", "forward");
			logout();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onboard_remitter_data]);

	useEffect(() => {
		clearInitiatePayment();
		clearNewBeneficiary();
		clearOnboardingPayload();
		clearConfirmTransaction();
		clearNewTransaction();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Send Money";
		return () => {
			subscribe = false;
		};
	}, []);
	//CALCULATE CHARGES
	useEffect(() => {
		transaction_charges_data && setCalculated(true);
	}, [transaction_charges_data]);

	//CLEAR ONBOARD REMITTER ERROR
	useEffect(() => {
		const time_id = setInterval(() => {
			onboard_remitter_error && clearOnboardRemitterError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [onboard_remitter_error, clearOnboardRemitterError]);

	//CLEAR SEND OTP ERROR
	useEffect(() => {
		const time_id = setInterval(() => {
			send_otp_error && clearSendOTPerror();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [send_otp_error, clearSendOTPerror]);

	// CLEAR VERIFY OTP ERROR
	useEffect(() => {
		const time_id = setInterval(() => {
			verify_otp_error && clearVerifyOTPerror();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [verify_otp_error, clearVerifyOTPerror]);

	const handleStep = (step, direction = "forward") => {
		if (step === "amountStep") {
			if (direction === "forward") {
				setAmountStepIsOpen(false);
				setAmountPassed(true);
				//// setBeneficiaryStepIsOpen(true);
				setSenderStepIsOpen(true);
				!user.onboarded ? setSenderStepIsOpen(true) : setBeneficiaryStepIsOpen(true);
			}
		} else if (step === "senderStep") {
			if (direction === "forward") {
				setSenderStepIsOpen(false);
				// setAmountStepIsOpen(false);

				setSenderPassed(true);
				setBeneficiaryStepIsOpen(true);
			}
			if (direction === "back") {
				setAmountStepIsOpen(true);
				setAmountPassed(false);
				setSenderStepIsOpen(false);
				setCalculated(false);
			}
		} else if (step === "beneficiaryStep") {
			if (direction === "forward") {
				setBeneficiaryStepIsOpen(false);
				setBeneficiaryPassed(true);
				setConfirmStepIsOpen(true);
				///// !user.onboarded ? setSenderStepIsOpen(true) : setConfirmStepIsOpen(true);
			}
			if (direction === "back") {
				if (!user.onboarded) {
					setSenderStepIsOpen(true);
					setSenderPassed(false);
				} else {
					setAmountStepIsOpen(true);
					setAmountPassed(false);
					setCalculated(false);
				}
				setBeneficiaryStepIsOpen(false);
				//// setCalculated(false);
			}
		} else if (step === "confirmStep") {
			if (direction === "forward") {
				setConfirmStepIsOpen(false);
				setConfirmPassed(true);
				setTransactionStepIsOpen(true);
			}
			if (direction === "back") {
				setConfirmStepIsOpen(false);

				//// if (user.onboarded) {
				setBeneficiaryStepIsOpen(true);
				setBeneficiaryPassed(false);
				////} else {
				//// 	setSenderStepIsOpen(true);
				//// 	setSenderPassed(false);
				//// }
			}
		}
	};

	useEffect(() => {
		getLimits();
		return () => {
			clearGetLimits();
		};
	}, [getLimits, clearGetLimits]);

	const calculateTotalCharges = () => {
		let currency;
		let charges;
		let tax;

		currency = user.country === "UK" ? "£" : "R";
		charges = transaction_charges_data ? transaction_charges_data.result.total_charges : 0;
		tax = transaction_charges_data ? transaction_charges_data.result.tax : 0;
		const totalCharges = Number(charges) + Number(tax);
		return `${currency} ${totalCharges.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
	};

	const checkTransactionLimit = (status, userAmount) => {
		const result = {
			reached: false,
			type: null,
		};

		// if (user.onboarded) {

		let corridor = user.country.toLowerCase();
		let bracket = ui.result.limits['uk'][status];
		let amounts_sent = limits ? limits.amountsSent : 0;

		// eslint-disable-next-line no-unused-vars
		const { transaction, day, month, quarter, year } = bracket;

		let options = ["transaction", "day", "month", "quarter", "year"];

		// eslint-disable-next-line array-callback-return
		options.some((option) => {
			if (eval(option) && Number(userAmount) + Number(amounts_sent[option]) > eval(option)) {
				result.reached = true;
				result.type = option;
				// eslint-disable-next-line array-callback-return
				return;
			}
		});

		// }

		return result;
	};

	const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

	const setAmountandDeliveryHandleChange = (e) => {
		setMinAmountError(false);

		switch (e.target.name) {
			case "amount_to_send":
				let sending_limit = checkTransactionLimit(user.kyc_status, e.target.value);
				sending_limit.reached ? setLimitError(true) : setLimitError(false);

				setAmntToSend({
					...amntToSend,
					[e.target.name]: e.target.value,
					amount_to_receive: "",
				});
				setAmountsError("");
				clearTransactionCharges();
				break;
			case "amount_to_receive":
				setAmountsError("");
				setLimitError(false);
				if (user.country === "UK") {
					let roundedAmount = roundNumber(e.target.value);

					let sAmount = roundedAmount / ui.result.rates.GBP_TO_USD;

					let receiving_limit = checkTransactionLimit(user.kyc_status, sAmount);

					receiving_limit.reached ? setLimitError(true) : setLimitError(false);
				}
				setAmntToSend({
					...amntToSend,
					[e.target.name]: e.target.value,
					amount_to_send: "",
				});
				clearTransactionCharges();

				break;
			default:
				setAmntToSend({
					...amntToSend,
					[e.target.name]: e.target.value,
				});
				clearTransactionCharges();
				break;
		}
	};

	const roundNumber = (n) => {
		// Smaller multiple
		let a = parseInt(n / 10, 10) * 10;
		// Larger multiple
		let b = a + 10;

		// Return of closest of two
		return n - a > b - n ? b : a;
	};

	const fetchMinimumAmount = (destination_currency) => {
		const result = ui.result.minimum_receiving_amounts[destination_currency];
		setMinimumRecievingAmount({
			...minimumRecievingAmount,
			currency: destination_currency,
			amount: result,
		});

		return Number(result);
	};

	const determineRate = () => {
		const rate =
			amntToSend.destination_currency === "ZAR" && amntToSend.source_currency === "ZAR"
				? ui.result.rates.ZAR_TO_ZAR
				: amntToSend.destination_currency === "USD" && amntToSend.source_currency !== "ZAR"
				? transaction_charges_data.result.rate
				: 1 / transaction_charges_data.result.rate;
		// : transaction_charges_data.result.rate;
		const currency =
			amntToSend.destination_currency === "ZAR" && amntToSend.source_currency === "ZAR"
				? "R"
				: amntToSend.destination_currency === "USD" && amntToSend.source_currency === "ZAR"
				? "R"
				: "$";

		return currency + Number(rate).toFixed(4);
	};

	useEffect(() => {
		const time_id = setInterval(() => {
			limits_error && clearGetLimitsError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [limits_error, clearGetLimitsError]);

	// handle calculate amount to send
	const handleAmntAndDeliverySubmit = (data) => {
		if (amntToSend.amount_to_send.length === 0 && amntToSend.amount_to_receive.length === 0) {
			setAmountsError("Please enter either sending amount or receiving amount");
		} else {
			let amount_to_send = amntToSend.amount_to_send;
			let amount_to_receive = amntToSend.amount_to_receive;

			let rates = ui.result.rates;

			let rateUsed =
				user.country === "UK"
					? rates.GBP_TO_USD
					: data.destination_currency === "USD"
					? rates.ZAR_TO_USD
					: rates.ZAR_TO_ZAR;
			let inverseRate =
				user.country === "UK"
					? rates.GBP_TO_USD
					: data.destination_currency === "USD"
					? rates.ZAR_TO_USD_ROUNDED
					: rates.ZAR_TO_ZAR;

			data.country = user.country;
			data.destination_country = "Zimbabwe";
			data.source_currency = getCurrency();
			data.amount_type = "DESTINATION";

			if (amount_to_send) {
				let rAmount = amount_to_send * rateUsed;

				let roundedAmount = roundNumber(rAmount);

				let newAmount = roundedAmount / inverseRate;
				let limit = checkTransactionLimit(user.kyc_status, newAmount);
				let minimumAmount = fetchMinimumAmount(data.destination_currency);

				setAmntToSend({
					...amntToSend,
					amount_to_receive: roundedAmount.toFixed(2),
					amount_to_send: newAmount.toFixed(2),
					source_currency: user.country === "UK" ? "GBP" : "ZAR",
					trans_type: data.trans_type,
					destination_currency: data.destination_currency,
					destination_country: "Zimbabwe",
					country: user.country,
					payment_method: data.payment_method,
					purpose: data.purpose,
					promo_code: data.promo_code,
					ref_code: data.ref_code,
				});

				if (roundedAmount < minimumAmount) {
					setMinAmountError(true);
				} else if (limit.reached) {
					setLimitError(true);
				} else {
					// data.amount_to_send = newAmount;
					data.amount_to_send = roundedAmount;
					getTransactionCharges(data);
				}
			} else if (amount_to_receive) {
				let roundedAmount = roundNumber(amount_to_receive);

				let sAmount = roundedAmount / inverseRate;

				let limit = checkTransactionLimit(user.kyc_status, sAmount);

				let minimumAmount = fetchMinimumAmount(data.destination_currency);
				setAmntToSend({
					...amntToSend,
					amount_to_receive: roundedAmount.toFixed(2),
					amount_to_send: sAmount.toFixed(2),
					source_currency: user.country === "UK" ? "GBP" : "ZAR",
					trans_type: data.trans_type,
					destination_currency: data.destination_currency,
					destination_country: "Zimbabwe",
					country: user.country,
					payment_method: data.payment_method,
					purpose: data.purpose,
					promo_code: data.promo_code,
					ref_code: data.ref_code,
				});
				if (roundedAmount < minimumAmount) {
					setMinAmountError(true);
				} else if (limit.reached) {
					setLimitError(true);
				} else {
					// data.amount_to_send = sAmount;
					data.amount_to_send = roundedAmount;
					getTransactionCharges(data);
				}
			}
		}
	};

	const handleBeneficiarySubmit = (data) => {
		let phoneNumber = data.mobile.substr(-16, 9);
		let mobile = "+263" + phoneNumber.toString();
		myBeneficiary.adding &&
			setMyBeneficiary({
				...myBeneficiary,
				first_name: data.first_name,
				last_name: data.last_name,
				middle_name: data.middle_name,
				mobile: mobile,
				remitter_id: "",
			});
		handleStep("beneficiaryStep", "forward");
	};

	const performBasicRegistration = async (data) => {
		clearInitiatePayment();
		clearNewTransaction();
		clearNewBeneficiary();
		clearConfirmTransaction();
		if (data.declaration) {
			const data = {
				amntToSend: amntToSend,
				recipient: {
					...myBeneficiary,
					mobile: myBeneficiary.mobile,
				},
				profile: {
					...myProfile,
					mobile: myProfile.mobile,
				},
			};

			await storeOnboardingPayload(data);

			if (!user.remitter_id) {
				const profilePayload = {
					first_name: myProfile.first_name,
					middle_name: myProfile.middle_name ? myProfile.middle_name : "",
					last_name: myProfile.last_name,
					mobile: myProfile.mobile,
					dob: myProfile.dob,
					gender: myProfile.gender,
					id1_details: user.country === "SA" ? myProfile.id1_details : undefined,
					id1_type: user.country === "SA" ? myProfile.id1_type : undefined,
					id2: user.country === "SA" ? myProfile.id2 : undefined,
					id2_type: user.country === "SA" ? myProfile.id2_type : undefined,
					nationality: user.country === "SA" ? myProfile.nationality : undefined,
					postal_code: user.country === "SA" ? myProfile.postal_code : undefined,
					address_line_1: user.country === "SA" ? myProfile.address_line_1 : undefined,
					address_line_2: user.country === "SA" ? myProfile.address_line_2 : undefined,
					city: user.country === "SA" ? myProfile.city : undefined,
					state_province: user.country === "SA" ? myProfile.state_province : undefined,
					id1_issue_country: user.country === "SA" ? myProfile.id1_issue_country : undefined,
					id1_issue_place: user.country === "SA" ? myProfile.id1_issue_place : undefined,
					id1_expiry: user.country === "SA" ? myProfile.id1_expiry : undefined,
					id3_details: user.country === "SA" ? myProfile.id3_details : undefined,
					id3_type: user.country === "SA" ? myProfile.id3_type : undefined,
					id4_details: user.country === "SA" ? myProfile.id4_details : undefined,
					id4_type: user.country === "SA" ? myProfile.id4_type : undefined,
					purpose_of_remittance: amntToSend.purpose,
					default_transfer_purpose:
						user.country === "SA" ? myProfile.default_transfer_purpose : undefined,
				};

				await fullRegistration(profilePayload);
				await setPage("remit-one");
			} else {
				// return console.log("SEDN DATA", data);

				myBeneficiary.id ? setPage("new-transaction") : setPage("add-beneficiary");
			}
		}
	};

	//TRIGGER PROCESS TO MAKE PAYMENT FOR AN UNBOARDED USER
	React.useEffect(() => {
		if (user) {
			if (page === "remit-one") {
				if (user.first_name && !remit_one) {
					remitOne();
					setPage("add-beneficiary");
				} else if (remit_one && remit_one.remitter_id) {
					setPage("add-beneficiary");
				}
			}
			//add-beneficiary
			if (page === "add-beneficiary") {
				if (user.onboarded) {
					onboarding_store.recipient.remitter_id = user.remitter_id;
					onboarding_store.recipient.id = undefined;
					newBeneficiary(onboarding_store.recipient);
					setPage("new-transaction");
				} else {
					if (remit_one) {
						onboarding_store.recipient.remitter_id = remit_one.remitter_id;
						onboarding_store.recipient.id = undefined;
						newBeneficiary(onboarding_store.recipient);
						setPage("new-transaction");
					}
				}
			}

			if ((nb_data || !myBeneficiary.adding) && page === "new-transaction") {
				let data = {
					country: user.country,
					remitter_id: remit_one ? remit_one.remitter_id : user.remitter_id,
					beneficiary_id: nb_data ? nb_data.new_beneficiary_id : onboarding_store.recipient.id,
					payment_method: onboarding_store.amntToSend.payment_method,
					sms_benef_mobile: onboarding_store.recipient.mobile,
					source_currency: onboarding_store.amntToSend.source_currency,
					dest_currency: onboarding_store.amntToSend.destination_currency,
					// amount_to_send: onboarding_store.amntToSend.amount_to_send,
					amount_to_send: onboarding_store.amntToSend.amount_to_receive,
					sms_mobile:
						// onboarding_store.profile.mobile.length > 0
						onboarding_store.profile.mobile ? onboarding_store.profile.mobile : user.mobile,
					trans_type: onboarding_store.amntToSend.trans_type,
					purpose: onboarding_store.amntToSend.purpose,
					amount_type: "DESTINATION",
				};
				newTransaction(data);
				setPage("confirm-transaction");
			}

			if (new_transaction && page === "confirm-transaction") {
				let data = {
					country: user.country,
					trans_session_id: new_transaction.trans_session_id,
				};
				confirmTransaction(data);
				setPage("initiate-payment");
			}

			if (confirm_transaction && page === "initiate-payment") {
				let pm_obj = ui.result.payment_methods[user.country].find(
					(el) => Number(el.id) === Number(onboarding_store.amntToSend.payment_method)
				);
				let path = pm_obj.name === "Credit/Debit Card" ? "transactions" : "checkout";
				let data = {
					member_id : user.remitter_id,
					transaction_reference: confirm_transaction.reference_number,
					payment_method: onboarding_store.amntToSend.payment_method,
					currency: onboarding_store.amntToSend.source_currency,
					amount: onboarding_store.amntToSend.amount_to_send,
					return_url: `${window.location.origin}/${path}/${confirm_transaction.reference_number}`,
				};
				setPage("payment-gateway-redirect");
				initiatePayment(data);
			}

			if (initiate_payment_data && page === "payment-gateway-redirect") {
				window.location.assign(initiate_payment_data.payment_url);
				// history.push(`transactions/${confirm_transaction.reference_number}`)
				setPage("process-complete");
				clearInitiatePayment();
				clearNewBeneficiary();
				clearOnboardingPayload();
				clearConfirmTransaction();
				clearRemitOne();
				clearNewTransaction();
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		user,
		remit_one,
		nb_data,
		new_transaction,
		page,
		confirm_transaction,
		initiate_payment_data,
	]);

	const handleChange = (files) => {
		setMyProfile({
			...myProfile,
			id1: files,
		});
	};

	const getUserTransactionlimit = () => {
		const corridor = user.country.toLowerCase();
		const kyc_status = profile ? profile.middleware.kyc_status : user.kyc_status;

		let bracket;
		if (user.country === "UK") {
			switch (kyc_status) {
				case "lite":
					bracket = "day";
					break;
				case "limited":
					bracket = "month";
					break;
				case "unlimited":
					bracket = "month";
					break;
				default:
					break;
			}
		} else {
			switch (kyc_status) {
				case "lite":
					bracket = "transaction";
					break;
				case "limited":
					bracket = "day";
					break;
				default:
					break;
			}
		}
		const amtn_sent = limits ? limits.amountsSent[bracket] : 0;
		const transaction_limit = ui ? ui.result.limits['uk'][kyc_status][bracket] : 0;

		return ` ${user.country === "UK" ? "£" : "R"}${Number(transaction_limit - amtn_sent).toFixed(
			2
		)} for ${
			bracket === "day"
				? " today"
				: bracket === "month"
				? " this month"
				: bracket === "transaction"
				? "this transaction"
				: ""
		}`;
	};

	let content =
		nb_loading ||
		confirm_transaction_loading ||
		new_transaction_loading ||
		(user_loading && !user.onboarded) ||
		remit_one_loading ||
		initiate_payment_loading ? (
			<PageLoader message="Processing transaction..." />
		) : limits_loading || profile_loading || (user_loading && user.onboarded) ? (
			<PageLoader />
		) : (
			<div className="vh-100">
				<SessionTimeout />
				<div style={{ minHeight: "15%" }}>
					{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
				</div>

				<div className="py-0 py-sm-3" style={{ minHeight: "75%" }}>
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 d-none d-md-block">
								<div
									className="card mb-4 border-0 textBlue bgBlue"
									style={{ backgroundColor: "#ccc9d7" }}
								>
								{user.country != "UK" && 				
								 (ENVIRONMENT !== "local" || ENVIRONMENT !="test")
								 &&
								(
									<div className="card-body">
										<h6 className="fw-bold">
										PACKAGE: <span className="text-default">{user.kyc_status.toUpperCase()}</span>
										</h6>
										<h6>Your Transaction limit remaining is {getUserTransactionlimit()}</h6>
										{user.onboarded &&
										((user.country === "UK" && user.kyc_status !== "unlimited") ||
											(user.country === "SA" && user.kyc_status !== "limited")) && (
											<Link to="/upgrade-account" className="text-decoration-none text-primary">
											Upgrade sending limit <FiExternalLink />
											</Link>
										)}
									</div>
									)}
								</div> 
								<div className="bgYellow rounded ps-5 pe-1 mb-4">
									<Step first="true" />

									<Step
										number={1}
										label="Amount to Send"
										active={amountStepIsOpen}
										passed={amountPassed}
									>
										{transaction_charges_data && amntToSend.trans_type && (
											<>
												<span>
													Sending:{" "}
													{amntToSend.source_currency +
														" " +
														Number(amntToSend.amount_to_send)
															.toFixed(2)
															.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
												</span>
												<br />
												<span>
													Receiving:{" "}
													{amntToSend.destination_currency +
														" " +
														Number(amntToSend.amount_to_receive)
															.toFixed(2)
															.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
												</span>
												<br />
												<span>Delivery Method: {amntToSend.trans_type}</span>
												<br />
												<span>Purpose: {amntToSend.purpose}</span>
												<br />
												<span>
													Rate:{" "}
													{user.country === "UK"
														? "£"
														: amntToSend.destination_currency === "ZAR"
														? "R"
														: "$"}
													1:{determineRate()}
													{/* {Number(transaction_charges_data.result.rate).toFixed(4)} */}
												</span>

												<br />
												<span>Charges: {calculateTotalCharges()}</span>

												<br />
												<span>
													Total to Pay:{" "}
													{transaction_charges_data.result.source_currency}{" "}
													{(
														Number(transaction_charges_data.result.source_amount) +
														Number(transaction_charges_data.result.total_charges) +
														Number(transaction_charges_data.result.tax)
													)
														.toFixed(2)
														.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
												</span>
											</>
										)}
									</Step>

									{!user.onboarded && (
										<Step
											number={2}
											label="Sender Details"
											active={senderStepIsOpen}
											passed={senderPassed}
										>
											{myProfile.first_name && (
												<>
													<span>First Name: {myProfile.first_name}</span>
													<br />
													{myProfile.middle_name && (
														<>
															<span>Middle Name(s): {myProfile.middle_name}</span>
															<br />
														</>
													)}
													<span>Last Name: {myProfile.last_name}</span>
													<br />
													<span>
														Mobile Number:{" "}
														{myProfile.mobile.startsWith("+")
															? myProfile.mobile
															: "+" + myProfile.mobile}
													</span>
													<br />
													<span>Date of Birth: {myProfile.dob}</span>
													<br />
													<span>Gender: {myProfile.gender}</span>
													<br />
													{user && user.country === "SA" && (
														<>
															<span>ID Number: {myProfile.id1_details ?? "--"}</span>
															<br />
															<span>
																ID Type: {myProfile.id1_type_label ?? "--"}
															</span>
															<br />
															<span>
																ID Issue Place: {myProfile.id1_issue_place ?? "--"}
															</span>
															<br />
															<span>
																ID Issue Country:{" "}
																{myProfile.id1_issue_country ?? "--"}
															</span>
															<br />
															<span>
																ID Expiry Date: {myProfile.id1_expiry ?? "--"}
															</span>
															<br />
															<span>
																Default purpose:{" "}
																{myProfile.default_transfer_purpose ?? "--"}
															</span>
															<br />
														</>
													)}
												</>
											)}
											{myProfile.postal_code && (
												<>
													<span>Postal Code: {myProfile.postal_code}</span>
													<br />
													<span>
														Address line 1: {myProfile.address_line_1 ?? "--"}
													</span>
													<br />
													<span>
														Address line 2: {myProfile.address_line_2 ?? "--"}
													</span>
													<br />
													<span>City: {myProfile.city ?? "--"}</span>
													<br />
													<span>
														State/Province: {myProfile.state_province ?? "--"}
													</span>
													<br />
												</>
											)}
										</Step>
									)}

									<Step
										number={!user.onboarded ? 3 : 2}
										label="Beneficiary Details"
										active={beneficiaryStepIsOpen}
										passed={beneficiaryPassed}
									>
										<span>First Name: {myBeneficiary.first_name}</span>
										<br />
										{myBeneficiary.middle_name && (
											<>
												<span>Middle Name(s): {myBeneficiary.middle_name}</span>
												<br />
											</>
										)}
										<span>Last Name: {myBeneficiary.last_name}</span>
										<br />
										<span>
											Mobile Number:{" "}
											{myBeneficiary.mobile.startsWith("+")
												? myBeneficiary.mobile
												: "+" + myBeneficiary.mobile}
										</span>
									</Step>

									<Step
										number={!user.onboarded ? 4 : 3}
										label="Confirmation"
										active={confirmStepIsOpen}
										passed={confirmPassed}
									/>

									<Step
										number={!user.onboarded ? 5 : 4}
										label="Transaction Status"
										active={transactionStepIsOpen}
									/>

									<Step last="true" />
								</div>

								<ContactDetails />
							</div>

							<div className="col-12 offset-xl-1 col-xl-6 offset-lg-1 col-lg-7 col-md-7 ">
								{ui_loading ? (
									<div className="">
										<PageLoader />
									</div>
								) : (
									<>
										{limits_error && (
											<div
												className="alert alert-danger alert-dismissible fade show"
												role="alert"
											>
												{limits_error}
												<button
													type="button"
													onClick={() => clearGetLimitsError()}
													className="btn-close"
													data-bs-dismiss="alert"
													aria-label="Close"
												></button>
											</div>
										)}
										<div
											className="card mb-2 border-0 d-block d-md-none textBlue bgBlue"
											// style={{ backgroundColor: "#A5A8AC" }}
											style={{ backgroundColor: "#ccc9d7" }}
										>
											<div className="card-body">
												<h6 className="fw-bold">
													PACKAGE:{" "}
													<span className="text-default">
														{user.kyc_status.toUpperCase()}
													</span>
												</h6>
												<h6>
													Your Transaction limit remaining is{" "}
													{getUserTransactionlimit()}
												</h6>
												{user.onboarded &&
													((user.country === "UK" &&
														user.kyc_status !== "unlimited") ||
														(user.country === "SA" &&
															user.kyc_status !== "limited")) && (
														<Link
															to="/upgrade-account"
															className="text-decoration-none text-primary"
														>
															Upgrade sending limit <FiExternalLink />
														</Link>
													)}
											</div>
										</div>

										{amountStepIsOpen && (
											<>
												{user && user.country === "SA" && (
													<div
														className="alert alert-danger text-white bg-danger"
														role="alert"
													>
														Please note you’re not allowed to send money to yourself
														according to the South African Reserve Bank
													</div>
												)}

												<div className="alert alert-warning" role="alert">
													Fill in all mandatory (*) fields and ensure all your details
													are correct.
												</div>
												<AmountAndDelivery
													transaction_charges_error={transaction_charges_error}
													amntToSend={amntToSend}
													amountsError={amountsError}
													setAmountandDeliveryHandleChange={
														setAmountandDeliveryHandleChange
													}
													calculateTotalCharges={calculateTotalCharges}
													amountsState={amountsState}
													clearOnboardRemitter={clearOnboardRemitter}
													clearSearchRemitter={clearSearchRemitter}
													setAmountsState={setAmountsState}
													calculated={calculated}
													minAmntError={minAmntError}
													determineRate={determineRate}
													transaction_charges_data={transaction_charges_data}
													handleStep={handleStep}
													limitError={limitError}
													minimumRecievingAmount={minimumRecievingAmount}
													setMinAmountError={setMinAmountError}
													fetchMinimumAmount={fetchMinimumAmount}
													setCalculated={setCalculated}
													setLimitError={setLimitError}
													setAmntToSend={setAmntToSend}
													setAmountsError={setAmountsError}
													checkTransactionLimit={checkTransactionLimit}
													transaction_charges_loading={transaction_charges_loading}
													handleAmntAndDeliverySubmit={handleAmntAndDeliverySubmit}
												/>
											</>
										)}

										{beneficiaryStepIsOpen && (
											<>
												<div className="alert alert-warning" role="alert">
													Fill in all mandatory (*) fields and ensure all your details
													are correct.
												</div>
												<BeneficiaryDetails
													handleBeneficiarySubmit={handleBeneficiarySubmit}
													handleStep={handleStep}
													myProfile={myProfile}
													clearSearchRemitter={clearSearchRemitter}
													clearOnboardRemitter={clearOnboardRemitter}
													myBeneficiary={myBeneficiary}
													setMyBeneficiary={setMyBeneficiary}
													setCustomError={setCustomError}
													disableFields={disableFields}
													setDisableFields={setDisableFields}
													selectedBeneficiary={selectedBeneficiary}
													setSelectedBeneficiary={setSelectedBeneficiary}
												/>
											</>
										)}

										{!user.onboarded && senderStepIsOpen && (
											<>
												<div className="alert alert-warning" role="alert">
													Fill in all mandatory (*) fields and ensure all your details
													are correct.
												</div>
												<CombinedKYC
													handleStep={handleStep}
													setMyProfile={setMyProfile}
													myProfile={myProfile}
													customError={customError}
													setCustomError={setCustomError}
													amntToSend={amntToSend}
													searchRemitter={searchRemitter}
													setModalVisibleMigration={setModalVisibleMigration}
													search_remitter_loading={search_remitter_loading}
													search_remitter_error={search_remitter_error}
													search_remitter_data={search_remitter_data}
													clearSearchRemitter={clearSearchRemitter}
													modalVisibleOtp={modalVisibleOtp}
													clearSearchRemitterError={clearSearchRemitterError}
													user={user}
													ui={ui}
													handleChange={handleChange}
												/>
											</>
										)}

										<Modal
											modalVisible={modalVisibleMigration}
											setModalVisible={setModalVisibleMigration}
											header="Account Migration"
										>
											<p className="p-4 rounded bgFaintYellow">
												Hie, we noticed that you already have an account registered with
												us. We would like to migrate your profile to this system for you
												to be able to send money on this platform. If you proceed, we
												will send you a one-time-password (OTP) using the phone number
												below to verify that this is your account. If you changed phone
												number, contact us on the contact details at the bottom
											</p>

											{send_otp_error &&
												send_otp_error.map((err) => (
													<Error key={err.error} error={err.error} />
												))}

											<p className="mb-0">
												Phone number to receive OTP with.
												<br />
												<small>
																If you changed phone number, contact us on <a href="mailto:support@accessforex.com">support@accessforex.com</a>
												</small>
											</p>
											{search_remitter_data && search_remitter_data.result.remitter && (
												<p className="fw-bold text-success">
													{search_remitter_data.result.remitter.mobile.substring(
														0,
														search_remitter_data.result.remitter.mobile.length - 9
													) +
														"*****" +
														search_remitter_data.result.remitter.mobile.substring(
															search_remitter_data.result.remitter.mobile.length - 4
														)}
												</p>
											)}

											<p className="fw-bold">Do you want to proceed?</p>
											<button
												className="btn bgYellow me-3"
												onClick={logout}
												type="button"
											>
												No, log me out
											</button>
											{send_otp_loading ? (
												<ButtonLoader />
											) : (
												<button
													onClick={handleRequestOTP}
													className="btn bgBlue"
													type="button"
												>
													Yes
												</button>
											)}
										</Modal>

										<Modal
											modalVisible={modalVisibleOtp}
											setModalVisible={setModalVisibleOtp}
											header="Account Migration"
										>
											<input
												type="text"
												className="form-control mb-1"
												name="otp"
												placeholder="Enter 6-digit OTP"
												onChange={(e) => setOtpValue(e.target.value)}
											/>
											{otpCustomError && (
												<small style={{ color: "#DC2626" }}>Enter valid OTP</small>
											)}
											{verify_otp_error &&
												verify_otp_error.map((err) => (
													<Error key={err.error} error={err.error} />
												))}
											{onboard_remitter_error &&
												onboard_remitter_error.map((err) => (
													<Error key={err.error} error={err.error} />
												))}
											<p className="mb-0">
												Dind't receive OTP?
												<button
													onClick={handleRequestOTP}
													type="button"
													className="text-primary rounded p-2 bg-white"
												>
													<strong>Re-send</strong>
												</button>
											</p>
											{verify_otp_loading || onboard_remitter_loading ? (
												<ButtonLoader />
											) : (
												<button
													onClick={handleVerifyOTP}
													className="btn bgBlue"
													type="button"
												>
													Confirm
												</button>
											)}
										</Modal>

										{confirmStepIsOpen && (
											<>
												<Confirmation
													amntToSend={amntToSend}
													myBeneficiary={myBeneficiary}
													myProfile={myProfile}
													user={user}
													user_error={user_error}
													ui={ui}
													calculateTotalCharges={calculateTotalCharges}
													nb_error={nb_error}
													setChecked={setChecked}
													determineRate={determineRate}
													declarationError={declarationError}
													setDeclarationError={setDeclarationError}
													new_transaction_error={new_transaction_error}
													initiate_payment_error={initiate_payment_error}
													confirm_transaction_error={confirm_transaction_error}
													transaction_charges_data={transaction_charges_data}
													clearTransactionPayloadError={clearTransactionPayloadError}
													clearNewBeneficiaryError={clearNewBeneficiaryError}
													clearInitiatePaymentError={clearInitiatePaymentError}
													clearAuthorizationError={clearAuthorizationError}
													handleStep={handleStep}
													performBasicRegistration={performBasicRegistration}
													clearConfirmTransactionError={clearConfirmTransactionError}
													clearRemitOneError={clearRemitOneError}
													remit_one_error={remit_one_error}
												/>
											</>
										)}

										{transactionStepIsOpen && (
											<>
												<TransactionStatus error={processingError} />
											</>
										)}
									</>
								)}
							</div>
							<div className="col-12 d-block d-md-none mt-2 mt-md-0">
								<ContactDetails />
							</div>
						</div>
					</div>
				</div>

				<div className="pt-2 pt-sm-0" style={{ minHeight: "10%" }}>
					<Footer user={user} uioptions={uioptions} />
				</div>
			</div>
		);

	return <>{content}</>;
};

SendMoney.propTypes = {
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	authorization: PropTypes.object.isRequired,
	myProfile: PropTypes.object.isRequired,
	getTransactionCharges: PropTypes.func.isRequired,
	storeOnboardingPayload: PropTypes.func.isRequired,
	fullRegistration: PropTypes.func.isRequired,
	clearAuthorizationError: PropTypes.func.isRequired,
	remitOne: PropTypes.func.isRequired,
	newBeneficiary: PropTypes.func.isRequired,
	clearRemitOneError: PropTypes.func.isRequired,
	confirmTransaction: PropTypes.func.isRequired,
	newTransaction: PropTypes.func.isRequired,
	initiatePayment: PropTypes.func.isRequired,
	clearNewBeneficiary: PropTypes.func.isRequired,
	clearInitiatePayment: PropTypes.func.isRequired,
	clearOnboardingPayload: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	clearConfirmTransaction: PropTypes.func.isRequired,
	clearGetLimits: PropTypes.func.isRequired,
	clearSendOTPerror: PropTypes.func.isRequired,
	clearGetLimitsError: PropTypes.func.isRequired,
	clearNewBeneficiaryError: PropTypes.func.isRequired,
	clearTransactionPayloadError: PropTypes.func.isRequired,
	clearConfirmTransactionError: PropTypes.func.isRequired,
	getLimits: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	clearInitiatePaymentError: PropTypes.func.isRequired,
	searchRemitter: PropTypes.func.isRequired,
	clearRemitOne: PropTypes.func.isRequired,
	onboardRemitterReducer: PropTypes.func.isRequired,
	onboardRemitter: PropTypes.func.isRequired,
	clearSearchRemitter: PropTypes.func.isRequired,
	clearNewTransaction: PropTypes.func.isRequired,
	clearTransactionCharges: PropTypes.func.isRequired,
	sendOTP: PropTypes.func.isRequired,
	clearSearchRemitterError: PropTypes.func.isRequired,
	clearVerifyOTPerror: PropTypes.func.isRequired,
	verifyOTP: PropTypes.func.isRequired,
	clearOnboardRemitter: PropTypes.func.isRequired,
	clearOnboardRemitterError: PropTypes.func.isRequired,
	transactionCharges: PropTypes.object.isRequired,
	initPymnt: PropTypes.object.isRequired,
	newBeneficiaryReducer: PropTypes.object.isRequired,
	onboardingPayload: PropTypes.object.isRequired,
	remitone: PropTypes.object.isRequired,
	newTransactionReducer: PropTypes.object.isRequired,
	clearTransactionReducer: PropTypes.object.isRequired,
	confirmTransactionReducer: PropTypes.object.isRequired,
	getLimitsReducer: PropTypes.object.isRequired,
	searchRemitterReducer: PropTypes.object.isRequired,
	verifyOTPReducer: PropTypes.object.isRequired,
	sendOTPReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
	remitone: state.remitone,
	initPymnt: state.initPymnt,
	myProfile: state.myProfile,
	onboardingPayload: state.onboardingPayload,
	newBeneficiaryReducer: state.newBeneficiaryReducer,
	transactionCharges: state.transactionCharges,
	newTransactionReducer: state.newTransactionReducer,
	getLimitsReducer: state.getLimitsReducer,
	clearTransactionReducer: state.clearTransactionReducer,
	confirmTransactionReducer: state.confirmTransactionReducer,
	searchRemitterReducer: state.searchRemitterReducer,
	verifyOTPReducer: state.verifyOTPReducer,
	onboardRemitterReducer: state.onboardRemitterReducer,
	sendOTPReducer: state.sendOTPReducer,
});

export default connect(mapStateToProps, actions)(SendMoney);
