import React, { useState, useEffect, Fragment } from "react";
import {
	ContactDetails,
	SignedHeader,
	TextField,
	RegularButton,
	RadioButton,
	File,
	Error,
	PageLoader,
	Select,
	MobileNumber,
	Modal,
} from "components";
import { useHistory } from "react-router-dom";
import Footer from "components/Footer/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Postcoder from "react-address-lookup";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import SessionTimeout from "utils/SessionTimeout";

import "react-phone-input-2/lib/style.css";
import "components/Inputs/PhoneInput.css";

const UpdateProfile = (props) => {
	const history = useHistory();
	const [updateProfileState, setUpdateProfileState] = useState({});
	const { updateProfile, uioptions, getProfile, clearUpdateProfile, clearUpdateProfileError } =
		props;
	const { user } = props.authorization;

	const { ui } = props.uioptions;
	const { profile, profile_loading, profile_error } = props.myProfile;
	const { update_profile_data, update_profile_loading, update_profile_error } =
		props.updateMyProfile;

	const [display, setDisplay] = useState({
		// proof_of_address: false,
		proof_of_address: (profile?.remitone?.id1_type === "Passport"|| profile?.remitone?.id1_type === "Other" || profile?.remitone?.id1_type === "Residence Permit") ? true : false,
		id1_scan2_required: false,
	});

	const [displaySa, setDisplaySa] = useState({
		foreign_id_number_required: false,
		permit_number_required: false,
	});

	const [country, setCountry] = useState("");
	const [modalVisible, setModalVisible] = useState(false);

	const newCountry = [];

	useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Update Profile";
		return () => {
			subscribe = false;
		};
	}, []);

	useEffect(() => {
		if (ui) {
			ui.result.nationalities.forEach((element) => {
				newCountry.push({ name: element.name, id: element.value });
			});
			setCountry(newCountry);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getProfile("profile");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formatDate = (date) => {
		var d = new Date(date),
			month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};

	const initialValues = {
		first_name: profile && profile.remitone.firstname ? profile.remitone.firstname : "",
		last_name: profile && profile.remitone.lastname ? profile.remitone.lastname : "",
		middle_name: profile && profile.remitone.middlename ? profile.remitone.middlename : "",
		mobile: profile && profile.remitone.mobile ? profile.remitone.mobile : "",
		phone: profile && profile.remitone.mobile ? profile.remitone.mobile.slice(-9) : "",
		nationality: profile && profile.remitone.nationality ? profile.remitone.nationality : "",
		email: profile && profile.remitone.email ? profile.remitone.email : "",
		dob: profile && profile.remitone.dob ? formatDate(profile.remitone.dob) : "",
		gender: profile && profile.middleware.gender !== null ? profile.middleware.gender : "",
		post_code: profile && profile.remitone.postcode ? profile.remitone.postcode : "",
		// country: profile && profile.remitone.country.length ? profile.remitone.country : "",
		address_line_1: profile && profile.remitone.address1 ? profile.remitone.address1 : "",
		address_line_2: profile && profile.remitone.address2 ? profile.remitone.address2 : "",
		city: profile && profile.remitone.city ? profile.remitone.city : "",
		state_province: profile && profile.remitone.state ? profile.remitone.state : "",
		id1_type: profile && profile.remitone.id1_type ? profile.remitone.id1_type : "",
		id1: "",
		id1_details: profile && profile.remitone.id1_details ? profile.remitone.id1_details : "",
		id1_issue_place:
			profile && profile.remitone.id1_issue_place ? profile.remitone.id1_issue_place : "",
		id1_issue_country:
			profile.remitone.id1_issue_country && profile.remitone.id1_issue_country !== null
				? profile.remitone.id1_issue_country
				: "",
		id1_expiry:
			profile && profile.remitone.id1_expiry !== null
				? formatDate(profile.remitone.id1_expiry)
				: "",
		id3: "",
		id3_type: profile && profile.remitone.id3_type ? profile.remitone.id3_type : "",
		id1_scan2: profile && profile.remitone.id_scan_2 !== null ? profile.remitone.id_scan_2 : "",
		id2: "",
		id2_type: profile && profile.remitone.id2_type ? profile.remitone.id2_type : "",
		id4: "",
		id4_type: profile && profile.remitone.id4_type ? profile.remitone.id4_type : "",
		id1_details_label: "",
	};

	const conditionalValidation = () => {
		let kyc_status = profile ? profile.middleware.kyc_status : user ? user.kyc_status : null;
		if (user.country === "UK") {
			switch (kyc_status) {
				case "lite":
					return {
						first_name: Yup.string()
							.required("First name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						nationality: Yup.string().required("Nationality is required"),
						last_name: Yup.string()
							.required("Last name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),

						dob: Yup.date()
							.required()
							.test("age", "You must be 18 or older", function (dob) {
								const cutoff = new Date();
								cutoff.setFullYear(cutoff.getFullYear() - 18);
								return dob <= cutoff;
							}),
						phone: Yup.string()
							.required("Mobile is required")
							.matches(
								/^[1-9]{1}[0-9]{1,}$/,
								"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
							)
							.min(9, "Invalid mobile number")
							.max(10, "Invalid mobile number"),
					};

				case "limited":
					return {
						first_name: Yup.string()
							.required("First name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						nationality: Yup.string().required("Nationality is required"),
						last_name: Yup.string()
							.required("Last name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						// email: Yup.string()
						// 	.email("Invalid email address")
						// 	.required("Email address is required"),
						dob: Yup.date()
							.required()
							.test("age", "You must be 18 or older", function (dob) {
								const cutoff = new Date();
								cutoff.setFullYear(cutoff.getFullYear() - 18);
								return dob <= cutoff;
							}),
						phone: Yup.string()
							.required("Mobile is required")
							.matches(
								/^[1-9]{1}[0-9]{1,}$/,
								"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
							)
							.min(9, "Invalid mobile number")
							.max(11, "Invalid mobile number"),
						id1_type: Yup.mixed().required("ID type is required"),
						id1_details: Yup.mixed().when("id1_type", {
							is: (val) => val !== "" && val !== null && val !== undefined,
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
						id1_issue_place: Yup.mixed().when("id1_type", {
							is: (val) => val !== "" && val !== null && val !== undefined,
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
						id1_expiry: Yup.date().when("id1_type", {
							is: (val) => val !== "" && val !== null && val !== undefined,
							then: Yup.date()
								.notRequired()
								.test(
									"expiry",
									"Expiry date should be greater than today",
									function (id1_expiry) {
										const today = new Date();
										return id1_expiry > today;
									}
								),
							otherwise: Yup.date().notRequired(),
						}),

						address_line_1: Yup.mixed().required().label("Address Line 1 "),
						city: Yup.string().required().label("City "),
						state_province: Yup.string().required().label("Province/state "),
						id1: Yup.mixed().when("id1_type", {
							is: (val) => val !== "" && val !== null && val !== undefined,
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
						id2: Yup.mixed().when("id1_type", {
							is: (val) =>
								val !== "" &&
								val !== null &&
								val !== undefined &&
								(val === "Passport" || val === "Other" || val === "Residence Permit"),
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
						id1_scan2: Yup.mixed().when("id1_type", {
							is: (val) =>
								val !== "" &&
								val !== null &&
								val !== undefined &&
								val === "Residence Permit",
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
					};

				case "unlimited":
					return {
						first_name: Yup.string()
							.required("First name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						nationality: Yup.string().required("Nationality is required"),
						last_name: Yup.string()
							.required("Last name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						dob: Yup.date()
							.required()
							.test("age", "You must be 18 or older", function (dob) {
								const cutoff = new Date();
								cutoff.setFullYear(cutoff.getFullYear() - 18);
								return dob <= cutoff;
							}),
						phone: Yup.string()
							.required("Mobile is required")
							.matches(
								/^[1-9]{1}[0-9]{1,}$/,
								"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
							)
							.min(9, "Invalid mobile number")
							.max(11, "Invalid mobile number"),
						id1_type: Yup.mixed().notRequired(),
						id1_details: Yup.mixed().required("ID details required"),
						id1_issue_place: Yup.mixed().required("ID issue place required"),
						id1_issue_country: Yup.mixed().required("ID issue country required"),
						id1_expiry: Yup.date()
							.required("ID expiry date required")
							.test(
								"expiry",
								"Expiry date should be greater than today",
								function (id1_expiry) {
									const today = new Date();
									return id1_expiry > today;
								}
							),
						address_line_1: Yup.mixed().required().label("Address Line 1 "),
						city: Yup.string().required().label("City "),
						state_province: Yup.string().required().label("Province/state "),
						id1: Yup.mixed().when("id1_type", {
							is: (val) => val !== "" && val !== null && val !== undefined,
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
						id3: Yup.mixed().notRequired(),
						id2: Yup.mixed().when("id1_type", {
							is: (val) =>
								val !== "" &&
								val !== null &&
								val !== undefined &&
								(val === "Passport" || val === "Other" || val === "Residence Permit"),
							then: Yup.mixed().required("Can not proceed without uploading this file"),
							otherwise: Yup.mixed().notRequired(),
						}),

						id1_scan2: Yup.mixed().when("id1_type", {
							is: (val) =>
								val !== "" &&
								val !== null &&
								val !== undefined &&
								val === "Residence Permit",
							then: Yup.mixed().notRequired(),
							otherwise: Yup.mixed().notRequired(),
						}),
					};
				default:
					return {};
			}
		} else {
			switch (kyc_status) {
				case "lite":
					return {
						first_name: Yup.string()
							.required("First name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						nationality: Yup.string().required("Nationality is required"),
						last_name: Yup.string()
							.required("Last name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						dob: Yup.date()
							.required()
							.test("age", "You must be 18 or older", function (dob) {
								const cutoff = new Date();
								cutoff.setFullYear(cutoff.getFullYear() - 18);
								return dob <= cutoff;
							}),
						phone: Yup.string()
							.required("Mobile is required")
							.matches(
								/^[1-9]{1}[0-9]{1,}$/,
								"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
							)
							.min(9, "Invalid mobile number")
							.max(11, "Invalid mobile number"),
					};

				case "limited":
					return {
						first_name: Yup.string()
							.required("First name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						nationality: Yup.string().required("Nationality is required"),
						last_name: Yup.string()
							.required("Last name is required")
							.matches(
								/^[A-Za-z\s]{1,}$/,
								"First name does not allow numeric values or special characters"
							),
						dob: Yup.date()
							.required("Date of birth is required")
							.test("age", "You must be 18 or older", function (dob) {
								const cutoff = new Date();
								cutoff.setFullYear(cutoff.getFullYear() - 18);
								return dob <= cutoff;
							}),
						phone: Yup.string()
							.required("Mobile is required")
							.matches(
								/^[1-9]{1}[0-9]{1,}$/,
								"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
							)
							.min(9, "Invalid mobile number")
							.max(11, "Invalid mobile number"),
						id1_type: Yup.string().required("ID type is required"),
						id1_details: Yup.mixed().required("ID details required"),
						id1_issue_place: Yup.mixed().required("ID issue place required"),
						id1_issue_country: Yup.mixed().required("ID issue country required"),
						id1_expiry: Yup.mixed().when("id1_type", {
							is: (val) => !["SA National ID", "Foreign National ID"].includes(val),
							then: Yup.mixed().required("ID expiry date required"),
							otherwise: Yup.mixed().notRequired(),
						}),

						address_line_1: Yup.mixed().required().label("Address Line 1 "),
						city: Yup.string().required().label("City "),
						state_province: Yup.string().required().label("Province/state "),
						id1: Yup.mixed().notRequired(),

						id3: Yup.string().when("id1_type", {
							is: (val) => val === "Other",
							then: Yup.string().notRequired(),
							otherwise: Yup.string().notRequired(),
						}),

						id2: Yup.mixed().notRequired(),

						id4: Yup.string().when("id1_type", {
							is: (val) => val === "Other",
							then: Yup.string().notRequired(),
							otherwise: Yup.string().notRequired(),
						}),
					};
				default:
					return {};
			}
		}
	};

	const schema = Yup.object().shape(conditionalValidation());

	const handleDocumentsUK = (type) => {
		 
																				
		const obj = ui.result.id_types.uk.id1.find((el) => el.code === type);

		if (obj.proof_of_address && obj.id1_scan2_required) {
			setDisplay({
				...display,
				proof_of_address: true,
				id1_scan2_required: true,
			});
		} else if (obj.proof_of_address) {
			setDisplay({
				...display,
				proof_of_address: true,
				id1_scan2_required: false,
			});
		} else if (obj.id1_scan2_required) {
			setDisplay({
				...display,
				proof_of_address: false,
				id1_scan2_required: true,
			});
		} else {
			setDisplay({
				...display,
				proof_of_address: false,
				id1_scan2_required: false,
			});
		}
	};

	const handleUpgradeSubmit = (data) => {
		// return console.log("DATA:", data);
		data.mobile = data.mobile.startsWith("+") ? data.mobile : "+" + data.mobile;
		setUpdateProfileState(data);
		setModalVisible(true);
		// updateProfile({ ...data, mobile: phoneNumber });
	};

	const handleFinalProfileSubmit = () => {
		setModalVisible(false);
		// console.log(updateProfileState);
		updateProfile(updateProfileState);
	};

	const handleDocumentsSA = (type) => {
		const obj = ui.result.id_types.sa.id1.find((el) => el.code === type);
		if (obj.foreign_id_number_required && obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: true,
			});
		} else if (obj.foreign_id_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: false,
			});
		} else if (obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: true,
			});
		} else {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: false,
			});
		}
	};

	React.useEffect(() => {
		setInterval(() => {
			update_profile_error && clearUpdateProfileError();
		}, 15000);
		return () => {
			clearUpdateProfileError();
		};
	}, [clearUpdateProfileError, update_profile_error]);

	React.useEffect(() => {
		if (update_profile_data) {
			history.push({
				pathname: "/profile"
			});
		}
	}, [history, update_profile_data]);

	let content =
		profile_loading || update_profile_loading ? (
			<PageLoader />
		) : (
			<div className="vh-100">
				<SessionTimeout />

				<div style={{ minHeight: "16%" }}>
					<SignedHeader />
				</div>

				<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-5 col-sm-5 order-last order-sm-first mt-2 mt-sm-0">
								<div className="bgFaintYellow rounded px-4 py-3 mb-4 d-none d-sm-block">
									<p>
										Process time varies based on the clarity of the documents submitted.
										The times vary between instant to 24 hours.
									</p>
								</div>
								<ContactDetails />
							</div>

							<div className="offset-xl-1 col-xl-6 offset-lg-1 col-lg-7 col-md-7 col-sm-7">
								<div className="bgFaintYellow rounded px-4 py-1 mb-2 d-block d-sm-none">
									Process time varies based on the clarity of the documents submitted. The
									times vary between instant to 24 hours.
								</div>
								<div className="alert alert-warning" role="alert">
									Fill in all mandatory (*) fields as they appear on your government issued
									photo ID. Any changes to your profile will require your account to be
									re-verified by our compliance team.
								</div>
								<h5 className="fw-bold col-12">Update Profile</h5>

								{profile_error &&
									profile_error.map((err) => <Error key={err.error} error={err.error} />)}

								{update_profile_error &&
									update_profile_error.map((err) => (
										<Error key={err.error} error={err.error} />
									))}

								<Formik
									initialValues={initialValues}
									validationSchema={schema}
									onSubmit={handleUpgradeSubmit}
									enableReinitialize={true}
								>
									{({ setFieldValue, ...rest }) => {
										const logAddress = (addr) => {
											setFieldValue("address_line_1", addr.addressline1);
											setFieldValue("address_line_2", addr.addressline2);
											setFieldValue("city", addr.posttown);
											setFieldValue("state_province", addr.county);
											setFieldValue("post_code", addr.postcode);
										};
										return (
											<Form>
												<TextField label="First Name*" name="first_name" />
												<TextField label="Last Name*" name="last_name" />
												<TextField label="Middle Name(s)" name="middle_name" />
												<TextField type="date" label="Date of Birth*" name="dob" />
												<MobileNumber
													label="Mobile Number*"
													value={rest.values.mobile}
													inputProps={{ name: "mobile" }}
													country={user && user.country === "UK" ? "gb" : "za"}
													onlyCountries={
														user && user.country === "UK" ? ["gb"] : ["za"]
													}
													onBlur={rest.handleBlur}
													onChange={(mobile, country, e) => {
														rest.handleChange(e);
														setFieldValue("mobile", mobile);
														setFieldValue(
															"phone",
															mobile.slice(country.dialCode.length)
														);
													}}
												/>
												{rest.errors && rest.errors.phone && (
													<span className="form-text small text-danger">
														{rest.errors.phone}
													</span>
												)}
												{ui && (
													<Select
														name="nationality"
														label="Nationality*"
														defaultChecked
														optionlabel="Choose Nationality"
														options={country}
													/>
												)}
												{profile &&
													(profile.middleware.kyc_status === "limited" ||
														profile.middleware.kyc_status === "unlimited") && (
														<>
															{user.country === "UK" && (
																<>
																	<Postcoder
																		label="Address*"
																		apiKey="PCW55-Y54T8-XN6GY-TQBRR"
																		name="post_code"
																		addressSelectedCallback={logAddress}
																		placeholder="Postal Code"
																	/>
																	<TextField
																		label="Post Code*"
																		placeholder="Enter postal code"
																		name="post_code"
																	/>
																</>
															)}

															<TextField
																label="Enter Address 1*"
																name="address_line_1"
															/>

															<TextField
																label="Enter Address 2"
																name="address_line_2"
															/>

															<TextField label="City" name="city" />

															<TextField
																label="State/Province*"
																name="state_province"
															/>

															<div className="alert alert-warning mt-2" role="alert">
																Select ID type below that you want it's associated
																documents uploaded. Make sure to upload the
																appropriate documents in the format: jpg, png, jpeg,
																or pdf
															</div>

															<p className="mt-3 mb-0 py-0 fw-bold">
																Select ID Type
															</p>

															{user && user.country === "UK" && (
																<>
																	{ui &&
																		ui.result.id_types.uk.id1.map(
																			(value, index) => {
																				return (
																					<RadioButton
																						name="id1_type"
																						label={value.type}
																						value={value.code}
																						onChange={(e) => {
																							handleDocumentsUK(
																								e.target.value
																							);
																							setFieldValue(
																								"id1_type",
																								e.target.value
																							);
																							setFieldValue(
																								"id1_details_label",
																								value.type
																							);
																						}}
																						defaultChecked={
																							value.code ===
																							rest.values.id1_type
																						}
																						id={"id1_type" + index}
																						key={"id1_type" + index}
																					/>
																				);
																			}
																		)}

																	<div className="mt-3">
																		<p className="mb-0 fw-bold">
																			Upload{" "}
																			{rest.values && rest.values.id1_type !== ""
																				? rest.values.id1_details_label
																				: "document"}
																		</p>

																		<File
																			name="id1"
																			onChange={(e) => {
																				setFieldValue("id1", e.target.files[0]);
																			}}
																		/>

																		{display.id1_scan2_required && (
																			<div className={`mt-3 `}>
																				<p className="mb-0 fw-bold">
																					Upload Back of the
																					{rest.values &&
																					rest.values.id1_type !== ""
																						? rest.values.id1_details_label
																						: "document"}
																				</p>
																				<File
																					name="id1_scan2"
																					onChange={(e) => {
																						setFieldValue(
																							"id1_scan2",
																							e.target.files[0]
																						);
																					}}
																				/>
																			</div>
																		)}
																		<div
																			className={`mt-3 ${
																				(display.proof_of_address)
																					? "d- block"
																					: "d-none"
																			} fw-bold`}
																		>
																			<p className="mb-0 fw-bold">
																				Upload Proof of Address
																			</p>
																			<File
																				name="id2"
																				onChange={(e) => {
																					setFieldValue(
																						"id2",
																						e.target.files[0]
																					);
																					setFieldValue(
																						"id2_type",
																						ui.result.id_types.uk.id2[0].code
																					);
																				}}
																			/>
																			<input name="id2_type" type="hidden" />
																		</div>
																	</div>
																</>
															)}

															{user && user.country === "SA" && (
																<>
																	{ui &&
																		ui.result.id_types.sa.id1.map(
																			(value, index) => {
																				return (
																					<RadioButton
																						name="id1_type"
																						label={value.type}
																						value={value.code}
																						onChange={(e) => {
																							handleDocumentsSA(
																								e.target.value
																							);
																							setFieldValue(
																								"id1_type",
																								e.target.value
																							);
																							setFieldValue(
																								"id1_details_label",
																								value.type
																							);
																							rest.handleChange(e);
																						}}
																						id={"id1_type" + index}
																						key={"id1_type" + index}
																					/>
																				);
																			}
																		)}

																	<div className="mt-3">
																		<p className="mb-0 fw-bold">
																			Upload{" "}
																			{rest.values && rest.values.id1_type !== ""
																				? rest.values.id1_details_label
																				: "document"}
																			*
																		</p>

																		<File
																			name="id1"
																			onChange={(e) => {
																				setFieldValue("id1", e.target.files[0]);
																			}}
																		/>

																		{displaySa.permit_number_required && (
																			<TextField
																				label="Permit Number*"
																				name="id4"
																				onInput={(e) => {
																					setFieldValue(
																						"id4_type",
																						ui.result.id_types.sa.id4[0].code
																					);
																				}}
																			/>
																		)}

																		{displaySa.foreign_id_number_required && (
																			<TextField
																				label="Foreign ID Number*"
																				name="id3"
																				onInput={(e) => {
																					setFieldValue(
																						"id3_type",
																						ui.result.id_types.sa.id3[0].code
																					);
																				}}
																			/>
																		)}
																	</div>

																	<div className="mt-3">
																		<p className="mb-0 fw-bold">Upload Selfie*</p>
																		<File
																			name="id2"
																			onChange={(e) => {
																				setFieldValue("id2", e.target.files[0]);
																				setFieldValue(
																					"id2_type",
																					ui.result.id_types.sa.id2[0].type
																				);
																			}}
																		/>
																		<input name="id2_type" type="hidden" />
																	</div>
																</>
															)}

															<TextField
																label={
																	rest.values && rest.values.id1_type !== ""
																		? rest.values.id1_details_label + " Number*"
																		: " Details*"
																}
																name="id1_details"
																placeholder="Enter ID details"
															/>
															<TextField
																label="Issue Place*"
																name="id1_issue_place"
																placeholder="Enter issue place"
															/>
															{ui && (
																<Select
																	name="id1_issue_country"
																	label="Issue country*"
																	optionlabel="Choose issue country"
																	options={country}
																/>
															)}
															{!["SA National ID", "Foreign National ID"].includes(
																rest.values.id1_details_label
															) && (
																<TextField
																	type="date"
																	label="Expiry Date*"
																	name="id1_expiry"
																/>
															)}
														</>
													)}

												{profile && profile.middleware.kyc_status === "unlimited" && (
													<>
														<p className="mb-0 mt-2">Upload Source of Funds</p>
														<File
															name="id3"
															onChange={(e) => {
																setFieldValue("id3", e.target.files[0]);
																setFieldValue(
																	"id3_type",
																	ui.result.id_types.uk.id3[0].code
																);
															}}
														/>
													</>
												)}

												<div className="alert alert-warning mt-3" role="alert">
													Please make sure all the details of your profile are correct
													and all mandatory (*) fields are filled in before saving the
													changes, as any incorrect information will hinder the
													verification of your account.
												</div>
												<div className="mt-3">
													<RegularButton
														value="Update"
														type="submit"
														floatright={true}
													/>
													<Modal
														modalVisible={modalVisible}
														setModalVisible={setModalVisible}
													>
														<p className="p-4 rounded bgFaintYellow">
															Please make sure all the details of your profile are
															correct and all mandatory (*) fields are filled in
															before saving the changes, as any incorrect information
															will hinder the verification of your account
														</p>
														<p className="fw-bold">Are you sure you want proceed?</p>
														<RegularButton
															value="No"
															type="button"
															bg="bgYellow"
															onClick={() => setModalVisible(false)}
															floatright={false}
														/>
														&nbsp;
														<RegularButton
															value="Proceed"
															onClick={handleFinalProfileSubmit}
															type="button"
															floatright={false}
														/>
													</Modal>
												</div>
											</Form>
										);
									}}
								</Formik>
							</div>
						</div>
					</div>
				</div>
				<div style={{ minHeight: "10%" }}>
					<Footer user={user} uioptions={uioptions} />
				</div>
			</div>
		);

	return <Fragment>{content}</Fragment>;
};

UpdateProfile.propTypes = {
	myProfile: PropTypes.object.isRequired,
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
	updateMyProfile: PropTypes.object.isRequired,
	updateProfile: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	clearUpdateProfile: PropTypes.func.isRequired,
	clearUpdateProfileError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
	updateMyProfile: state.updateMyProfile,
	myProfile: state.myProfile,
});

export default connect(mapStateToProps, actions)(UpdateProfile);
