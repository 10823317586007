import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	TextField,
	SelectWithImages,
	Password,
	CheckBox,
	ButtonLoader,
	Error,
	Select,
	PageLoader,
	MobileNumber
} from "components";
import { AuthLayout } from "layouts";
import * as actions from "store/actions";

const Register = (props) => {
	const history = useHistory();
	const [countryName, setCountryName] = useState("");
	const [error, setError] = useState("");
	const [errorMobile, setErrorMobile] = useState("");
	const [regCountries, setRegCountries] = useState();
	const [country, setCountry] = useState(null);
	const [mobile, setMobile] = useState(null);
	const [, setProcessing] = useState(false);
	const { ui, ui_loading } = props.uioptions;
	const { user, user_loading, user_error } = props.authorization;

	useEffect(() => {
		setMobile(country?.code === "SA" ? "+27" : "+44" )
	},[country])


	const { getUIoptions, basicRegistration, clearBasicRegistrationError, clearUIoptionsError } =
		props;

	const initialValues = {
		country: "",
		email: "",
		password: "",
		subscribe: false,
	};

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Register";
		return () => {
			subscribe = false;
		};
	}, []);

	useEffect(() => {
		user && history.push("/send-money");
		
		return () => {
			clearBasicRegistrationError();
		};
	}, [clearBasicRegistrationError, user, history]);

	useEffect(() => {
		getUIoptions();
	}, [getUIoptions]);

	useEffect(() => {
		const time_id = setInterval(() => {
			user_error && clearBasicRegistrationError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [user_error, clearBasicRegistrationError]);

	useEffect(() => {
		let newCountriesArray = [];
		ui &&
			ui.result.countries.source.forEach((item) => {
				newCountriesArray.push({ id: item.code, name: item.name });
			});
		setRegCountries(newCountriesArray);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const registerSchema = Yup.object().shape({
		email: Yup.string().email("Invalid email address").required().label("Email address"),
		password: Yup.string()
			.matches(/([A-Z])/, "Invalid password, at least 1 capital letter expected")
			.min(8, "Invalid password. It should have more than 7 characters")
			.matches(/([0-9])/, "Invalid password, at least 1 numeric value expected")
			.required("Password is required"),
	});

	const proceedWithRegistration = () => {
			fDataStorage.country = country.code;
			setProcessing(true);
			basicRegistration(fDataStorage);
			clearBasicRegistrationError();
	}

	const [fDataStorage, setFDataStorage] = useState()

	const handleRegister = (formData) => {

		const validMobile = mobile.startsWith(country && country.code === "UK" ? "44" : "27");
		
		if (country && mobile && mobile.length > 10 && validMobile && country.code !== "") {
			
			setError("");
			setErrorMobile("")

			if(!otpCode) {
				sendOTP("+"+mobile);
				// sendOTP(user.mobile);
				setFDataStorage(formData)
				setOTPStep(true)
			} 
		} else {
			if(country.code === "") setError("Country is required");
			if(mobile.length < 10){
				setErrorMobile("Invalid mobile number")
			}
				if (!validMobile) {
				setErrorMobile("Invalid mobile number. Please enter corresponding country code.")
			}
		}

		
	};

	const [otpStep,setOTPStep] = useState(false)

	const [otpCode, setOTPCode] = useState(null);

	function generateOTP() {
          
		// Declare a digits variable 
		// which stores all digits
		var digits ='0123456789';
		let OTP ='';
		for (let i = 0; i <6; i++) {
			OTP += digits[Math.floor(Math.random() * 10)];
		}
		return OTP;
	}

	const [optInput, setOTPInput] = useState("")
	const [optError, setOTPError] = useState("")

	const verifyOTP = () => {

		const username = 'afx_otp_gen';
		const password = 'rWQ153awTnGu1%7K$Sof';
		const url = `https://api.accessforex.com/otp/index.php?type=verify&mobile=263784629765&otp=${optInput}`;

		fetch(url,{
			method: 'GET',
			headers: { 
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Basic ${btoa(`${username}:${password}`)}`,
			},
		})
		.then((response) => {
			if (!response.ok) {
				setOTPError("Invalid OTP")
				throw new Error('Network response was not ok');
			}
			proceedWithRegistration()
			return response.json();
		})
		.catch((err) =>console.error('There was a problem with the fetch operation:', err));

		
		// if(optInput == otpCode){
		// 	// history.push("/send-money")
		// 	proceedWithRegistration()
		// }else{
		// 	setOTPError("Invalid OTP")
		// }
	}

	const sendOTP = (mobile_number) => {

		const otp = generateOTP();
		const username = 'afx_otp_gen';
		const password = 'rWQ153awTnGu1%7K$Sof';
		const url = `https://api.accessforex.com/otp/index.php?type=request&mobile=263784629765`;

		// setOTPCode(otp)

		fetch(url,{
			method: 'GET',
			headers: { 
				'Accept': '*/*',
				// 'Content-Type': 'application/json',
				Authorization: `Basic ${btoa(`${username}:${password}`)}`,
			},
		})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.catch((err) =>console.error('There was a problem with the fetch operation:', err));
	}

	let content = ui_loading ? (
		<PageLoader />
	) : (
		<AuthLayout
			uioptions={props.uioptions}
			clearUIoptionsError={clearUIoptionsError}
			pagetitle="SIGN UP"
		>
			{
				<>
				{otpStep ? 
					<div className="form-group mobile-input-container">
						{/* <input type="text" label="OTP*" name="otp" /> */}
						<div className="alert alert-success" role="alert">
							An OTP has been sent to your mobile number. Please enter the OTP sent to your mobile number to verify your account.
						</div>
						{optError ? <div className="alert alert-danger" role="alert">
							{optError}
						</div> : null}
						<input onChange={(e) => {
							setOTPInput(e.target.value);
							setOTPError('')
						}} className="p-inputtext p-component p-filled p-d-block outlinenone false" placeholder="Enter OTP HERE"  label="Enter OTP"  style={{width: "100%", border: "1px solid rgb(59, 54, 111)", textAlign:'center'}}></input>
						{otpCode ? <span onClick={() => sendOTP("+"+mobile)} style={{cursor:'pointer', display:'flex', width:'100%', justifyContent:'center', margin:'10px 0'}}>Resend OTP</span> : null}
						{user_loading ? (
									<ButtonLoader />
								) : (
									<button onClick={() => verifyOTP()} className="btn btn-primary bgBlue" style={{marginTop:10, marginBottom: 20, display:'flex',justifyContent:'center', alignItems:'center', width:'100%'}} type="submit">
										Verify
									</button>
								)}
						
					</div> :
				<Formik
					initialValues={initialValues}
					enableReinitialize={true}
					validationSchema={registerSchema}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(true);
						handleRegister(values);
						setSubmitting(false);
					}}
				>
					{(formik) => (
						<Form>
							{user_error && user_error.map((err) => <Error key={err.error} error={err.error} />)}
							<div className="d-none d-md-block">
								{ui !== null && ui_loading === false && (
									<SelectWithImages
										countryName={countryName}
										setCountryName={setCountryName}
										country={country}
										setCountry={(e) => {
											setCountry(e)
											console.log("COUNTRY",e)
										}}
										name="country"
										// onCountryChange={(e) => {
										// 	console.log("COUNTRY",e)
										// }}
										// onChange={(e) => {
										// 	console.log("COUNTRY",e)

										// 	formik.handleChange(e)
										// }}
										countries={ui && ui.result.countries.source}
									/>
								)}
								{error && <span className="text-danger">{error}</span>}
							</div>

							{/* <div className="d-block d-md-none">
								{ui !== null && ui_loading === false && (
									<Select
										options={regCountries}
										name="country"
										label="Select Country"
										onChange={(e) => {
											console.log("COUNTRY",e)
											formik.handleChange(e);
											setCountry({ code: e.target.value });
										}}
									/>
								)}
								{error && <span className="text-warning">{error}</span>}
							</div> */}

							<div className="form-group mobile-input-container">
											{country?.code === "UK" ? <MobileNumber
												label="Mobile Number*"
												name="phone"
												value={mobile}
												// disabled={disableFields}
												inputProps={{
													name: "mobile",
													placeholder: "Enter mobile number",
												}}
												country={"gb"}
												onlyCountries={["gb"]}
												onChange={(e) => {
													setMobile(e)
												}}
											/> : null}

											{country?.code === "SA" ? <MobileNumber
												label="Mobile Number*"
												name="phone"
												value={mobile}
												// disabled={disableFields}
												inputProps={{
													name: "mobile",
													placeholder: "Enter mobile number",
												}}
												country={"za"}
												onlyCountries={["za"]}
												onChange={(e) => {
													setMobile(e)
												}}
											/> : null}	

										{errorMobile && <span className="text-danger">{errorMobile}</span>}
											
										
										</div>

							<TextField label="Email*" name="email" />
							<Password label="Password*" name="password" type="password" />
							<div className="mt-3">
								<CheckBox
									name="subscribe"
									label="Subscribe to receive exclusive offers & services from us."
								/>
							</div>

							<div className="d-grid gap-2 mt-3">
								{user_loading ? (
									<ButtonLoader />
								) : (
									<button className="btn btn-primary bgBlue" type="submit">
										<i className="pi pi-lock"></i> &nbsp;Sign Up
									</button>
								)}
							</div>
							<p className="text-start mt-3 mb-10">
								<Link
									to="/"
									style={{
										textDecoration: "none",
										color: "#3B366F",
									}}
								>
									Already a member? <strong style={{background:'#ccc', borderRadius:5, padding:'0 10px'}}>Login</strong>
								</Link>
							</p>
						</Form>
					)}
				</Formik>}
				</>

			}
		</AuthLayout>
	);

	return <>{content}</>;
};

Register.propTypes = {
	authorization: PropTypes.object.isRequired,
	uioptions: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	basicRegistration: PropTypes.func.isRequired,
	clearUIoptionsError: PropTypes.func.isRequired,
	clearBasicRegistrationError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(Register);
