import { ContactDetails, Error, Footer, TextFieldTwo, UnsignedHeader } from "components";
import { useHistory } from "react-router-dom";
import React from "react";
import SignedHeader from "components/SignedHeader/SignedHeader";
import { Formik } from "formik";
import * as Yup from "yup";
import RegularButton from "components/Buttons/RegularButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./UpdateBeneficiary.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import PageLoader from "components/Loaders/PageLoader";
import SessionTimeout from "utils/SessionTimeout";

const UpdateBeneficiary = (props) => {
	const history = useHistory();
	const { user } = props.authorization;

	const {
		getSingleBeneficiary,
		clearSingleBeneficiaryError,
		updateBeneficiary,
		clearUpdateBeneficiaryError,
		clearUpdateBeneficiary,
		uioptions,
	} = props;

	const { single_beneficiary, single_beneficiary_loading, single_beneficiary_error } =
		props.getSingleBeneficiaryReducer;
	const { update_beneficiary_data, update_beneficiary_loading, update_beneficiary_error } =
		props.updateBeneficiaryReducer;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Update Beneficiary";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		getSingleBeneficiary(props.match.params.slug);
		return () => {
			clearSingleBeneficiaryError();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getSingleBeneficiary, clearSingleBeneficiaryError]);

	const initialValues = {
		phone: single_beneficiary && single_beneficiary.mobile ? single_beneficiary.mobile : "",
		first_name: single_beneficiary && single_beneficiary.fname ? single_beneficiary.fname : "",
		middle_name: single_beneficiary && single_beneficiary.mname ? single_beneficiary.mname : "",
		last_name: single_beneficiary && single_beneficiary.lname ? single_beneficiary.lname : "",
	};

	const updateBeneficiarySchema = Yup.object().shape({
		phone: Yup.string().required("Mobile is required").label("Mobile Number").matches(
			/^[1-9]{1}[0-9]{1,}$/,
			"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
		)
		.min(11, "Invalid mobile number")
		.max(12, "Invalid mobile number"),
		first_name: Yup.string().required().min(2).label("First Name"),
		last_name: Yup.string().required().min(2).label("Last Name"),
		middle_name: Yup.string().test(
			"empty-or-2-characters-check",
			"Middle name must be at least 2 characters",
			(middle_name) => !middle_name || middle_name.length >= 2
		),
	});

	const submitForm = (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
		const data = {
			beneficiary_id: props.match.params.slug,
			first_name: values.first_name,
			middle_name: values.middle_name,
			last_name: values.last_name,
			mobile: values.phone.startsWith("+") ? values.phone :  "+" + values.phone,
		};
		updateBeneficiary(data);
	};

	//CLEAR SINGLE BENEFICIARY ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			single_beneficiary_error && clearSingleBeneficiaryError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [single_beneficiary_error, clearSingleBeneficiaryError]);

	//CLEAR UPDATE BENEFICIARY
	React.useEffect(() => {
		if (update_beneficiary_data) {
			history.push("/beneficiaries");
		}

		return () => {
			clearUpdateBeneficiary();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [update_beneficiary_data, clearUpdateBeneficiary]);

	//CLEAR UPDATE BENEFICIARY ERROR
	React.useEffect(() => {
		const time_id = setInterval(() => {
			update_beneficiary_error && clearUpdateBeneficiaryError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [update_beneficiary_error, clearUpdateBeneficiaryError]);

	let content =
		single_beneficiary_loading || update_beneficiary_loading ? (
			<PageLoader />
		) : single_beneficiary_error ? (
			single_beneficiary_error.map((err) => <Error key={err.error} error={err.error} />)
		) : (
			<Formik
				//   innerRef={ref}
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={updateBeneficiarySchema}
				onSubmit={submitForm}
			>
				{(formik) => {
					const {
						values,
						handleChange,
						handleSubmit,
						errors,
						touched,
						handleBlur,
						isValid,
						dirty,
						setFieldValue,
					} = formik;
					return (
						<form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
							{/* {update_beneficiary_error && <Error key={err.error} error={update_beneficiary_error} />} */}
							{update_beneficiary_error && update_beneficiary_error.map((err) => <Error key={err.error} error={err.error} />)}
							<div className="row">
								<div className="col-md-12">
									<TextFieldTwo
										name="first_name"
										label="First Name*"
										placeholder="First Name"
										value={values.first_name}
										onChange={handleChange}
										onBlur={handleBlur}
										autoComplete="off"
									/>
									{errors.first_name && touched.first_name && (
										<span className="form-text small text-danger">
											{errors.first_name}
										</span>
									)}
								</div>

								<div className="col-md-12">
									<TextFieldTwo
										name="middle_name"
										label="Middle Name(s)"
										placeholder="Middle Name(s)"
										value={values.middle_name}
										onChange={handleChange}
										onBlur={handleBlur}
										autoComplete="off"
									/>
									{errors.middle_name && touched.middle_name && (
										<span className="form-text small text-danger">
											{errors.middle_name}
										</span>
									)}
								</div>

								<div className="col-md-12">
									<TextFieldTwo
										name="last_name"
										label="Last Name*"
										placeholder="Last Name"
										value={values.last_name}
										onChange={handleChange}
										onBlur={handleBlur}
										autoComplete="off"
									/>
									{errors.last_name && touched.last_name && (
										<span className="form-text small text-danger">
											{errors.last_name}
										</span>
									)}
								</div>

								<div className="col-md-12">
									<div className="form-group mobile-input-container mt-1">
										<label>Mobile Number*</label>
										<PhoneInput
											name="phone"
											country={"zw"}
											onlyCountries={["zw"]}
											value={values.phone}
											onChange={(phone) => setFieldValue("phone", phone)}
											onBlur={handleBlur}
											placeholder=""
											containerClass={`phone-input`}
											autoFormat={false}
										/>
										{errors.phone && (
											<span className="form-text small text-danger">{errors.phone}</span>
										)}
									</div>
								</div>

								<div className="col-md-12 mt-2">
									<RegularButton
										value="Update"
										type="submit"
										floatright={true}
										disabled={!(dirty && isValid)}
									/>
								</div>

								<br />
								<br />
							</div>
						</form>
					);
				}}
			</Formik>
		);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-2" style={{ minHeight: "70%" }}>
				<div className="container mt-3">
					<div className="row">
						<div className="col-sm-5 col-md-4 col-lg-3 order-last order-sm-first mt-2 mt-sm-0">
							<ContactDetails />
						</div>
						<div className="col-sm-7 offset-md-1 col-md-6  offset-lg-1 col-lg-6">
							<div>
								<h5 className="fw-bold">Update Beneficiary</h5>

								{content}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

UpdateBeneficiary.propTypes = {
	getSingleBeneficiary: PropTypes.func.isRequired,
	clearSingleBeneficiaryError: PropTypes.func.isRequired,
	getSingleBeneficiaryReducer: PropTypes.object.isRequired,

	updateBeneficiary: PropTypes.func.isRequired,
	clearUpdateBeneficiaryError: PropTypes.func.isRequired,
	clearUpdateBeneficiary: PropTypes.func.isRequired,
	updateBeneficiaryReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	getSingleBeneficiaryReducer: state.getSingleBeneficiaryReducer,
	updateBeneficiaryReducer: state.updateBeneficiaryReducer,
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(UpdateBeneficiary);
