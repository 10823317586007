/* eslint-disable import/no-anonymous-default-export */
import {SET_UPDATE_BENEFICIARY_LOADING, UPDATE_BENEFICIARY, SET_UPDATE_BENEFICIARY_ERROR, CLEAR_UPDATE_BENEFICIARY_ERROR, CLEAR_UPDATE_BENEFICIARY} from '../actions/types';

const initialState = { update_beneficiary_data: null, update_beneficiary_loading: false, update_beneficiary_error: null };

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_BENEFICIARY:
            return { ...state, update_beneficiary_data: null, update_beneficiary_loading: false, update_beneficiary_error: null }
        case SET_UPDATE_BENEFICIARY_LOADING:
            return { ...state, update_beneficiary_error: null, update_beneficiary_loading: true }
        case UPDATE_BENEFICIARY:
            return { ...state, update_beneficiary_data: action.payload, update_beneficiary_loading: false, update_beneficiary_error: null }
        case SET_UPDATE_BENEFICIARY_ERROR:
            return { ...state, update_beneficiary_error: action.payload, update_beneficiary_loading: false }
        case CLEAR_UPDATE_BENEFICIARY_ERROR:
            return { ...state, update_beneficiary_error: null, update_beneficiary_loading: false }
        default: return state
    }
}