import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";

// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import App from "./App";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const { store, persistor } = configureStore();



	
	// Bugsnag.start({
	// 	apiKey: "481caae4d0ebc90c9f392284dcfcecf4", // test
	// 	// apiKey: "075b566f18f8954bf18771af1440b1c8", // kyc
	// 	// apiKey: "e59e002ff87106e2e749339b50d5481f", // live
	// 	plugins: [new BugsnagPluginReact()],
	// });

	// const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

	ReactDOM.render(
		// <ErrorBoundary>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
					{/* <ClearCacheProvider duration={5000}> */}
						<App />
					{/* </ClearCacheProvider> */}
						{/* <Footer/> */}
					</BrowserRouter>
				</PersistGate>
			</Provider>,
		// </ErrorBoundary>,
		document.getElementById("root")
	);
	



