/* eslint-disable import/no-anonymous-default-export */
import {GET_TRANSACTION, CLEAR_GET_TRANSACTION, CLEAR_GET_TRANSACTION_ERROR, GET_TRANSACTION_ERROR, GET_TRANSACTION_LOADING} from '../actions/types';

const initialState = {
    transaction: null, transaction_loading: false, transaction_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case GET_TRANSACTION:
            return {
                ...state, transaction: action.payload, transaction_loading: false, transaction_error: null
            }
        case CLEAR_GET_TRANSACTION:
            return {
                ...state, transaction: null, transaction_loading: false, transaction_error: null
            }
        case CLEAR_GET_TRANSACTION_ERROR:
            return {
                ...state, transaction_loading: false, transaction_error: null
            }
        case GET_TRANSACTION_ERROR:
            return {
                ...state, transaction_loading: false, transaction_error: action.payload 
            }
        case GET_TRANSACTION_LOADING:
            return {
                ...state, transaction_loading: true, transaction_error: null
            }
        default: return state 
    }
}