/* eslint-disable import/no-anonymous-default-export */
import { SET_NEW_BENEFICIARY_LOADING, NEW_BENEFICIARY, SET_NEW_BENEFICIARY_ERROR, CLEAR_NEW_BENEFICIARY_ERROR, CLEAR_NEW_BENEFICIARY } from '../actions/types';

const initialState = { nb_data: null, nb_loading: false, nb_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_NEW_BENEFICIARY_LOADING:
            return { ...state, nb_loading: true, nb_error: null }
        case SET_NEW_BENEFICIARY_ERROR:
            return { ...state, nb_error: action.payload, nb_loading: false }
        case NEW_BENEFICIARY:
            return { ...state, nb_loading: false, nb_error: null, nb_data: action.payload }
        case CLEAR_NEW_BENEFICIARY_ERROR:
            return { ...state, nb_loading: false, nb_error: null}
        case CLEAR_NEW_BENEFICIARY:
            return { ...state, nb_loading: false, nb_error: null, nb_data: null}
        default: return state
    }
}