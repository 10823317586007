import React from "react";
import {
	ContactDetails,
	Error,
	Footer,
	PageLoader,
	SignedHeader,
	UnsignedHeader,
} from "components";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import moment from "moment";
import { formattedPaymentMethod, formattedStatus } from "utils/helpers";
import SessionTimeout from "utils/SessionTimeout";

const TransList = (props) => {
	const { initiatePayment, transactions, initiate_payment_error } = props;
	//sort array of objects
	let sortedTransactions = transactions.sort(
		(a, b) => new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
	);


	const makePayment = (transaction) => {
		// DO NOT DELETE THE FOLLOWING COMMENTED CODE =====> Why shouldn't I?

		// let pm_obj = uioptions.ui.result.payment_methods[user.country].find(
		// 	(el) => Number(el.id) === Number(transaction.payment_method)
		// );
		// let path = pm_obj.name === "Credit/Debit Card" ? "transactions" : "checkout";
		let path =
			transaction.payment_name === "AFXeZaga" ||
			transaction.payment_name === "ORMEzagaPay" ||
			transaction.payment_name === "ORMPay360" ||
			transaction.payment_name === "AFXPay360" ||
			transaction.payment_name === "ORMAxcess"
				? "transactions"
				: "checkout";
		let data = {
			member_id   : transaction.member_id,
 			transaction_reference: transaction.trans_ref,
			payment_method: transaction.payment_method,
			currency: transaction.currency,
			amount: transaction.remitt_pay,
			return_url: `${window.location.origin}/${path}/${transaction.trans_ref}`,
		};
		initiatePayment(data);
	};

	return (
		<div className="row">
			{initiate_payment_error && <Error error={initiate_payment_error} />}
			{transactions &&
				sortedTransactions.map((transaction) => {
					return (
						<>
							<div
								className="col-12 col-sm-6 d-block d-md-none mb-2"
								key={transaction.trans_ref}
							>
								<p className="mb-0">
									Date: {moment(transaction.creation_date).format("MMMM Do YYYY")}
								</p>
								<p className="mb-0">Reference: {transaction.trans_ref}</p>
								<p className="mb-0">Beneficiary: {transaction.benef_name}</p>
								<p className="mb-0">
									Amount: {transaction.currency === "GBP" ? "£" : "R"}{" "}
									{transaction.remitt_pay}
								</p>
								<p className="mb-0">Status: {formattedStatus(transaction.status)}</p>
								{/* <Link
									to={`/transactions/${transaction.trans_ref}`}
									className="btn bgBlue rounded py-1 mb-3"
									style={{ backgroundColor: "#3B366F" }}
								>
									View Details
								</Link> */}

								{transaction.status === "PENDING_CLEARANCE" ? (
									<>
										<button
											className="btn bgBlue rounded py-1"
											style={{ backgroundColor: "#FECB3E", color: "#000" }}
											type="button"
											onClick={() => makePayment(transaction)}
										>
											Make Payment
										</button>
									</>
								) : (
									<Link
										to={`/transactions/${transaction.trans_ref}`}
										className="btn bgBlue rounded py-1"
										style={{ backgroundColor: "#3B366F" }}
									>
										{"View Details"}
									</Link>
								)}
							</div>
						</>
					);
				})}

			<div className="col-12 table-responsive-md d-none d-md-block ">
				<table className="table table-bordered table-responsive table-hover">
					<thead>
						<tr className="bgYellow p-2 textBlue ">
							<th className="border p-2">Date</th>
							<th className="border">Reference</th>
							<th className="border">Beneficiary</th>
							<th className="border">Amount</th>
							<th className="border">Payment Method</th>
							<th className="border">Status</th>
							<th className="border"></th>
						</tr>
					</thead>
					<tbody>
						{transactions &&
							transactions.map((transaction, index) => {
								return (
									<tr
										key={transaction.trans_ref}
										style={{ backgroundColor: index % 2 === 0 ? "#F7F7FA" : "#ffffff" }}
									>
										<td className="p-2 border">
											{moment(transaction.creation_date).format("MMMM Do YYYY")}
										</td>
										<td className="p-2 border">{transaction.trans_ref}</td>
										<td className="p-2 border">{transaction.benef_name}</td>
										<td className="p-2 border">
											{transaction.currency === "GBP" ? "£" : "R"}
											{/* {transaction.remitter_pay_amount} */}
											{transaction.remitt_pay}
										</td>
										<td className="p-2 border">
											<span>{formattedPaymentMethod(transaction.payment_name)}</span>
										</td>
										<td className="p-2 border">
											<span>{formattedStatus(transaction.status)}</span>
										</td>

										<td className="p-2 border text-center">
											{/* {transaction.status === "PENDING_CLEARANCE" && transaction.currency !== "GBP" ? ( */}
											{transaction.status === "PENDING_CLEARANCE" ? (
												<>
													<button
														className="btn bgBlue rounded py-1"
														style={{ backgroundColor: "#FECB3E", color: "#000" }}
														type="button"
														onClick={() => makePayment(transaction)}
													>
														Make Payment
													</button>
												</>
											) : (
												<Link
													to={`/transactions/${transaction.trans_ref}`}
													className="btn bgBlue rounded py-1"
													style={{ backgroundColor: "#3B366F" }}
												>
													{"View Details"}
												</Link>
											)}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

const TransactionLogs = (props) => {
	const { user } = props.authorization;
	const { initiate_payment_data, initiate_payment_loading, initiate_payment_error } =
		props.initPymnt;
	const {
		getAllTransactions,
		clearGetTransactionsError,
		uioptions,
		initiatePayment,
		clearInitiatePayment,
		clearInitiatePaymentError,
	} = props;
	const { transactions, transactions_loading, transactions_error } = props.getTransactions;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Transactions";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		getAllTransactions();
		return () => {
			clearGetTransactionsError();
		};
	}, [getAllTransactions, clearGetTransactionsError]);

	React.useEffect(() => {
		if (initiate_payment_data) {
			let url = initiate_payment_data.payment_url;
			clearInitiatePaymentError();
			clearInitiatePayment();
			// window.location.replace(url);
			window.location.assign(url);
		}
		return () => {
			clearInitiatePaymentError();
			clearInitiatePayment();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initiate_payment_data, clearInitiatePaymentError, clearInitiatePayment]);

	React.useEffect(() => {
		const time_id = setInterval(() => {
			initiate_payment_error && clearInitiatePaymentError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [initiate_payment_error, clearInitiatePaymentError]);

	let content =
		transactions_loading || initiate_payment_loading ? (
			<PageLoader />
		) : (
			<div className="container">
				<div className="row">
					<div className="col-xl-3 col-md-4 col-sm-12 order-last order-md-first">
						<ContactDetails />
					</div>
					<div className="col-xl-9 col-md-8 col-sm-12">
						<h5 className="fw-bold text-left mb-3">My Transactions</h5>

						{transactions_error &&
							transactions_error.map((err) => <Error key={err.error} error={err.error} />)}
						{transactions && transactions.length !== 0 ? (
							<TransList
								transactions={transactions}
								initiatePayment={initiatePayment}
								initiate_payment_error={initiate_payment_error}
							/>
						) : (
							<h3 className="">No Transactions Found!</h3>
						)}
					</div>
				</div>
			</div>
		);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>
			<div style={{ minHeight: "70%" }} className="py-0 py-sm-3">
				{content}
			</div>
			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

TransactionLogs.propTypes = {
	getAllTransactions: PropTypes.func.isRequired,
	clearGetTransactionsError: PropTypes.func.isRequired,
	initiatePayment: PropTypes.func.isRequired,
	clearInitiatePayment: PropTypes.func.isRequired,
	clearInitiatePaymentError: PropTypes.func.isRequired,
	getTransactions: PropTypes.object.isRequired,
	initPymnt: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	getTransactions: state.getTransactions,
	authorization: state.authorization,
	uioptions: state.uioptions,
	initPymnt: state.initPymnt,
});

export default connect(mapStateToProps, actions)(TransactionLogs);
