import React from "react";

const Footer = (props) => {
	const { ui, ui_error } = props.uioptions;
	const user = props.user;

	return (
		<div style={{ overflowX: "hidden" }}>
			<div
				className="border-top mx-2 mt-2 mt-sm-0 bg-white position-relative bottom-0 start-0"
				style={{
					width: "100%",
					overflowX: "hidden !important",
				}}
			>
				<div className="container py-2">
					<div className="row align-items-end">
						<div className="col-md-6 col-sm-12 ">
							{!ui_error && ui
								? ui.result.footer.copyright
								: "Copyright 2021 Access Forex. All rights reserved"}
						</div>
						<div className="col-md-6 col-sm-12 text-md-end text-sm-start">
							<p className="mb-1">
								<a
									href="https://www.accessforex.com/privacy-policy/"
									target="_blank"
									style={{ textDecoration: "none" }}
									rel="noreferrer"
								>
									Privacy Policy
								</a>
								&nbsp; | &nbsp;
								<a
									href="https://www.accessforex.com/terms-condition/"
									target="_blank"
									style={{ textDecoration: "none" }}
									rel="noreferrer"
								>
									Terms & Conditions
								</a>
							</p>
						</div>
						{user && user.country === "UK" && !ui_error && ui && (
							<div className="col-sm-12 text-start">
								<p>{ui.result.footer.address.uk}</p>
							</div>
						)}
						{user && user.country === "SA" && !ui_error && ui && (
							<div className="col-sm-12 text-start">{ui.result.footer.address.sa}</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
