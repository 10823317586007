import React from "react";
import { ErrorMessage, useField } from "formik";
import { FaCloudUploadAlt } from "react-icons/fa";
import "./File.css";

const File = ({ ...props }) => {
  const [field] = useField(props);

  return (
    <div>
      <label
        className=" rounded d-block d-flex flex-column align-items-center justify-content-center"
        htmlFor={props.name}
        style={{ width: "100%", height: "170px", border: "1px dashed #3B366F" }}
      >
        <div className="">
          <FaCloudUploadAlt style={{ fontSize: "70px" }} />
        </div>
        <input
          id={props.name}
          type="file"
          className="border-0"
          //   {...field}
          {...props}
        />
        {/* Upload File */}
      </label>
      <ErrorMessage
        component="div"
        name={field.name}
        className="ms-2 mt-0 text-danger"
      />
    </div>
  );
};

export default File;
