/* eslint-disable import/no-anonymous-default-export */
import {PROFILE, PROFILE_ERROR, PROFILE_LOADING, CLEAR_PROFILE_ERROR, CLEAR_PROFILE} from '../actions/types';

const initialState = {
    profile: null,
    profile_loading: false,
    profile_error: null
}

export default function (state = initialState, action){
    switch(action.type){
        case PROFILE:
            return {
                ...state,
                profile: action.payload,
                profile_loading: false,
                profile_error: null
            }
        case PROFILE_ERROR:
            return {
                ...state,
                profile_loading: false,
                profile_error: action.payload
            }
        case PROFILE_LOADING:
            return {
                ...state,
                profile_loading: true,
                profile_error: null
            }
        case CLEAR_PROFILE_ERROR:
            return {
                ...state,
                profile_loading: false,
                profile_error: null
            }
        case CLEAR_PROFILE:
            return {
                ...state,
                profile: null,
                profile_loading: false,
                profile_error: null 
            }
        default: return state
    }
}