import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	RegularButton,
	ButtonLoader,
	Error,
	PageLoader,
	ImgInputGroup,
	Select,
	TextField,
} from "components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ukFlag from "assets/flags/uk.png";
import saFlag from "assets/flags/sa.png";
import zimFlag from "assets/flags/zw.png";
import * as actions from "store/actions";

const AmountAndDelivery = ({ ...props }) => {
	const [paymentMethods, setPaymentMethods] = useState(null);
	const [transferTypes, setTransferTypes] = useState(null);
	const [allowedCurrencies, setAllowedCurrencies] = useState(null);
	const [remittancePurpose, setRemittancePurpose] = useState(null);
	const [saUpgradeLimit, setSAupgradeLimit] = useState(false);
	const [complianceCheck, setComplianceCheck] = useState(false);

	const { ui, ui_loading } = props.uioptions;
	const { user } = props.authorization;
	const { transactions } = props.getTransactions;
	const {await_payment, await_payment_error} = props.awaitPaymentReducer;

	const {
		getUIoptions,
		setAmountandDeliveryHandleChange,
		amntToSend,
		amountsError,
		transaction_charges_error,
		clearTransactionChargesError,
		transaction_charges_data,
		handleStep,
		transaction_charges_loading,
		handleAmntAndDeliverySubmit,
		limitError,
		minAmntError,
		minimumRecievingAmount,
		setMinAmountError,
		clearTransactionCharges,
		amountsState,
		setAmountsState,
		clearInitiatePayment,
		clearNewBeneficiary,
		clearOnboardingPayload,
		clearConfirmTransaction,
		clearRemitOne,
		clearNewTransaction,
		determineRate,
		getAllTransactions,
		clearGetTransactionsError,
		calculateTotalCharges,
		clearOnboardRemitter,
		clearSearchRemitter,
		checkAwaitPayment,
		clearCheckAwaitPaymentError
	} = props;

	const newPaymentMethods = [];
	const newTransferTypes = [];
	const newAllowedCurrencies = [];
	const newRemittancePurpose = [];
	const [paymentMethodDisclaimer, setPaymentMethodDisclaimer] = useState("");

	React.useEffect(() => {
		if (user.onboarded) getAllTransactions();
		return () => {
			clearGetTransactionsError();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAllTransactions, clearGetTransactionsError]);

	useEffect(() => {
		if (user.country === "SA" && user.onboarded && user.kyc_status === "lite") {
			setSAupgradeLimit(true);
		}

		return () => {
			setSAupgradeLimit(false);
		};
	}, [user]);

	useEffect(() => {
		if (transactions) {
			let result = transactions.find((el) => el.status === "AGENT_OK");
			result && setComplianceCheck(false);
		}

		return () => {
			setComplianceCheck(false);
		};
	}, [transactions]);

	useEffect(() => {
		if (ui) {
			ui.result.transfer_types.forEach((element) => {
				newTransferTypes.push({ name: element.type });
			});
			setTransferTypes(newTransferTypes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		clearInitiatePayment();
		clearNewBeneficiary();
		clearOnboardingPayload();
		clearConfirmTransaction();
		clearRemitOne();
		clearTransactionCharges();
		clearNewTransaction();
		clearOnboardRemitter();
		clearSearchRemitter();
		clearCheckAwaitPaymentError();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		checkAwaitPayment();
	}, [checkAwaitPayment]);

	useEffect(() => {
		if (ui) {
			if (user.country === "UK") {
				ui.result.purpose_of_remittance.uk.forEach((element) => {
					newRemittancePurpose.push({ name: element.purpose });
				});
			} else if (user.country === "SA") {
				ui.result.purpose_of_remittance.sa.forEach((element) => {
					newRemittancePurpose.push({ name: element.purpose });
				});
			}
			setRemittancePurpose(newRemittancePurpose);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const time_id = setInterval(() => {
			transaction_charges_error && clearTransactionChargesError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [transaction_charges_error, clearTransactionChargesError]);

	useEffect(() => {
		const time_id = setInterval(() => {
			await_payment_error && clearCheckAwaitPaymentError();
		})
		return () => {
			clearTimeout(time_id);
		}
	}, [await_payment_error, clearCheckAwaitPaymentError])

	useEffect(() => {
		const time_id = setInterval(() => {
			minAmntError && setMinAmountError(false);
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [minAmntError, setMinAmountError]);

	useEffect(() => {
		if (ui && user.country === "UK") {
			ui.result.payment_methods.UK.forEach((element) => {
				newPaymentMethods.push({ id: element.id, name: element.name });
			});

			setPaymentMethods(newPaymentMethods);
			ui.result.receiving_currencies.UK.forEach((element) => {
				newAllowedCurrencies.push({
					id: element.currency,
					name: element.display,
				});
			});
			setAllowedCurrencies(newAllowedCurrencies);
		}
		if (ui && user.country === "SA") {
			ui.result.payment_methods.SA.forEach((element) => {
				newPaymentMethods.push({ id: element.id, name: element.name });
			});
		
			setPaymentMethods(newPaymentMethods);
			ui.result.receiving_currencies.SA.forEach((element) => {
				newAllowedCurrencies.push({
					id: element.currency,
					name: element.display,
				});
			});
			setAllowedCurrencies(newAllowedCurrencies);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePaymentMethodDisclaimer = async (id) => {
		let desc = "";
		if (ui && user.country === "SA") {
			try {
				let paymentMethodObj = await ui.result.payment_methods.SA.find(
					(el) => Number(el.id) === Number(id)
				);
				desc = await paymentMethodObj.disclaimer;
				setPaymentMethodDisclaimer(desc);
			} catch (e) {}
		} else {
			try {
				let paymentMethodObj = await ui.result.payment_methods.UK.find(
					(el) => Number(el.id) === Number(id)
				);
				desc = await paymentMethodObj.disclaimer;
				setPaymentMethodDisclaimer(desc);
			} catch (e) {}
		}
	};

	useEffect(() => {
		!ui && getUIoptions();
	}, [getUIoptions, ui]);

	const getUIContent = () => {
		if (ui_loading) {
			return <PageLoader />;
		} else if (ui) {
			const initialValues = {
				amount_to_send: amountsState.amount_to_send,
				amount_to_receive: amountsState.amount_to_receive,
				destination_currency:
					user && user.country === "UK" && allowedCurrencies
						? allowedCurrencies[0].id
						: amountsState.destination_currency,
				trans_type:
					user && user.country === "UK" && transferTypes
						? transferTypes[0].name
						: amountsState.trans_type,
				payment_method:
					user && user.country === "UK" && paymentMethods
						? paymentMethods[0].id
						: amountsState.payment_method,
				purpose:
					user && user.country === "UK" && remittancePurpose
						? remittancePurpose[0].name
						: amountsState.purpose,
				promo_code: amountsState.promo_code,
			};
			const validateMoney = Yup.object().shape({
				amount_to_send: Yup.number()
					.min(1, "Error, amount should not be negative or zero")
					.notRequired(),
				amount_to_receive: Yup.number()
					.min(1, "Error, amount should not be negative or zero")
					.notRequired(),
				destination_currency: Yup.string().required("Destination currency required"),
				trans_type: Yup.string().required("Transfer type is required"),
				payment_method: Yup.string().required("Payment method is required"),
				purpose: Yup.string().required("Payment purpose is required"),
			});

			const handleSubmitMoney = (data) => {
				// console.log(data);
				setAmountsState({
					amount_to_send: data.amount_to_send,
					amount_to_receive: data.amount_to_receive,
					destination_currency: data.destination_currency,
					trans_type: data.trans_type,
					payment_method: data.payment_method,
					purpose_: data.purpose,
					promo_code: data.promo_code,
				});
				handleAmntAndDeliverySubmit(data);
			};

			const hideCharges = () => {
				clearTransactionCharges();
			};

			return (
				<Formik
					initialValues={initialValues}
					validationSchema={validateMoney}
					onSubmit={handleSubmitMoney}
					enableReinitialize={true}
				>
					{(formik) => {
						// console.log(formik.values);
						return (
							<Form>
								<h5 className="fw-bold">Amount to Send</h5>
								{/* {user &&
									user.country === "UK" &&
									allowedCurrencies &&
									allowedCurrencies[0].id} */}

									{/* {user && user.country === "UK" && (
									<div class="alert alert-danger" style={{background:'red',color:'white'}} role="alert">
									Sending Money Blocked! We're working on resolving the issue. We sincerely regret any inconvenience caused.
									</div>
									)} */}
								{saUpgradeLimit && (
									<div class="alert alert-danger" role="alert">
										Upgrade your account to continue transacting!
									</div>
								)}
								{complianceCheck && (
									<div class="alert alert-danger" role="alert">
										You cannot proceed until one of your transactions has been cleared by
										compliance.
									</div>
								)}
								
								{transaction_charges_error &&
									transaction_charges_error.map((err) => (
										<Error key={err.error} error={err.error} />
									))}
								{await_payment_error && await_payment_error.map(err => (
									<Error key={err.error} error={err.error} />
								))}
								{await_payment && await_payment.pending && <div className="alert alert-danger" role="alert">
									{ui ? ui.result.disclaimers.pending_transactions : "You have a pending transaction."} <Link className="redirectTrans" to="/transactions">View Transaction</Link>
								</div>}
								{limitError && user.onboarded && (
									<Error
										error={
											"Amount entered is above your transaction limit. Please upgrade your account to continue."
										}
									/>
								)}
								{minAmntError && (
									<Error
										error={`The minimum amount a user can receive is ${
											minimumRecievingAmount.currency +
											Number(minimumRecievingAmount.amount)
												.toFixed(2)
												.replace(/\d(?=(\d{3})+\.)/g, "$&,")
										}`}
									/>
								)}
								{limitError && !user.onboarded && (
									<Error error={`Amount entered is above transaction limit`} />
								)}
								
								{user ?<div className="row">
								{/* {user && user.country !== "UK" ?<div className="row"> */}
									<div className="col-md-6">
										<ImgInputGroup
											label="Sending*"
											name="amount_to_send"
											value={amntToSend.amount_to_send}
											onChange={(e) => {
												setAmountandDeliveryHandleChange(e);
												hideCharges();
												formik.values.amount_to_receive = "";
												formik.setFieldError("amount_to_receive", "");
												formik.handleChange(e);
											}}
											placeholder="Sending"
											type="number"
											step="any"
											min="0"
											img={user && user.country === "UK" ? ukFlag : saFlag}
										/>
									</div>

									<div className="col-md-6">
										<ImgInputGroup
											label="Receiving*"
											name="amount_to_receive"
											value={amntToSend.amount_to_receive}
											onChange={(e) => {
												setAmountandDeliveryHandleChange(e);
												hideCharges();
												formik.values.amount_to_send = "";
												formik.setFieldError("amount_to_send", "");
												formik.handleChange(e);
											}}
											placeholder="Receiving"
											type="number"
											min="0"
											step="any"
											img={zimFlag}
										/>
									</div>
								</div> : null}
								{amountsError.length > 0 && (
									<span style={{ color: "red" }}>{amountsError}</span>
								)}

								{/* {user && user.country !== "UK" ? <> */}
								{user  ? <>

								<Select
									name="destination_currency"
									label="Receiving Currency*"
									optionlabel="Choose Currency"
									options={allowedCurrencies}
									// preselect={user && user.country === "UK" ? true : false}
									onChange={(e) => {
										hideCharges();
										formik.handleChange(e);
									}}
								/>
								<Select
									name="trans_type"
									label="How Do You Want The Money To Be Received?*"
									optionlabel="Choose Transfer Type"
									options={transferTypes}
									// preselect={user && user.country === "UK" ? true : false}
									onChange={(e) => {
										hideCharges();
										formik.handleChange(e);
									}}
								/>
								<Select
									name="payment_method"
									label="How Do You Want To Pay?*"
									optionlabel="Choose Payment Method"
									options={paymentMethods}
									// preselect={user && user.country === "UK" ? true : false}
									onChange={(e) => {
										handlePaymentMethodDisclaimer(e.target.value);
										hideCharges();
										formik.handleChange(e);
									}}
								/>
								{paymentMethodDisclaimer && (
									<span className="text-success ms-2">{paymentMethodDisclaimer}</span>
								)}
								<Select
									name="purpose"
									label="Purpose Of Remittance*"
									optionlabel="Choose Purpose Of remittance"
									options={remittancePurpose}
									// preselect={user && user.country === "UK" ? true : false}
									onChange={(e) => {
										hideCharges();
										formik.handleChange(e);
									}}
								/>

								{/* {user && user.onboarded ? ( */}
								<div className="row">
									<div className="col-md-12">
										<TextField
											label="Promo Code"
											name="promo_code"
											placeholder="Enter Promo Code"
											onChange={(e) => {
												hideCharges();
												formik.handleChange(e);
											}}
										/>
										<small>Optional</small>
									</div>
								</div>

								{/* transaction_charges_data */}
								{transaction_charges_loading ? (
									<div className="mb-4">
										<ButtonLoader floatright={true} />
									</div>
								) : (
									<div
										className={`mb-4 ${
											transaction_charges_data && amountsState.trans_type.length > 0
												? "d-none"
												: "d-inline"
										}`}
									>
										<RegularButton
											value="Calculate"
											type="submit"
											disabled={
												limitError || minAmntError || saUpgradeLimit || complianceCheck
											}
											floatright={true}
										/>
									</div>
								)}

								</> : null}
								
								

								{transaction_charges_data && amountsState.trans_type && (
									<>
										<div className="mt-4 mb-4 float-center">
											<p>
												Your sending amount has been adjusted because we can only pay
												out in multiples of $10
											</p>
											<h5>
												<strong>Rate:</strong>
												<span className="float-end">
													{user.country === "UK"
														? "£"
														: amntToSend.destination_currency === "ZAR"
														? "R"
														: "$"}
													1:{determineRate()}
												</span>
											</h5>
											<h5>
												<strong>Charges:</strong>
												<span className="float-end">{calculateTotalCharges()}</span>
											</h5>
											<h5>
												<strong>Total To Pay:</strong>
												<span className="float-end">
													{user.country === "UK" ? "£" : "R"}{" "}
													{(
														Number(transaction_charges_data.result.source_amount) +
														Number(transaction_charges_data.result.total_charges) +
														Number(transaction_charges_data.result.tax)
													)
														.toFixed(2)
														.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
												</span>
											</h5>
										</div>

										<div>
											<RegularButton
												onClick={() => handleStep("amountStep", "forward")}
												value="Continue"
												type="button"
												floatright={true}
											/>
										</div>
									</>
								)}
							</Form>
						);
					}}
				</Formik>
			);
		}
	};

	let content = getUIContent();

	return <>{content}</>;
};

AmountAndDelivery.propTypes = {
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
	getUIoptions: PropTypes.func.isRequired,
	clearInitiatePayment: PropTypes.func.isRequired,
	clearNewBeneficiary: PropTypes.func.isRequired,
	clearOnboardingPayload: PropTypes.func.isRequired,
	clearConfirmTransaction: PropTypes.func.isRequired,
	clearRemitOne: PropTypes.func.isRequired,
	checkAwaitPayment: PropTypes.func.isRequired,
	clearNewTransaction: PropTypes.func.isRequired,
	clearTransactionCharges: PropTypes.func.isRequired,
	clearGetTransactionsError: PropTypes.func.isRequired,
	clearCheckAwaitPaymentError: PropTypes.func.isRequired,
	clearTransactionChargesError: PropTypes.func.isRequired,
	getAllTransactions: PropTypes.func.isRequired,
	getTransactions: PropTypes.object.isRequired,
	awaitPaymentReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
	getTransactions: state.getTransactions,
	awaitPaymentReducer: state.awaitPaymentReducer,
});

export default connect(mapStateToProps, actions)(AmountAndDelivery);
