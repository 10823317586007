import React, { useState } from "react";
import { ContactDetails, SignedHeader, Footer, Error, UnsignedHeader } from "components";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import PageLoader from "components/Loaders/PageLoader";
import SessionTimeout from "utils/SessionTimeout";

const Beneficiaries = (props) => {
	const { getAllBeneficiaries, clearAllBeneficiariesError, uioptions } = props;
	const { all_beneficiaries, all_beneficiaries_loading, all_beneficiaries_error } =
		props.getBeneficiaries;

	const { user } = props.authorization;

	// eslint-disable-next-line no-empty-pattern
	const [] = useState(false);

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Beneficiaries";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		getAllBeneficiaries();
		return () => {
			clearAllBeneficiariesError();
		};
	}, [getAllBeneficiaries, clearAllBeneficiariesError]);

	let content = all_beneficiaries && (
		<div className="row">
			<div className="col-12 d-none d-md-block">
				<table className="mytable table table-bordered table-responsive table-hover">
					<thead>
						<tr
							className="bgYellow textBlue p-2 border-bottom border-white"
							style={{ borderTopLeftRadius: "50px !important" }}
						>
							<th className="border p-2" style={{ borderTopLeftRadius: "50px !important" }}>
								First Name
							</th>
							<th className="border">Middle Name</th>
							<th className="border">Last Name</th>
							<th className="border">Mobile Number</th>
							<th className="border"></th>
						</tr>
					</thead>
					<tbody>
						{all_beneficiaries &&
							all_beneficiaries.map((beneficiary, index) => {
								return (
									<tr
										className="border-bottom-white"
										style={{ backgroundColor: index % 2 === 0 ? "#F7F7FA" : "#ffffff" }}
									>
										<td className="p-2">{beneficiary.fname}</td>
										<td className="p-2 border">{beneficiary.mname}</td>
										<td className="p-2 border">{beneficiary.lname}</td>
										<td className="p-2 border">{beneficiary.mobile}</td>
										<td className="p-2 border text-center">
											<Link
												to={`/beneficiaries/${beneficiary.beneficiary_id}`}
												className="btn bgBlue rounded py-1 text-white"
												style={{ backgroundColor: "#3B366F" }}
											>
												Update
											</Link>
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>

			{all_beneficiaries &&
				all_beneficiaries.map((beneficiary, index) => {
					return (
						<div
							className="col-12 col-sm-6 d-block d-md-none mb-3 mt-2"
							key={beneficiary.beneficiary_id}
						>
							<p className="mb-0">First Name: {beneficiary.fname}</p>
							<p className="mb-0">Middle Name: {beneficiary.mname}</p>
							<p className="mb-0">Last Name: {beneficiary.lname}</p>
							<p className="mb-0">Mobile Number: {beneficiary.mobile}</p>
							<Link
								to={`/beneficiaries/${beneficiary.beneficiary_id}`}
								className="btn bgBlue rounded py-1 text-white"
								style={{ backgroundColor: "#3B366F" }}
							>
								Update
							</Link>
						</div>
					);
				})}
		</div>
	);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
				<div className="container mt-3 mt-sm-0">
					<div className="row">
						<div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 order-last order-md-first">
							<ContactDetails />
						</div>
						<div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 table-responsive-md">
							<div className="row">
								<div className="col-12">
									<h5 className="fw-bold">My Beneficiaries</h5>
								</div>
								<div className="col-12 text-start text-sm-end">
									<Link
										to={`/beneficiaries/new`}
										className="btn bgBlue text-white rounded py-1"
									>
										Add New Beneficiary
									</Link>
								</div>

								<div className="col-12 mt-2">
									{all_beneficiaries_loading ? (
										<PageLoader />
									) : (
										<>
											{all_beneficiaries_error &&
												all_beneficiaries_error.map((err) => (
													<Error key={err.error} error={err.error} />
												))}
											{all_beneficiaries && all_beneficiaries.length !== 0 ? (
												content
											) : (
												<h3 align="center">No Beneficiaries Found!</h3>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

Beneficiaries.propTypes = {
	getAllBeneficiaries: PropTypes.func.isRequired,
	clearAllBeneficiariesError: PropTypes.func.isRequired,
	getBeneficiaries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	getBeneficiaries: state.getBeneficiaries,
	uioptions: state.uioptions,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(Beneficiaries);
