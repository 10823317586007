import React from "react";
import { PageLoader, UnsignedHeader } from "components";
// import bgfx from "assets/bgaccessfx.jpg";
import accessfxbg from "assets/accessfxbg.jpg";
import { zw, sa, uk } from "assets/flags";
import { links } from "utils/constants";
import { FiFacebook, FiTwitter, FiYoutube, FiInstagram, FiLinkedin } from "react-icons/fi";

const AuthLayout = ({ heading, clearUIoptionsError, children, ...props }) => {
  const { fb, tw, yt, ins, lin } = links;
  const { ui_loading, ui_error, ui } = props.uioptions;

  React.useEffect(() => {
    const time_id = setInterval(() => {
      ui_error && clearUIoptionsError();
    }, 9000);

    return () => {
      clearTimeout(time_id);
    };
  }, [ui_error, clearUIoptionsError]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  let content = ui_loading ? (
    <PageLoader />
  ) : (
    <div className="vh-100 py-0 overflowY">
      <div className="header-wrapper">
        <UnsignedHeader layout="auth" />
      </div>

      <div
        className="container-fluid d-flex align-items-center py-0 py-sm-3 justify-content-center"
        style={{
          minHeight: "68%",
          backgroundImage: `url(${accessfxbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="row w-100">
          <div
            className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8 offset-md-2 col-md-8 rounded mt-md-0"
            style={{ boxShadow: "0 0 40px 10px rgb(0 0 0 / 10%)" }}
          >
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 bg-white px-4 border-end borderRadiusLeft-5px ">
                <h5 className="mt-3 mb-0 fw-bold">{props.pagetitle}</h5>
                <p className="text-center mb-1">{heading}</p>
                {children}
                <p className="font-13px mt-0">
                  By signing up you agree to our{" "}
                  <a href={ui ? ui.result.links.terms : ""} target="_blank" rel="noreferrer" className="fw-bold">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href={ui ? ui.result.links.privacy : ""} target="_blank" rel="noreferrer" className="fw-bold">
                    Privacy Policy
                  </a>
                </p>
              </div>

              <div
                className="col-lg-6 col-md-6 text-white bgBlue borderRadiusRight-5px d-flex align-items-center"
                style={{ backgroundColor: "#3B366F !important" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textTransform: "uppercase",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    <h3 align="center" className="fw-bold" style={{ color: "#fec528" }}>
                      Download Our Mobile App
                    </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <img src="./doityourself.png" width="250" alt="" /> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src="./qr.png" width="150" alt="" />
                      <button
                        style={{
                          marginTop: 10,
                          padding: 5,
                          paddingLeft: 10,
                          paddingRight: 10,
                          borderRadius: 5,
                          textTransform: "uppercase",
                        }}
                        onClick={() => openInNewTab("https://app.accessforex.com")}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="ms-2 py-2 py-md-0">
									<div>
										<h5 className="fw-bold">Call Us</h5>
										<img src={zw} width={23} alt="" />
										<a
											href="tel:+2638677008822"
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											+263 8677 008 822
										</a>
										<br />
										<img src={uk} width={23} alt="" />{" "}
										<a
											href={ui ? ui.result.contact_info.uk.phone.link : ""}
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											{" "}
											{ui ? ui.result.contact_info.uk.phone.display : ""}
										</a>
										<br />
										<img src={sa} width={23} alt="" />
										<a
											href={ui ? ui.result.contact_info.sa.phone.link : ""}
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											{" "}
											{ui ? ui.result.contact_info.sa.phone.display : ""}
										</a>{" "}
										<br />
										<br />
									</div>
									<div>
										<h5 className="fw-bold">WhatsApp Us</h5>
										<img src={zw} width={23} alt="" />
										<a
											href="https://api.whatsapp.com/send?phone=2638677008822&text=Hi&source=website"
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											+263 8677 008 822
										</a>
										<br />
										<img src={uk} width={23} alt="" />{" "}
										<a
											href="https://api.whatsapp.com/send?phone=448708200854&text=Hi&source=website"
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											{" "}
											{ui ? ui.result.contact_info.uk.phone.display : ""}
										</a>
										<br />
										<img src={sa} width={23} alt="" />
										<a
											href={ui ? ui.result.contact_info.sa.whatsapp.link : "#"}
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											{" "}
											{ui ? ui.result.contact_info.sa.whatsapp.display : ""}
										</a>{" "}
										<br />
										<br />
									</div>

									<div>
										<h5 className="fw-bold">Email</h5>
										<a
											href={"mailto:" + (ui ? ui.result.links.email : "#")}
											target="_blank"
											rel="noreferrer"
											className="text-light text-decoration-none"
										>
											{ui ? ui.result.links.email : ""}
										</a>
									</div>
								</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ minHeight: "10vh" }}>
        <div style={{ overflowX: "hidden" }}>
          <div
            className=" mx-2 bg-white "
            style={{
              width: "100%",
              overflowX: "hidden !important",
              // whiteSpace: "nowrap",
            }}
          >
            <div className="container-md py-4">
              <div className="row">
                <div className="col-lg-6 col-sm-12 ">{ui ? ui.result.footer.copyright : ""}</div>
                <div className="col-lg-6 col-sm-12 text-lg-end text-sm-start">
                  <a href={"https://www.accessforex.com/terms-condition"} target="_blank" rel="noreferrer">
                    Terms of Use
                  </a>
                  {" | "}
                  <a href={"https://www.accessforex.com/privacy-policy"} target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>

                  <div className="mt-md-0 d-inline d-md-none ms-1">
                    <a
                      href={fb}
                      target="_blank"
                      className="ms-3 textBlue"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FiFacebook />
                    </a>
                    <a
                      href={tw}
                      target="_blank"
                      className="ms-3 textBlue"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FiTwitter />
                    </a>
                    <a
                      href={yt}
                      target="_blank"
                      className="ms-3 textBlue"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FiYoutube />
                    </a>
                    <a
                      href={ins}
                      target="_blank"
                      className="ms-3 textBlue"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FiInstagram />
                    </a>
                    <a
                      href={lin}
                      target="_blank"
                      className="ms-3 textBlue"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <FiLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return <>{content}</>;
};

export default AuthLayout;
