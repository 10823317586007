/* eslint-disable import/no-anonymous-default-export */
import {CLEAR_SEND_OTP, CLEAR_SEND_OTP_ERROR, SEND_OTP_LOADING, SEND_OTP_ERROR, SEND_OTP} from '../actions/types';

const initialState = {
    send_otp_data: null,
    send_otp_loading: false,
    send_otp_error: null
}

export default function (state = initialState, action){
    switch (action.type) {
        case CLEAR_SEND_OTP:
            return {
                ...state,
                send_otp_data: null,
                send_otp_loading: false,
                send_otp_error: null
            }
        case CLEAR_SEND_OTP_ERROR:
            return {
                ...state,
                send_otp_loading: false,
                send_otp_error: null
            }
        case SEND_OTP_LOADING:
            return {
                ...state,
                send_otp_loading: true,
                send_otp_error: null
            }
        case SEND_OTP_ERROR:
            return {
                ...state,
                send_otp_loading: false,
                send_otp_error: action.payload
            }
        case SEND_OTP:
            return {
                ...state,
                send_otp_data: action.payload,
                send_otp_loading: false,
                send_otp_error: null
            }
        default:
            return state
    }
}