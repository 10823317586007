/* eslint-disable import/no-anonymous-default-export */
import {SET_UPDATE_PROFILE_LOADING, UPDATE_PROFILE, SET_UPDATE_PROFILE_ERROR, CLEAR_UPDATE_PROFILE_ERROR, CLEAR_UPDATE_PROFILE} from '../actions/types';

const initialState = { update_profile_data: null, update_profile_loading: false, update_profile_error: null };

export default function (state = initialState, action){
    switch(action.type){
        case CLEAR_UPDATE_PROFILE:
            return { ...state, update_profile_data: null, update_profile_loading: false, update_profile_error: null }
        case SET_UPDATE_PROFILE_LOADING:
            return { ...state, update_profile_error: null, update_profile_loading: true }
        case UPDATE_PROFILE:
            return { ...state, update_profile_data: action.payload, update_profile_loading: false, update_profile_error: null }
        case SET_UPDATE_PROFILE_ERROR:
            return { ...state, update_profile_error: action.payload, update_profile_loading: false }
        case CLEAR_UPDATE_PROFILE_ERROR:
            return { ...state, update_profile_error: null, update_profile_loading: false }
        default: return state
    }
}