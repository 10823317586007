import React from "react";
import { SignedHeader, ContactDetails, Footer, Error, UnsignedHeader } from "components";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import moment from "moment";
import PageLoader from "components/Loaders/PageLoader";
import { formattedPaymentMethod, formattedStatus } from "utils/helpers";

import RegularButton from "components/Buttons/RegularButton";
import SessionTimeout from "utils/SessionTimeout";

const TransactionDetails = (props) => {
	const {
		getSingleTransaction,
		clearGetSingleTransactionError,
		clearGetSingleTransaction,
		uioptions,
		pollPayment,
		clearPollPaymentError,
		clearPollPayment,
		initiatePayment,
		clearInitiatePaymentError,
		clearInitiatePayment,
		getProfile,
	} = props;
	const { transaction, transaction_loading, transaction_error } =
		props.getSingleTransactionReducer;
	const { poll_data, poll_loading, poll_error } = props.pollPymnt;

	const { initiate_payment_data, initiate_payment_loading, initiate_payment_error } =
		props.initPymnt;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Transaction Details";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		const run = async () => {
			await clearPollPaymentError();
			await clearPollPayment();
			await pollPayment({ transaction_reference: props.match.params.slug });
		};

		run();

		return () => {
			clearGetSingleTransactionError();
			clearGetSingleTransaction();
			clearPollPaymentError();
			clearPollPayment();

			clearInitiatePaymentError();
			clearInitiatePayment();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		getSingleTransaction,
		clearGetSingleTransactionError,
		clearGetSingleTransaction,
		pollPayment,
		clearPollPaymentError,
		clearPollPayment,
		clearInitiatePaymentError,
		clearInitiatePayment,
	]);

	React.useEffect(() => {
		getSingleTransaction(props.match.params.slug);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [poll_data, getSingleTransaction]);

	const { user } = props.authorization;

	const makePayment = () => {
		// DO NOT DELETE THE FOLLOWING COMMENTED CODE =====> Why shouldn't I?

		// let pm_obj = uioptions.ui.result.payment_methods[user.country].find(
		// 	(el) => Number(el.id) === Number(transaction.payment_method)
		// );
		// let path = pm_obj.name === "Credit/Debit Card" ? "transactions" : "checkout";

		let path =
			transaction.payment_name === "AFXeZaga" ||
			transaction.payment_name === "ORMEzagaPay" ||
			transaction.payment_name === "ORMPay360" ||
			transaction.payment_name === "AFXPay360" || 
			transaction.payment_name === "ORMAxcess"
				? "transactions"
				: "checkout";

		let data = {
			transaction_reference: transaction.trans_ref,
			payment_method: transaction.payment_method,
			currency: transaction.currency,
			amount: transaction.remitt_pay,
			return_url: `${window.location.origin}/${path}/${transaction.trans_ref}`,
		};

		initiatePayment(data);
	};

	React.useEffect(() => {
		getProfile("auth");
	}, [getProfile]);

	React.useEffect(() => {
		if (
			initiate_payment_data &&
			transaction &&
			formattedStatus(transaction.status) === "Awaiting Payment"
		) {
			let url = initiate_payment_data.payment_url;
			clearInitiatePaymentError();
			clearInitiatePayment();
			// window.location.replace(url);
			window.location.assign(url);
		}
		return () => {
			clearInitiatePaymentError();
			clearInitiatePayment();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initiate_payment_data, clearInitiatePaymentError, clearInitiatePayment]);

	let content =
		transaction_loading || initiate_payment_loading || poll_loading ? (
			<PageLoader />
		) : transaction_error ? (
			transaction_error.map((err) => <Error key={err.error} error={err.error} />)
		) : (
			transaction && (
				<div className="border rounded p-4">
					<div className="row d-block d-md-none mb-2">
						<div className="col-12">
							{transaction && formattedStatus(transaction.status) === "Awaiting Payment" && (
								<RegularButton
									style={{ width: "100%" }}
									className="btn btn-block bgYellow mx-0 textBlue mt-2"
									onClick={() => (transaction ? makePayment() : null)}
									value="Make Payment"
								/>
							)}
						</div>
					</div>
					<div className="row">
						{initiate_payment_error &&
							initiate_payment_error.map((err) => <Error key={err.error} error={err.error} />)}
						<div className="col-md-8">
							{/* {JSON.stringify(transaction)} */}
							<div className="d-block">
								<strong>Creation Date:</strong>
								<span className="float-end text-right">
									{moment(transaction.creation_date).format("MMMM Do YYYY")}
								</span>
							</div>
							<div className="d-block">
								<strong>Reference:</strong>
								<span className="float-end text-right">{transaction.trans_ref}</span>
							</div>
							<div className="d-block">
								<strong>Transaction Status:</strong>
								<span className="float-end text-right">
									{formattedStatus(transaction.status)}
								</span>
							</div>
							<div className="d-block">
								<strong>Payment Method:</strong>
								<span className="float-end text-right">
									{formattedPaymentMethod(transaction.payment_name)}
								</span>
							</div>
							<div className="d-block">
								<strong>Transfer Type:</strong>
								<span className="float-end text-right">{transaction.trans_type}</span>
							</div>
						</div>

						<div className="col-md-4 text-center" style={{ color: "#8d8d9c" }}>
							<h6 className="fw-bold">Total to Pay</h6>
							<h4 className="fw-bold">
								{transaction.currency === "GBP" ? "£" : "R"}
								{Number(transaction.remitt_pay)
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
							</h4>
							<h6 className="fw-bold">Receiving Amount</h6>
							<h4 className="fw-bold">
								{transaction.dest_currency === "USD" ? "$" : "R"}
								{Number(transaction.dest_amount)
									.toFixed(2)
									.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
							</h4>
						</div>
					</div>

					<div
						className="row mb-3 text-white py-2 rounded"
						style={{ backgroundColor: "#3B366F" }}
					>
						<div className="col-4">Beneficiary Name</div>
						<div className="col-4">Beneficiary Country</div>
						<div className="col-4">Beneficiary Contact</div>
					</div>

					<div className="row mb-3 py-2 border-bottom">
						<div className="col-4">{transaction.benef_name}</div>
						<div className="col-4">Zimbabwe</div>
						<div className="col-4">{transaction.benef_mobile}</div>
					</div>

					<div className="row justify-content-end">
						<div className="col-lg-4">
							<div>
								Rate{" "}
								<span className="float-end">
									{transaction.currency === "GBP" ? "£" : "R"}1 ={" "}
									{transaction.dest_currency === "USD" ? "$" : "R"}
									{Number(transaction.rate).toFixed(4)}
								</span>
							</div>
							<div>
								Charges{" "}
								<span className="float-end">
									{transaction.currency === "GBP" ? "£" : "R"}
									{Number(transaction.total_charges)
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
								</span>
							</div>
							<hr />
							<div>
								Total to Pay{" "}
								<span className="float-end">
									{transaction.currency === "GBP" ? "£" : "R"}
									{Number(transaction.remitt_pay)
										.toFixed(2)
										.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
								</span>
							</div>
						</div>
					</div>

					<div
						className="row mt-3 text-white py-2 rounded"
						style={{ backgroundColor: "#3B366F" }}
					>
						<div className="col-6 text-center">
							<h5>Collection Point</h5>
							<span>{transaction.collection_point}</span>
						</div>

						<div className="col-6">
							{transaction.status !== "PENDING_CLEARANCE" &&
								transaction.status !== "AGENT_OK" && (
									<>
										<h5>Collection PIN</h5>
										<span>{transaction.collection_pin}</span>
									</>
								)}
						</div>
					</div>
				</div>
			)
		);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
				<div className="container mt-2 mb-3">
					<div className="row">
						<div className="col-12 col-lg-3 col-md-4 col-sm-12 d-none d-md-block">
							{transaction && formattedStatus(transaction.status) === "Awaiting Payment" && (
								<RegularButton
									style={{ width: "100%", marginBottom: 20 }}
									className="btn btn-block bgYellow textBlue px-4"
									onClick={() => (transaction ? makePayment() : null)}
									value="Make Payment"
								/>
							)}
							<br />
							<ContactDetails />
						</div>

						<div className="col-12 col-lg-9 col-md-8 col-sm-12">
							{poll_data && (
								<div className="alert alert-success" role="alert">
									{poll_data.result.message}
								</div>
							)}

							{poll_error &&
								poll_error.map((err, key) => {
									return (
										err.error !== "Payment Not Initiated." && (
											<div key={key} className="alert alert-danger" role="alert">
												{err.error}
											</div>
										)
									);
								})}

							{/* {JSON.stringify(transaction)} */}

							{content}
						</div>
						<div className="col-12 mt-2 mt-md-0 d-block d-md-none">
							<ContactDetails />
						</div>
					</div>
				</div>
			</div>

			<div style={{ minHeight: "10%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

TransactionDetails.propTypes = {
	getSingleTransaction: PropTypes.func.isRequired,
	clearGetSingleTransactionError: PropTypes.func.isRequired,
	clearGetSingleTransaction: PropTypes.func.isRequired,
	getSingleTransactionReducer: PropTypes.object.isRequired,

	pollPayment: PropTypes.func.isRequired,
	clearPollPaymentError: PropTypes.func.isRequired,
	clearPollPayment: PropTypes.func.isRequired,
	pollPymnt: PropTypes.object.isRequired,

	initiatePayment: PropTypes.func.isRequired,
	clearInitiatePaymentError: PropTypes.func.isRequired,
	clearInitiatePayment: PropTypes.func.isRequired,
	getProfile: PropTypes.func.isRequired,
	initPymnt: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	getSingleTransactionReducer: state.getSingleTransactionReducer,
	pollPymnt: state.pollPymnt,
	initPymnt: state.initPymnt,
	uioptions: state.uioptions,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(TransactionDetails);
