/* eslint-disable import/no-anonymous-default-export */
import { SET_RESET_PASSWORD_LOADING, RESET_PASSWORD, SET_RESET_PASSWORD_ERROR, CLEAR_RESET_PASSWORD_ERROR, CLEAR_RESET_PASSWORD } from '../actions/types';

const initialState = { rp_data: null, rp_loading: false, rp_error: null }

export default function (state = initialState, action){
    switch(action.type){
        case SET_RESET_PASSWORD_LOADING:
            return { ...state, rp_loading: true, rp_error: null }
        case SET_RESET_PASSWORD_ERROR:
            return { ...state, rp_error: action.payload, rp_loading: false }
        case RESET_PASSWORD:
            return { ...state, rp_loading: false, rp_error: null, rp_data: action.payload }
        case CLEAR_RESET_PASSWORD_ERROR:
            return { ...state, rp_loading: false, rp_error: null}
        case CLEAR_RESET_PASSWORD:
            return { ...state, rp_loading: false, rp_error: null, rp_data: null}
        default: return state
    }
}