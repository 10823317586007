/* eslint-disable import/no-anonymous-default-export */
import { GET_LIMITS, CLEAR_GET_LIMITS, CLEAR_GET_LIMITS_ERROR, GET_LIMITS_ERROR, GET_LIMITS_LOADING } from '../actions/types';

const initialState = {
    limits: null, limits_loading: false, limits_error: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LIMITS:
            return {
                ...state, limits: action.payload, limits_loading: false, limits_error: null
            }
        case CLEAR_GET_LIMITS:
            return {
                ...state, limits: null, limits_loading: false, limits_error: null
            }
        case CLEAR_GET_LIMITS_ERROR:
            return {
                ...state, limits_loading: false, limits_error: null
            }
        case GET_LIMITS_ERROR:
            return {
                ...state, limits_loading: false, limits_error: action.payload
            }
        case GET_LIMITS_LOADING:
            return {
                ...state, limits_loading: true, limits_error: null
            }
        default: return state
    }
}