/* eslint-disable import/no-anonymous-default-export */
import {SET_NEW_TRANSACTION_LOADING, SET_NEW_TRANSACTION_ERROR, NEW_TRANSACTION, CLEAR_NEW_TRANSACTION_ERROR, CLEAR_NEW_TRANSACTION} from '../actions/types';

const initialState = {
    new_transaction: null, new_transaction_error: null, new_transaction_loading: false
}

export default function (state = initialState, action){
    switch(action.type){
        case SET_NEW_TRANSACTION_LOADING:
            return {
                ...state, new_transaction_error: null, new_transaction_loading: true
            }
        case SET_NEW_TRANSACTION_ERROR:
            return {
                ...state, new_transaction_error: action.payload, new_transaction_loading: false 
            }
        case NEW_TRANSACTION: 
            return {
                ...state, new_transaction: action.payload, new_transaction_error: null, new_transaction_loading: false
            }
        case CLEAR_NEW_TRANSACTION_ERROR:
            return {
                ...state, new_transaction_error: null, new_transaction_loading: false
            }
        case CLEAR_NEW_TRANSACTION:
            return {
                ...state, new_transaction: null, new_transaction_error: null, new_transaction_loading: false
            }
        default: return state 
    }
}