import React from "react";
import "./PageLoader.css";

const PageLoader = (props) => {
  return (
    <div id="preloader">
      <div className="animsition-loading"></div>
      <p className="loading-text text-center">
        {props.message ? props.message : "Page is loading...."}
      </p>
    </div>
  );
};

export default PageLoader;
