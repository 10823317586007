import React from "react";
import {
  TextFieldTwo,
  RadioButtonTwo,
  RegularButton,
  ButtonLoader,
} from "components";
import Postcoder from "react-address-lookup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { DropzoneArea } from "material-ui-dropzone";
import * as actions from "store/actions";

const LimitedKyc = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur", reValidateMode: "onChange" });

  const { upgradeProfile, clearUpgradeProfileError } = props;
  const {
    upgrade_profile_data,
    upgrade_profile_loading,
    upgrade_profile_error,
  } = props.upgrdProfile;

  const [err, setError] = React.useState({
    file: "",
    post_code: "",
  });
  const [file, setFile] = React.useState({
    id1: null,
  });
  const [value, setValue] = React.useState({
    postal_code: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    province: "",
  });

  const logAddress = (addr) => {
    setValue({
      ...value,
      postal_code: addr.postcode,
      address_line_1: addr.addressline1,
      address_line_2: addr.addressline2,
      city: addr.posttown,
      province: addr.county,
    });
    reset({
      address_line_1: addr.addressline1,
      address_line_2: addr.addressline2,
      city: addr.posttown,
      province: addr.county,
    });
  };

  const handleChange = (files) => {
    setFile({
      ...file,
      id1: files[0],
    });
  };

  const handleUpgradeAccount = (data) => {
    if (value.postal_code.length === 0 && file.id1 === null) {
      setError({
        ...err,
        postal_code: "This field is required",
        file: "This field is required",
      });
    } else if (value.postal_code.length === 0) {
      setError({ ...err, postal_code: "This field is required" });
    } else if (!file.id1) {
      setError({ ...err, file: "This field is required" });
    } else {
      data.postal_code = value.postal_code;
      data.id1 = file.id1;
      upgradeProfile(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpgradeAccount)}>
      {upgrade_profile_error && <span>{upgrade_profile_error}</span>}
      <Postcoder
        label="Address*"
        apiKey="PCW55-Y54T8-XN6GY-TQBRR"
        name="postal_code"
        {...register("postal_code", { required: false })}
        addressSelectedCallback={logAddress}
        placeholder="Postal Code"
      />
      {err.postal_code && (
        <span style={{ color: "red" }}>This field is required</span>
      )}

      <TextFieldTwo
        floatingtext="Enter Address 1*"
        defaultValue={value.address_line_1}
        {...register("address_line_1", { required: true })}
      />
      {errors.address_line_1 && (
        <span style={{ color: "red" }}>This field is required</span>
      )}

      <TextFieldTwo
        floatingtext="Enter Address 2*"
        defaultValue={value.address_line_2}
        {...register("address_line_2", { required: true })}
      />
      {errors.address_line_2 && (
        <span style={{ color: "red" }}>*This field is required</span>
      )}

      <TextFieldTwo
        floatingtext="City*"
        defaultValue={value.city}
        {...register("city", { required: true })}
      />
      {errors.city && (
        <span style={{ color: "red" }}>*This field is required</span>
      )}

      <TextFieldTwo
        floatingtext="State/Province*"
        defaultValue={value.province}
        {...register("province", { required: true })}
      />
      {errors.province && (
        <span style={{ color: "red" }}>*This field is required</span>
      )}

      <p className="mt-3 mb-0 py-0">Select ID Type*</p>
      <RadioButtonTwo
        label="Passport"
        {...register("id1_type", { required: true })}
        value="passport"
        id="passport"
      />
      <RadioButtonTwo
        label="Driving Licence"
        {...register("id1_type", { required: true })}
        value="drivingLicence"
        id="drivingLicence"
      />
      <RadioButtonTwo
        label="Residence Card"
        {...register("id1_type", { required: true })}
        value="residenceCard"
        id="residenceCard"
      />
      {errors.id1_type && errors.id1_type.type === "required" && (
        <span style={{ color: "red" }}>*This field is required</span>
      )}

      <div className="mt-3">
        <p className="mb-0">Upload Driving Licence*</p>
        <DropzoneArea name="id1" onChange={handleChange} className="border-0" />
      </div>
      {err.file && <span style={{ color: "red" }}>{err.file}</span>}
    </form>
  );
};

LimitedKyc.propTypes = {
  upgrdProfile: PropTypes.object.isRequired,
  clearUpgradeProfileError: PropTypes.func.isRequired,
  upgradeProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  upgrdProfile: state.upgrdProfile,
});

export default connect(mapStateToProps, actions)(LimitedKyc);
