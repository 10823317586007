import React from "react";
import { useField } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const MobileNumber = (props) => {
	const [field] = useField(props);
	return (
		<div className="mt-2 ">
			<label htmlFor={props.name} className="form-label fw-bold">
				{props.label}
			</label>
			<PhoneInput
				id={props.name}
				{...field}
				{...props}
				autoFormat={false}
				enableSearch={true}
				disableSearchIcon={false}
				// prefix="+"
				containerClass={`phone-input`}
			/>
		</div>
	);
};

export default MobileNumber;
