import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../store/actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function SessionTimeout(props) {
  const [counter, setCounter] = useState(21);
  const history = useHistory();
  const { logout } = props;
  const idleTimerRef = useRef(null);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const onHide = () => {
    setDialogIsOpen(false);
    setCounter(21);
  };

  const signout = () => {
    logout();
    history.push("/");
  };

  const dialogFooter = () => {
    return (
      <div>
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-text"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          onClick={() => signout()}
          autoFocus
        />
      </div>
    );
  };

  const onIdle = () => {
    setDialogIsOpen(true);
    setCounter(counter - 1);
  };

  React.useEffect(() => {
    if (counter > 0 && dialogIsOpen) {
      setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter <= 0 && dialogIsOpen) {
      logout();
      history.push("/");
      setDialogIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, history, logout]);

  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={15 * 60 * 1000}
        onIdle={onIdle}
      ></IdleTimer>
      <Dialog
        header="Session timeout"
        visible={dialogIsOpen}
        onHide={() => onHide()}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        footer={dialogFooter}
      >
        <p>
          Your session is about to expire! You will be logged out in {counter}s
        </p>
        <p>Do you want to stay connected?</p>
      </Dialog>
      {props.children}
    </div>
  );
}

SessionTimeout.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, actions)(SessionTimeout);
