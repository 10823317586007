import {
	ButtonLoader,
	ContactDetails,
	Error,
	Footer,
	TextFieldTwo,
	UnsignedHeader,
} from "components";
import React from "react";
import SignedHeader from "components/SignedHeader/SignedHeader";
import { Formik } from "formik";
import * as Yup from "yup";
import RegularButton from "components/Buttons/RegularButton";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import "./UpdateBeneficiary.css";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";

import SessionTimeout from "utils/SessionTimeout";

const NewBeneficiary = (props) => {
	const history = useHistory();
	const { newBeneficiary, uioptions, clearNewBeneficiaryError, clearNewBeneficiary } = props;

	const { user } = props.authorization;

	const { nb_data, nb_loading, nb_error } = props.newBeneficiaryReducer;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | New Beneficiary";
		return () => {
			subscribe = false;
		};
	}, []);

	const initialValues = {
		phone: "",
		first_name: "",
		middle_name: "",
		last_name: "",
	};

	const updateBeneficiarySchema = Yup.object().shape({
		phone: Yup.string().required().label("Mobile Number"),
		first_name: Yup.string().required().min(2).label("First Name"),
		last_name: Yup.string().required().min(2).label("Last Name"),
		middle_name: Yup.string().test(
			"empty-or-2-characters-check",
			"Middle name must be at least 2 characters",
			(middle_name) => !middle_name || middle_name.length >= 2
		),
	});

	const submitForm = (values) => {
		const data = {
			first_name: values.first_name,
			middle_name: values.middle_name,
			last_name: values.last_name,
			mobile: "+" + values.phone,
		};

		newBeneficiary(data);
	};

	React.useEffect(() => {
		const time_id = setInterval(() => {
			nb_error && clearNewBeneficiaryError(false);
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [nb_error, clearNewBeneficiaryError]);

	React.useEffect(() => {
		if (nb_data) {
			history.push("/beneficiaries");
			clearNewBeneficiary();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nb_data, clearNewBeneficiary]);

	let content = (
		<Formik
			enableReinitialize={true}
			initialValues={initialValues}
			validationSchema={updateBeneficiarySchema}
			onSubmit={submitForm}
		>
			{(formik) => {
				const {
					values,
					handleChange,
					handleSubmit,
					errors,
					touched,
					handleBlur,
					isValid,
					dirty,
					setFieldValue,
				} = formik;
				return (
					<form onSubmit={handleSubmit} className="form-signin mt-3 " autoComplete="off">
						{nb_error && nb_error.map((err) => <Error key={err.error} error={err.error} />)}

						<div className="row">
							<div className="col-md-12">
								<TextFieldTwo
									name="first_name"
									label="First Name*"
									placeholder="First Name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
								/>
								{errors.first_name && touched.first_name && (
									<span className="form-text small text-danger">{errors.first_name}</span>
								)}
							</div>

							<div className="col-md-12">
								<TextFieldTwo
									name="middle_name"
									label="Middle Name(s)"
									placeholder="Middle Name(s)"
									value={values.middle_name}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
								/>
								{errors.middle_name && touched.middle_name && (
									<span className="form-text small text-danger">{errors.middle_name}</span>
								)}
							</div>

							<div className="col-md-12">
								<TextFieldTwo
									name="last_name"
									label="Last Name*"
									placeholder="Last Name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									autoComplete="off"
								/>
								{errors.last_name && touched.last_name && (
									<span className="form-text small text-danger">{errors.last_name}</span>
								)}
							</div>

							<div className="col-md-12">
								<div className="form-group mobile-input-container mt-2">
									<label>Mobile Number*</label>
									<PhoneInput
										name="phone"
										country={"zw"}
										value={values.phone}
										onlyCountries={["zw"]}
										onChange={(phone) => setFieldValue("phone", phone)}
										onBlur={handleBlur}
										placeholder=""
										containerClass={`phone-input`}
										autoFormat={false}
									/>
									{errors.phone && (
										<span className="form-text small text-danger">{errors.phone}</span>
									)}
								</div>
							</div>

							<div className="col-md-12 mt-2">
								{nb_loading ? (
									<ButtonLoader floatright={true} />
								) : (
									<RegularButton
										value="Save"
										type="submit"
										floatright={true}
										disabled={!(dirty && isValid)}
									/>
								)}
							</div>

							<br />
							<br />
						</div>
					</form>
				);
			}}
		</Formik>
	);

	return (
		<div className="vh-100">
			<SessionTimeout />
			<div style={{ minHeight: "16%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
				<div className="container">
					<div className="row">
						<div className="col-sm-5 col-md-4 col-lg-3 order-last order-sm-first mt-2 mt-sm-0">
							<ContactDetails />
						</div>
						<div className="col-sm-7 offset-md-1 col-md-6  offset-lg-1 col-lg-6">
							<div>
								<h4 className="fw-bold">Add New Beneficiary</h4>

								{content}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style={{ minHeight: "14%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

NewBeneficiary.propTypes = {
	newBeneficiary: PropTypes.func.isRequired,
	clearNewBeneficiaryError: PropTypes.func.isRequired,
	clearNewBeneficiary: PropTypes.func.isRequired,
	newBeneficiaryReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	newBeneficiaryReducer: state.newBeneficiaryReducer,
	authorization: state.authorization,
	uioptions: state.uioptions,
});

export default connect(mapStateToProps, actions)(NewBeneficiary);
