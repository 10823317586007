import React from "react";
import { Dropdown } from "primereact/dropdown";

const SelectWithImages = ({ countries, setCountryName, country, setCountry, ...rest }) => {
	const onCountryChange = (e) => {
		setCountry(e.value);
		e.value && setCountryName(e.value.name);
	};

	const selectedCountryTemplate = (option, props) => {
		if (option) {
			return (
				<div className="country-item country-item-value">
					<img
						alt={option.name}
						src={`${option.flag}`}
						width="30"
						className={`flag flag-${option.flag.toLowerCase()}`}
					/>{" "}
					<span>{option.name}</span>
				</div>
			);
		}

		return <span>{props.placeholder}</span>;
	};

	const countryOptionTemplate = (option) => {
		return (
			<div className="country-item">
				<img
					alt={option.name}
					src={`${option.flag}`}
					width="30"
					className={`flag flag-${option.flag.toLowerCase()}`}
				/>{" "}
				<span>{option.name}</span>
			</div>
		);
	};

	return (
		<div>
			<Dropdown
				options={countries}
				value={country}
				optionLabel="code"
				name="country"
				placeholder="Select a Country"
				onChange={onCountryChange}
				filter
				showClear
				filterBy="name"
				valueTemplate={selectedCountryTemplate}
				itemTemplate={countryOptionTemplate}
				style={{ width: "100%", border: "1px solid #3B366F" }}
			/>
		</div>
	);
};

export default SelectWithImages;
