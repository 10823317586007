import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { InputText } from 'primereact/inputtext';

const ImgInputGroup = ({ ...props }) => {

    const [field] = useField(props);

    return (
        <>
            <div className="p-field">
                <label htmlFor={props.name} className="p-d-block fw-bold">
                    {props.label}
                </label>
                <div className="p-inputgroup rounded"
                    style={{ width: '100%', border: '1px solid #3B366F' }}
                >
                    <span className="p-inputgroup-addon border-0 bg-white p-0">
                        <img src={props.img} alt="" width="40" className="ms-2" />
                    </span>
                    <InputText id={props.name}
                        className="border-0"
                        {...field}
                        {...props}
                    />
                </div>
            </div>
            <ErrorMessage component="div" name={field.name} className="ms-2 mt-0 text-danger" />
        </>
    )
}

export default ImgInputGroup
