import React from "react";
import { Dialog } from "primereact/dialog";

const Modal = ({ modalVisible, setModalVisible, children, header }) => {
	// const [visible, setVisible] = useState(false);
	return (
		<>
			<Dialog
				header={header || "Confirmation"}
				visible={modalVisible}
				draggable={true}
				onHide={() => setModalVisible(false)}
				style={{ width: "50vw" }}
				breakpoints={{'960px': '75vw', '640px': '95vw'}}
			>
				{children}
			</Dialog>
		</>
	);
};

export default Modal;
