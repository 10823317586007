import React, { Fragment } from "react";
import { ContactDetails, Footer, PageLoader, SignedHeader, UnsignedHeader } from "components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Link } from "react-router-dom";
import SessionTimeout from "utils/SessionTimeout";

const Profile = (props) => {
	const { user } = props.authorization;
	const { profile, profile_loading } = props.myProfile;
	const { uioptions, getProfile, clearUpgradeProfile, clearUpdateProfile } = props;
	const { upgrade_profile_data } = props.upgrdProfile;
	const { update_profile_data } = props.updateMyProfile;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Profile";
		return () => {
			subscribe = false;
		};
	}, []);

	React.useEffect(() => {
		getProfile("profile");
	}, [getProfile]);

	React.useEffect(() => {
		const time_id = setInterval(() => {
			upgrade_profile_data && clearUpgradeProfile();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [upgrade_profile_data, clearUpgradeProfile]);

	React.useEffect(() => {
		const time_id = setInterval(() => {
			update_profile_data && clearUpdateProfile();
		}, 9000);
		return () => {
			clearUpdateProfile();
			clearTimeout(time_id);
		};
	}, [clearUpdateProfile, update_profile_data]);

	const getIssueCountry = (isoCode) => {
		let code = uioptions.ui
			? uioptions.ui.result.nationalities.find((n) => n.value === isoCode)
			: null;

		return code ? code.name : "--";
	};
	const personalInfo = (
		<div>
			<i className="pi pi-user"></i> <span>Personal Information</span>
		</div>
	);
	const contactInfo = (
		<div>
			<i className="pi pi-map-marker"></i> <span>Contact Information</span>
		</div>
	);
	const documents = (
		<div>
			<i className="pi pi-file-o"></i> <span>Documents</span>
		</div>
	);

	const handleClearUpdateProfile = () => {
		clearUpdateProfile();
	}

	let content = profile_loading ? (
		<PageLoader />
	) : (
		profile && (
			<div className="vh-100">
				<SessionTimeout />
				<div style={{ minHeight: "16%" }}>
					{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
				</div>

				<div className="py-0 py-sm-3" style={{ minHeight: "70%" }}>
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 order-last order-md-first mt-2 mt-md-0y">
								<ContactDetails />
							</div>
							<div className="offset-xl-1 col-xl-6 offset-lg-1 col-lg-7 col-md-8 col-sm-12">
								<div>
									{upgrade_profile_data && (
										<div
											className="alert alert-success alert-dismissible fade show"
											role="alert"
										>
											Your account was successfully upgraded
											<button
												type="button"
												onClick={() => clearUpgradeProfile()}
												className="btn-close"
												data-bs-dismiss="alert"
												aria-label="Close"
											></button>
										</div>
									)}

									{update_profile_data && (
										<div
											className="alert alert-success alert-dismissible fade show"
											role="alert"
										>
											Your account was successfully updated
											<button
												type="button"
												onClick={() => handleClearUpdateProfile()}
												className="btn-close"
												data-bs-dismiss="alert"
												aria-label="Close"
											></button>
										</div>
									)}
									<div className="mb-4 row">
										<div className="col-4">
											<h5 className="fw-bold">My Profile</h5>
										</div>
										<div className="col-8 text-end">
											<Link
												to="/change-password"
												className="btn bgBlue rounded py-1 me-2"
											>
												Change Password
											</Link>
											<Link to="/profile/update" className="btn bgBlue rounded py-1">
												Edit
											</Link>
										</div>
									</div>
									<Accordion activeIndex={0}>
										<AccordionTab header={personalInfo}>
											<div>
												<strong>Member ID</strong>
												<span className="float-end">
													{profile && profile.remitone.remitter_id}
												</span>
											</div>
											<div>
												<strong>First Name</strong>
												<span className="float-end">
													{profile && profile.remitone.firstname.length
														? profile.remitone.firstname
														: "--"}
												</span>
											</div>
											<div>
												<strong>Last Name</strong>
												<span className="float-end">
													{profile.remitone.lastname
														? profile.remitone.lastname
														: "--"}
												</span>
											</div>
											<div>
												<strong>Middle Name(s)</strong>
												<span className="float-end">
													{profile.remitone.middlename
														? profile.remitone.middlename
														: "-"}
												</span>
											</div>
											<div>
												<strong>Date of Birth</strong>
												<span className="float-end">
													{profile.remitone.dob ? profile.remitone.dob : "--"}
												</span>
											</div>
											<div>
												<strong>Nationality</strong>
												<span className="float-end">
													{profile && profile.remitone.nationality
														? getIssueCountry(profile.remitone.nationality)
														: "--"}
												</span>
											</div>
											<div>
												<strong>Gender</strong>
												<span className="float-end">
													{profile.middleware.gender ?? "--"}
												</span>
											</div>
										</AccordionTab>
										<AccordionTab header={contactInfo}>
											<div>
												<strong>Email Address</strong>
												<span className="float-end">
													{profile.remitone.email ?? "--"}
												</span>
											</div>
											<div>
												<strong>Mobile Number</strong>
												<span className="float-end">
													{profile.remitone.mobile ? profile.remitone.mobile : ""}
												</span>
											</div>
											<div>
												<strong>Address Line 1</strong>
												<span className="float-end">{profile.remitone.address1}</span>
											</div>
											<div>
												<strong>Address Line 2</strong>
												<span className="float-end">{profile.remitone.address2}</span>
											</div>
											<div>
												<strong>City</strong>
												<span className="float-end">
													{profile.remitone.city ? profile.remitone.city : "--"}
												</span>
											</div>
											<div>
												<strong>State/Province</strong>
												<span className="float-end">
													{profile.remitone.state ? profile.remitone.state : "--"}
												</span>
											</div>
											<div>
												<strong>Country</strong>
												<span className="float-end">{profile.remitone.country}</span>
											</div>
											<div>
												<strong>Nationality</strong>
												<span className="float-end">
													{profile.remitone.nationality
														? getIssueCountry(profile.remitone.nationality)
														: "--"}
												</span>
											</div>
											<div>
												<strong>Post Code</strong>
												<span className="float-end">
													{profile.remitone.postcode
														? profile.remitone.postcode
														: "--"}
												</span>
											</div>
										</AccordionTab>

										{/* {profile.remitone.kyc_status !== "lite" && ( */}
										<AccordionTab header={documents}>
											<div>
												<strong>ID Information</strong>
												<hr />
												<p className="mb-0">
													Type :{" "}
													{profile.remitone.id1_type
														? profile.remitone.id1_type
														: "--"}
												</p>
												<p className="mb-0">
													Details :{" "}
													{profile.remitone.id1_details
														? profile.remitone.id1_details
														: "--"}
												</p>
												<p className="mb-0">
													Issue Place :{" "}
													{profile.remitone.id1_issue_place
														? profile.remitone.id1_issue_place
														: "--"}
												</p>
												<p className="mb-0">
													Issue Country :{" "}
													{profile.remitone.id1_issue_country
														? getIssueCountry(profile.remitone.id1_issue_country)
														: "--"}
												</p>
												<p>
													Expiry Date :{" "}
													{profile.remitone.id1_expiry
														? profile.remitone.id1_expiry
														: "-"}
												</p>
											</div>

											{profile.remitone.kyc_status === "unlimited" && (
												<>
													<div>
														<strong>Source of Funds</strong>
														<hr />
														<p className="mb-0">
															Details :{" "}
															{profile.remitone.id3_details
																? profile.remitone.id3_details
																: "--"}
														</p>
														<p className="mb-0">
															Issue Place :{" "}
															{profile.remitone.id3_issue_place
																? profile.remitone.id3_issue_place
																: "--"}
														</p>
														<p className="mb-0">
															Issue Country :{" "}
															{profile.remitone.id_issue_country &&
															profile.remitone.id3_issue_country
																? profile.remitone.id3_issue_country
																: "--"}
														</p>
														<p>
															Expiry Date :{" "}
															{profile.remitone.id3_issue_expiry &&
															profile.remitone.id3_issue_expiry
																? profile.remitone.id3_issue_expiry
																: "--"}
														</p>
													</div>

													<div>
														<strong>
															{user && user.country === "UK"
																? "Proof of Address"
																: "Selfie"}
														</strong>
														<hr />
													</div>
												</>
											)}
										</AccordionTab>
										{/* )} */}
									</Accordion>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style={{ minHeight: "14%" }}>
					<Footer user={user} uioptions={uioptions} />
				</div>
			</div>
		)
	);

	return <Fragment>{content}</Fragment>;
};

Profile.propTypes = {
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
	myProfile: PropTypes.object.isRequired,
	upgrdProfile: PropTypes.object.isRequired,
	updateMyProfile: PropTypes.object.isRequired,
	getProfile: PropTypes.func.isRequired,
	clearUpdateProfile: PropTypes.func.isRequired,
	clearUpgradeProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	myProfile: state.myProfile,
	updateMyProfile: state.updateMyProfile,
	upgrdProfile: state.upgrdProfile,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(Profile);
