import React from "react";
import { ErrorMessage, useField } from "formik";
import { FiEye, FiEyeOff } from "react-icons/fi";

const PasswordLogin = ({ ...props }) => {
	const [field, meta] = useField(props);
	const [togglePassword, showTogglePassword] = React.useState(false);

	return (
		<div>
			<label htmlFor={props.name} className="form-label fw-bold">
				{props.label}
			</label>
			<div className="input-group mb-3">
				<input
					id={props.name}
					type={togglePassword ? "text" : "password"}
					{...field}
					{...props}
					className="form-control border-end-0"
				/>
				<span
					className="input-group-text border-start-0"
					id="addon2"
					style={{ cursor: "pointer" }}
					onClick={() => showTogglePassword(!togglePassword)}
				>
					{togglePassword ? <FiEyeOff /> : <FiEye />}
				</span>
			</div>
			<ErrorMessage component="div" name={field.name} className="ms-2 mt-0 text-danger" />
		</div>
	);
};

export default PasswordLogin;
