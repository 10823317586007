import React from "react";
import {
	CheckBoxTwo,
	ContactDetails,
	Footer,
	MobileInputTwo,
	RegularButton,
	SignedHeader,
	TextFieldTwo,
	UnsignedHeader,
} from "components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { zw } from "assets/flags";
import SessionTimeout from "utils/SessionTimeout";

const AddBeneficiary = (props) => {
	const { user } = props.authorization;
	const { uioptions } = props;

	React.useEffect(() => {
		let subscribe = true;
		if (subscribe) document.title = "Access Forex | Add Beneficiary";
		return () => {
			subscribe = false;
		};
	}, []);

	return (
		<div className="vh-100">
			<SessionTimeout />

			<div style={{ minHeight: "15%" }}>
				{user && user.onboarded ? <SignedHeader /> : <UnsignedHeader />}
			</div>

			<div className="py-2" style={{ minHeight: "75%" }}>
				<div className="container mt-2">
					<div className="row">
						<div className="col-lg-4">
							<ContactDetails />
						</div>

						<div className="col-lg-8">
							<h5 className="fw-bold">Add Beneficiary</h5>

							<form action="">
								<div className="row">
									<div className="col-md-6">
										<TextFieldTwo label="First Name*" placeholder="Enter First Name" />
									</div>

									<div className="col-md-6">
										<TextFieldTwo
											label="Middle Name(s)"
											placeholder="Enter Middle Name(s)"
										/>
										<small>Optional</small>
									</div>
								</div>

								<TextFieldTwo label="Last Name*" placeholder="Enter Last Name" />

								<MobileInputTwo label="Mobile Number*" img={zw} code="+263" />
								<div className="mt-3">
									<CheckBoxTwo name="saveToFavorites" label="Save to favorites" />
								</div>

								<div className="mt-4">
									<RegularButton value="Add Beneficiary" type="submit" floatright={true} />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div style={{ minHeight: "10%" }}>
				<Footer user={user} uioptions={uioptions} />
			</div>
		</div>
	);
};

AddBeneficiary.propTypes = {
	uioptions: PropTypes.object.isRequired,
	authorization: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	uioptions: state.uioptions,
	authorization: state.authorization,
});

export default connect(mapStateToProps, actions)(AddBeneficiary);
