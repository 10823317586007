import { File, MobileNumber, RadioButton, RegularButton, Select, TextField } from "components";
import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonLoader from "components/Loaders/ButtonLoader";
import Error from "components/Error/Error";

const CombinedKYC = (props) => {
	const {
		handleStep,
		myProfile,
		setMyProfile,
		user,
		ui,
		searchRemitter,
		search_remitter_loading,
		search_remitter_error,
		search_remitter_data,
		modalVisibleOtp,
		clearSearchRemitterError,
		setModalVisibleMigration,
	} = props;

	const [displaySa, setDisplaySa] = useState({
		foreign_id_number_required: false,
		permit_number_required: false,
	});

	const handleSubmit = (data) => {
		setMyProfile({
			...myProfile,
			first_name: data.first_name,
			last_name: data.last_name,
			middle_name: data.middle_name,
			mobile: data.mobile.startsWith("+") ? data.mobile : "+" + data.mobile,
			phone: data.phone,
			phone_number: data.phone,
			dob: data.dob,
			gender: data.gender,
			nationality: data.nationality,
			postal_code: data.postal_code,
			address_line_1: data.address_line_1,
			address_line_2: data.address_line_2,
			city: data.city,
			state_province: data.state_province,
			id1_type: user && user.country === "SA" ? data.id1_type : "",
			id1_details: user && user.country === "SA" ? data.id1_details : "",
			id1_issue_place: user && user.country === "SA" ? data.id1_issue_place : "",
			id1_issue_country: user && user.country === "SA" ? data.nationality : "",
			id1_expiry: user && user.country === "SA" ? data.id1_expiry : "",
			id1: data.id1,
			id3: data.id3 ? data.id3 : "",
			id3_details: data.id3_details,
			id3_type: data.id3_type,
			id4_details: data.id4_details,
			id4_type: data.id4_type,
			id2: data.id2,
			id2_type: data.id2_type,
			default_transfer_purpose: data.default_transfer_purpose,
			id1_type_label: data.id1_type_label,
		});

		const payload = {
			country: user.country,
			first_name: data.first_name,
			middle_name: data.middle_name,
			last_name: data.last_name,
			mobile: data.mobile.startsWith("+") ? data.mobile : "+" + data.mobile,
			dob: data.dob,
		};

		searchRemitter(payload);

		//handleStep("senderStep", "forward");
	};

	const [country, setCountry] = useState("");

	const newCountry = [];

	useEffect(() => {
		if (ui) {
			ui.result.nationalities.forEach((element) => {
				newCountry.push({ name: element.name, id: element.value });
			});
			setCountry(newCountry);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDocumentsSA = (type) => {
		const obj = ui.result.id_types.sa.id1.find((el) => el.code === type);
		if (obj.foreign_id_number_required && obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: true,
			});
		} else if (obj.foreign_id_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: true,
				permit_number_required: false,
			});
		} else if (obj.permit_number_required) {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: true,
			});
		} else {
			setDisplaySa({
				...displaySa,
				foreign_id_number_required: false,
				permit_number_required: false,
			});
		}
	};
	useEffect(() => {
		if (search_remitter_data) {
			if (search_remitter_data.result.remitter && !modalVisibleOtp) {
				setModalVisibleMigration(true);
			} else if (!search_remitter_data.result.remitter) {
				handleStep("senderStep", "forward");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_remitter_data, handleStep]);

	useEffect(() => {
		const time_id = setInterval(() => {
			search_remitter_error && clearSearchRemitterError();
		}, 9000);

		return () => {
			clearTimeout(time_id);
		};
	}, [search_remitter_error, clearSearchRemitterError]);

	const validateUK = () => {
		return {
			first_name: Yup.string()
				.matches(
					/^[A-Za-z\s]{1,}$/,
					"First name does not allow numeric values or special characters"
				)
				.required("First name is required"),
			last_name: Yup.string()
				.required("Last name is required")
				.matches(
					/^[A-Za-z\s]{1,}$/,
					"First name does not allow numeric values or special characters"
				),
			middle_name: Yup.string()
				.nullable()
				.matches(
					/^[A-Za-z\s]{1,}$/,
					"Middle name does not allow numeric values or special characters"
				),
			gender: Yup.string().required("Gender is required"),
			phone: Yup.string()
				.required("Mobile is required")
				.matches(
					/^[1-9]{1}[0-9]{1,}$/,
					"Phone number should have numbers only and does not accept spaces. Should not start with a zero"
				)
				.min(9, "Invalid mobile number")
				.max(10, "Invalid mobile number"),
			dob: Yup.date()
				.required("Date of birth is required")
				.test("age", "You must be 18 or older", function (dob) {
					const cutoff = new Date();
					cutoff.setFullYear(cutoff.getFullYear() - 18);
					return dob <= cutoff;
				}),
		};
	};

	const validateSA = () => {
		return {
			...validateUK(),
			id1_details: Yup.string().required("ID Number is required"),

			id1_type: Yup.string().required("ID required"),
			id1_issue_place: Yup.string().required("ID issue place required"),
			// id1_issue_country: Yup.string().required("ID issue country required"),
			nationality: Yup.string().required("Nationality required"),
			id1_expiry: Yup.date()
				.required("ID expiry date required")
				.test("expiry", "Expiry date should be greater than today", function (id1_expiry) {
					const today = new Date();
					return id1_expiry > today;
				}),
			id3_details: Yup.string().when("id1_type", {
				is: (val) => val === "Foreign_National ID" || val === "Passport",
				then: Yup.string().required("Foreign ID Number required"),
				otherwise: Yup.string().notRequired(),
			}),

			id4: Yup.string().when("id1_type", {
				is: (val) => val === "Other",
				then: Yup.string().required("Resident permit is required"),
				otherwise: Yup.string().notRequired(),
			}),
		};
	};

	const validate = Yup.object().shape(user && user.country === "UK" ? validateUK() : validateSA());

	return (
		<div>
			{search_remitter_error &&
				search_remitter_error.map((err) => <Error key={err.error} error={err.error} />)}
			<Formik
				initialValues={myProfile}
				enableReinitialize={true}
				validationSchema={validate}
				onSubmit={handleSubmit}
			>
				{({ setFieldValue, ...rest }) => {
					return (
						<Form>
							<h5 className="fw-bold">Sender Details</h5>
							<div className="row">
								<div className="col-md-6">
									<TextField
										name="first_name"
										label="First Name*"
										placeholder="Enter First Name"
									/>
								</div>

								<div className="col-md-6">
									<TextField
										name="middle_name"
										label="Middle Name(s)"
										placeholder="Enter Middle Name(s)"
									/>
									<small>Optional</small>
								</div>
							</div>

							<TextField label="Last Name*" name="last_name" placeholder="Enter Last Name" />

							<MobileNumber
								label="Mobile Number*"
								name="phone"
								value={
									myProfile && myProfile.mobile ? myProfile.mobile : rest.values.mobile
								}
								inputProps={{ name: "mobile" }}
								country={user && user.country === "UK" ? "gb" : "za"}
								onlyCountries={user && user.country === "UK" ? ["gb"] : ["za"]}
								onBlur={rest.handleBlur}
								onChange={(mobile, country, e) => {
									rest.handleChange(e);
									setFieldValue("mobile", mobile);
									setFieldValue("phone", mobile.slice(country.dialCode.length));
								}}
							/>
							{rest.errors && rest.errors.phone && (
								<span className="form-text small text-danger mt-0">
									{rest.errors.phone}
								</span>
							)}

							<TextField
								label="Date of Birth*"
								name="dob"
								type="date"
								placeholder="Enter Date of Birth"
							/>

							{user && user.country === "SA" && (
								<>
									<TextField
										label="Post Code*"
										name="postal_code"
										placeholder="Enter Post Code"
									/>
									<TextField
										label="Address Line 1*"
										name="address_line_1"
										placeholder="Enter address line 1"
									/>
									<TextField
										label="Address Line 2"
										name="address_line_2"
										placeholder="Enter address line 2"
									/>

									<TextField label="City*" placeholder="Enter city" name="city" />

									<TextField
										label="State/Province*"
										placeholder="Enter state/province"
										name="state_province"
									/>

									{ui && (
										<Select
											name="nationality"
											label="Nationality*"
											optionlabel="Choose nationality"
											options={country}
										/>
									)}

									<p className="mt-3 mb-0 fw-bold py-0">Select ID Type</p>

									{/* {ui &&
										ui.result.id_types.sa.id1.map((value, index) => {
											return (
												<RadioButton
													name="id1_type"
													label={value.type}
													value={value.type}
													onChange={(e) => {
														setFieldValue("id1_type", e.target.value);
													}}
													id={"id1_type" + index}
													key={"id1_type" + index}
												/>
											);
										})} */}

									{ui &&
										ui.result.id_types.sa.id1.map((value, index) => {
											return (
												<RadioButton
													name="id1_type"
													label={value.type}
													value={value.code}
													onChange={(e) => {
														handleDocumentsSA(e.target.value);
														setFieldValue("id1_type", e.target.value);
														setFieldValue(
															"default_transfer_purpose",
															value.default_transfer_purpose
														);
														setFieldValue("id1_type_label", value.type);
													}}
													id={"id1_type" + index}
													key={"id1_type" + index}
												/>
											);
										})}

									<TextField
										label={
											rest.values && rest.values.id1_type !== ""
												? rest.values.id1_type_label + " Number*"
												: " Details*"
										}
										name="id1_details"
										placeholder="Enter ID details"
									/>

									<TextField
										label="Issue Place*"
										name="id1_issue_place"
										placeholder="Enter Issue Place"
									/>
									{/* {ui && (
										<Select
											name="id1_issue_country"
											label="Issue Country*"
											optionlabel="Choose issue country"
											options={country}
										/>
									)} */}

									<TextField type="date" label="Expiry Date*" name="id1_expiry" />

									{displaySa.permit_number_required && (
										<TextField
											label="Permit Number*"
											placeholder="Enter Permit Number"
											name="id4"
											onInput={(e) => {
												setFieldValue("id4_type", ui.result.id_types.sa.id4[0].code);
											}}
										/>
									)}

									{displaySa.foreign_id_number_required && (
										<TextField
											label="Foreign ID Number*"
											placeholder="Enter Foreign ID Number"
											name="id3_details"
											onInput={(e) => {
												setFieldValue("id3_type", ui.result.id_types.sa.id3[0].code);
											}}
										/>
									)}
									{rest.errors && rest.errors.id3_details && (
										<p className="text-danger">{rest.errors.id3_details}</p>
									)}
								</>
							)}

							<p className="mb-0 mt-3 fw-bold">Select Gender*</p>

							<div className="row mt-0">
								{ui &&
									ui.result.gender_types.map((type, index) => (
										<div className="col-md-6 mt-0 py-0">
											<RadioButton
												label={type.gender}
												value={type.gender}
												name="gender"
												onChange={(e) => setFieldValue("gender", e.target.value)}
												defaultChecked={myProfile.gender === type.gender}
												key={"gender" + index}
												id={"gender" + index}
											/>
										</div>
									))}
							</div>

							<div className="mt-4">
								<RegularButton
									onClick={() => {
										handleStep("senderStep", "back");
									}}
									value="Back"
									bg="bgYellow"
									type="button"
									floatright={false}
								/>
								{search_remitter_loading ? (
									<ButtonLoader floatright={true} />
								) : (
									<RegularButton value="Continue" type="submit" floatright={true} />
								)}
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default CombinedKYC;
